/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { deleteUserControllerDelete } from '../fn/user/delete-user-controller-delete';
import { DeleteUserControllerDelete$Params } from '../fn/user/delete-user-controller-delete';
import { deleteUserControllerDeleteMany } from '../fn/user/delete-user-controller-delete-many';
import { DeleteUserControllerDeleteMany$Params } from '../fn/user/delete-user-controller-delete-many';
import { deleteUserControllerGoogleTest } from '../fn/user/delete-user-controller-google-test';
import { DeleteUserControllerGoogleTest$Params } from '../fn/user/delete-user-controller-google-test';
import { FindEmailResponseDto } from '../models/find-email-response-dto';
import { FindPasswordConfirmResponseDto } from '../models/find-password-confirm-response-dto';
import { getUserControllerCheckEmail } from '../fn/user/get-user-controller-check-email';
import { GetUserControllerCheckEmail$Params } from '../fn/user/get-user-controller-check-email';
import { getUserControllerFindByContact } from '../fn/user/get-user-controller-find-by-contact';
import { GetUserControllerFindByContact$Params } from '../fn/user/get-user-controller-find-by-contact';
import { getUserControllerFindById } from '../fn/user/get-user-controller-find-by-id';
import { GetUserControllerFindById$Params } from '../fn/user/get-user-controller-find-by-id';
import { getUserControllerGetMe } from '../fn/user/get-user-controller-get-me';
import { GetUserControllerGetMe$Params } from '../fn/user/get-user-controller-get-me';
import { getUserControllerSearchCursor } from '../fn/user/get-user-controller-search-cursor';
import { GetUserControllerSearchCursor$Params } from '../fn/user/get-user-controller-search-cursor';
import { getUserControllerSearchOffset } from '../fn/user/get-user-controller-search-offset';
import { GetUserControllerSearchOffset$Params } from '../fn/user/get-user-controller-search-offset';
import { getUserControllerSearchOffsetAuth } from '../fn/user/get-user-controller-search-offset-auth';
import { GetUserControllerSearchOffsetAuth$Params } from '../fn/user/get-user-controller-search-offset-auth';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { parentControllerFindParent } from '../fn/user/parent-controller-find-parent';
import { ParentControllerFindParent$Params } from '../fn/user/parent-controller-find-parent';
import { patchUserControllerAdminUpdateEmail } from '../fn/user/patch-user-controller-admin-update-email';
import { PatchUserControllerAdminUpdateEmail$Params } from '../fn/user/patch-user-controller-admin-update-email';
import { patchUserControllerBlock } from '../fn/user/patch-user-controller-block';
import { PatchUserControllerBlock$Params } from '../fn/user/patch-user-controller-block';
import { patchUserControllerCheckPassword } from '../fn/user/patch-user-controller-check-password';
import { PatchUserControllerCheckPassword$Params } from '../fn/user/patch-user-controller-check-password';
import { patchUserControllerNoAuthUpdatePassword } from '../fn/user/patch-user-controller-no-auth-update-password';
import { PatchUserControllerNoAuthUpdatePassword$Params } from '../fn/user/patch-user-controller-no-auth-update-password';
import { patchUserControllerUnblock } from '../fn/user/patch-user-controller-unblock';
import { PatchUserControllerUnblock$Params } from '../fn/user/patch-user-controller-unblock';
import { patchUserControllerUpdate } from '../fn/user/patch-user-controller-update';
import { PatchUserControllerUpdate$Params } from '../fn/user/patch-user-controller-update';
import { patchUserControllerUpdateContact } from '../fn/user/patch-user-controller-update-contact';
import { PatchUserControllerUpdateContact$Params } from '../fn/user/patch-user-controller-update-contact';
import { patchUserControllerUpdateEmail } from '../fn/user/patch-user-controller-update-email';
import { PatchUserControllerUpdateEmail$Params } from '../fn/user/patch-user-controller-update-email';
import { patchUserControllerUpdateNotificationEnabled } from '../fn/user/patch-user-controller-update-notification-enabled';
import { PatchUserControllerUpdateNotificationEnabled$Params } from '../fn/user/patch-user-controller-update-notification-enabled';
import { patchUserControllerUpdatePasswordOnLogin } from '../fn/user/patch-user-controller-update-password-on-login';
import { PatchUserControllerUpdatePasswordOnLogin$Params } from '../fn/user/patch-user-controller-update-password-on-login';
import { patchUserControllerWithdraw } from '../fn/user/patch-user-controller-withdraw';
import { PatchUserControllerWithdraw$Params } from '../fn/user/patch-user-controller-withdraw';
import { postUserControllerCheckDuplicateContact } from '../fn/user/post-user-controller-check-duplicate-contact';
import { PostUserControllerCheckDuplicateContact$Params } from '../fn/user/post-user-controller-check-duplicate-contact';
import { postUserControllerConnectAuthByEmail } from '../fn/user/post-user-controller-connect-auth-by-email';
import { PostUserControllerConnectAuthByEmail$Params } from '../fn/user/post-user-controller-connect-auth-by-email';
import { postUserControllerConnectAuthBySns } from '../fn/user/post-user-controller-connect-auth-by-sns';
import { PostUserControllerConnectAuthBySns$Params } from '../fn/user/post-user-controller-connect-auth-by-sns';
import { postUserControllerCreate } from '../fn/user/post-user-controller-create';
import { PostUserControllerCreate$Params } from '../fn/user/post-user-controller-create';
import { postUserControllerFindEmail } from '../fn/user/post-user-controller-find-email';
import { PostUserControllerFindEmail$Params } from '../fn/user/post-user-controller-find-email';
import { postUserControllerFindPasswordConfirm } from '../fn/user/post-user-controller-find-password-confirm';
import { PostUserControllerFindPasswordConfirm$Params } from '../fn/user/post-user-controller-find-password-confirm';
import { postUserControllerLogin } from '../fn/user/post-user-controller-login';
import { PostUserControllerLogin$Params } from '../fn/user/post-user-controller-login';
import { postUserControllerLogout } from '../fn/user/post-user-controller-logout';
import { PostUserControllerLogout$Params } from '../fn/user/post-user-controller-logout';
import { postUserControllerRefresh } from '../fn/user/post-user-controller-refresh';
import { PostUserControllerRefresh$Params } from '../fn/user/post-user-controller-refresh';
import { postUserControllerSendCode } from '../fn/user/post-user-controller-send-code';
import { PostUserControllerSendCode$Params } from '../fn/user/post-user-controller-send-code';
import { postUserControllerSendPasswordResetEmail } from '../fn/user/post-user-controller-send-password-reset-email';
import { PostUserControllerSendPasswordResetEmail$Params } from '../fn/user/post-user-controller-send-password-reset-email';
import { postUserControllerVerifyCode } from '../fn/user/post-user-controller-verify-code';
import { PostUserControllerVerifyCode$Params } from '../fn/user/post-user-controller-verify-code';
import { UserDto } from '../models/user-dto';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUserControllerSearchOffset()` */
  static readonly GetUserControllerSearchOffsetPath = '/api/user/search/offset';

  /**
   * 사용자 오프셋 기반 조회.
   *
   * 사용자를 오프셋 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserControllerSearchOffset$Response(params: GetUserControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return getUserControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 오프셋 기반 조회.
   *
   * 사용자를 오프셋 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserControllerSearchOffset(params: GetUserControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.getUserControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `getUserControllerSearchCursor()` */
  static readonly GetUserControllerSearchCursorPath = '/api/user/search/cursor';

  /**
   * 사용자 커서 기반 조회.
   *
   * 사용자를 커서 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserControllerSearchCursor$Response(params: GetUserControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return getUserControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 커서 기반 조회.
   *
   * 사용자를 커서 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserControllerSearchCursor(params: GetUserControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.getUserControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `getUserControllerSearchOffsetAuth()` */
  static readonly GetUserControllerSearchOffsetAuthPath = '/api/user/search/offset/auth';

  /**
   * AUTH 오프셋 기반 조회.
   *
   * AUTH 를 오프셋 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserControllerSearchOffsetAuth()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserControllerSearchOffsetAuth$Response(params: GetUserControllerSearchOffsetAuth$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return getUserControllerSearchOffsetAuth(this.http, this.rootUrl, params, context);
  }

  /**
   * AUTH 오프셋 기반 조회.
   *
   * AUTH 를 오프셋 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserControllerSearchOffsetAuth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserControllerSearchOffsetAuth(params: GetUserControllerSearchOffsetAuth$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.getUserControllerSearchOffsetAuth$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `getUserControllerCheckEmail()` */
  static readonly GetUserControllerCheckEmailPath = '/api/user/check/email';

  /**
   * 이메일 중복 확인.
   *
   * 이메일 중복을 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserControllerCheckEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserControllerCheckEmail$Response(params: GetUserControllerCheckEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return getUserControllerCheckEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 이메일 중복 확인.
   *
   * 이메일 중복을 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserControllerCheckEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserControllerCheckEmail(params: GetUserControllerCheckEmail$Params, context?: HttpContext): Observable<boolean> {
    return this.getUserControllerCheckEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getUserControllerFindByContact()` */
  static readonly GetUserControllerFindByContactPath = '/api/user/check/contact';

  /**
   * 연락처 중복 확인.
   *
   * 연락처 중복을 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserControllerFindByContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserControllerFindByContact$Response(params: GetUserControllerFindByContact$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return getUserControllerFindByContact(this.http, this.rootUrl, params, context);
  }

  /**
   * 연락처 중복 확인.
   *
   * 연락처 중복을 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserControllerFindByContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserControllerFindByContact(params: GetUserControllerFindByContact$Params, context?: HttpContext): Observable<UserDto> {
    return this.getUserControllerFindByContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `getUserControllerGetMe()` */
  static readonly GetUserControllerGetMePath = '/api/user/me';

  /**
   * 내 정보 조회.
   *
   * 내 정보를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserControllerGetMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserControllerGetMe$Response(params?: GetUserControllerGetMe$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return getUserControllerGetMe(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 정보 조회.
   *
   * 내 정보를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserControllerGetMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserControllerGetMe(params?: GetUserControllerGetMe$Params, context?: HttpContext): Observable<UserDto> {
    return this.getUserControllerGetMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `getUserControllerFindById()` */
  static readonly GetUserControllerFindByIdPath = '/api/user/{id}';

  /**
   * 사용자 ID 기반 조회.
   *
   * 사용자를 ID 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserControllerFindById$Response(params: GetUserControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return getUserControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 ID 기반 조회.
   *
   * 사용자를 ID 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserControllerFindById(params: GetUserControllerFindById$Params, context?: HttpContext): Observable<UserDto> {
    return this.getUserControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `deleteUserControllerDelete()` */
  static readonly DeleteUserControllerDeletePath = '/api/user/{id}';

  /**
   * 사용자 삭제.
   *
   * 사용자를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserControllerDelete$Response(params: DeleteUserControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return deleteUserControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 삭제.
   *
   * 사용자를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUserControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserControllerDelete(params: DeleteUserControllerDelete$Params, context?: HttpContext): Observable<UserDto> {
    return this.deleteUserControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `postUserControllerFindEmail()` */
  static readonly PostUserControllerFindEmailPath = '/api/user/find/email';

  /**
   * 이메일 찾기.
   *
   * 사용자 연락처와 이름를 기반으로 해당 회원의 모든 이메일을 찾습니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserControllerFindEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerFindEmail$Response(params: PostUserControllerFindEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FindEmailResponseDto>>> {
    return postUserControllerFindEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 이메일 찾기.
   *
   * 사용자 연락처와 이름를 기반으로 해당 회원의 모든 이메일을 찾습니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postUserControllerFindEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerFindEmail(params: PostUserControllerFindEmail$Params, context?: HttpContext): Observable<Array<FindEmailResponseDto>> {
    return this.postUserControllerFindEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FindEmailResponseDto>>): Array<FindEmailResponseDto> => r.body)
    );
  }

  /** Path part for operation `postUserControllerFindPasswordConfirm()` */
  static readonly PostUserControllerFindPasswordConfirmPath = '/api/user/find-password-confirm';

  /**
   * 비밀번호 재설정 확인.
   *
   * 입력받은 정보로 비밀번호 재설정을 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserControllerFindPasswordConfirm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerFindPasswordConfirm$Response(params: PostUserControllerFindPasswordConfirm$Params, context?: HttpContext): Observable<StrictHttpResponse<FindPasswordConfirmResponseDto>> {
    return postUserControllerFindPasswordConfirm(this.http, this.rootUrl, params, context);
  }

  /**
   * 비밀번호 재설정 확인.
   *
   * 입력받은 정보로 비밀번호 재설정을 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postUserControllerFindPasswordConfirm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerFindPasswordConfirm(params: PostUserControllerFindPasswordConfirm$Params, context?: HttpContext): Observable<FindPasswordConfirmResponseDto> {
    return this.postUserControllerFindPasswordConfirm$Response(params, context).pipe(
      map((r: StrictHttpResponse<FindPasswordConfirmResponseDto>): FindPasswordConfirmResponseDto => r.body)
    );
  }

  /** Path part for operation `postUserControllerSendPasswordResetEmail()` */
  static readonly PostUserControllerSendPasswordResetEmailPath = '/api/user/find/password';

  /**
   * 비밀번호 재설정 인증 이메일 발송.
   *
   * 비밀번호 재설정 이메일을 발송합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserControllerSendPasswordResetEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  postUserControllerSendPasswordResetEmail$Response(params: PostUserControllerSendPasswordResetEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return postUserControllerSendPasswordResetEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 비밀번호 재설정 인증 이메일 발송.
   *
   * 비밀번호 재설정 이메일을 발송합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postUserControllerSendPasswordResetEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postUserControllerSendPasswordResetEmail(params: PostUserControllerSendPasswordResetEmail$Params, context?: HttpContext): Observable<boolean> {
    return this.postUserControllerSendPasswordResetEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `postUserControllerLogin()` */
  static readonly PostUserControllerLoginPath = '/api/user/signin';

  /**
   * 사용자 이메일 로그인.
   *
   * 사용자가 이메일로 로그인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerLogin$Response(params: PostUserControllerLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'accessToken'?: string;
'refreshToken'?: string;
}>> {
    return postUserControllerLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 이메일 로그인.
   *
   * 사용자가 이메일로 로그인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postUserControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerLogin(params: PostUserControllerLogin$Params, context?: HttpContext): Observable<{
'accessToken'?: string;
'refreshToken'?: string;
}> {
    return this.postUserControllerLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'accessToken'?: string;
'refreshToken'?: string;
}>): {
'accessToken'?: string;
'refreshToken'?: string;
} => r.body)
    );
  }

  /** Path part for operation `postUserControllerCreate()` */
  static readonly PostUserControllerCreatePath = '/api/user/signup';

  /**
   * 이메일 회원가입.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerCreate$Response(params: PostUserControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return postUserControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 이메일 회원가입.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postUserControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerCreate(params: PostUserControllerCreate$Params, context?: HttpContext): Observable<UserDto> {
    return this.postUserControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `postUserControllerConnectAuthByEmail()` */
  static readonly PostUserControllerConnectAuthByEmailPath = '/api/user/connect-auth-by-email';

  /**
   * 이메일 회원가입 시 기존 SNS 계정과 연결.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserControllerConnectAuthByEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerConnectAuthByEmail$Response(params: PostUserControllerConnectAuthByEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return postUserControllerConnectAuthByEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 이메일 회원가입 시 기존 SNS 계정과 연결.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postUserControllerConnectAuthByEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerConnectAuthByEmail(params: PostUserControllerConnectAuthByEmail$Params, context?: HttpContext): Observable<boolean> {
    return this.postUserControllerConnectAuthByEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `postUserControllerConnectAuthBySns()` */
  static readonly PostUserControllerConnectAuthBySnsPath = '/api/user/connect-auth-by-SNS';

  /**
   * 소셜 회원가입 시 기존 계정과 연결.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserControllerConnectAuthBySns()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerConnectAuthBySns$Response(params: PostUserControllerConnectAuthBySns$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return postUserControllerConnectAuthBySns(this.http, this.rootUrl, params, context);
  }

  /**
   * 소셜 회원가입 시 기존 계정과 연결.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postUserControllerConnectAuthBySns$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerConnectAuthBySns(params: PostUserControllerConnectAuthBySns$Params, context?: HttpContext): Observable<boolean> {
    return this.postUserControllerConnectAuthBySns$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `postUserControllerRefresh()` */
  static readonly PostUserControllerRefreshPath = '/api/user/refresh';

  /**
   * 엑세스 토큰 갱신.
   *
   * 엑세스 토큰을 갱신합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserControllerRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  postUserControllerRefresh$Response(params?: PostUserControllerRefresh$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'accessToken'?: string;
}>> {
    return postUserControllerRefresh(this.http, this.rootUrl, params, context);
  }

  /**
   * 엑세스 토큰 갱신.
   *
   * 엑세스 토큰을 갱신합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postUserControllerRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postUserControllerRefresh(params?: PostUserControllerRefresh$Params, context?: HttpContext): Observable<{
'accessToken'?: string;
}> {
    return this.postUserControllerRefresh$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'accessToken'?: string;
}>): {
'accessToken'?: string;
} => r.body)
    );
  }

  /** Path part for operation `postUserControllerLogout()` */
  static readonly PostUserControllerLogoutPath = '/api/user/signout';

  /**
   * 로그아웃.
   *
   * 로그아웃합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserControllerLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  postUserControllerLogout$Response(params?: PostUserControllerLogout$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return postUserControllerLogout(this.http, this.rootUrl, params, context);
  }

  /**
   * 로그아웃.
   *
   * 로그아웃합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postUserControllerLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postUserControllerLogout(params?: PostUserControllerLogout$Params, context?: HttpContext): Observable<boolean> {
    return this.postUserControllerLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `postUserControllerSendCode()` */
  static readonly PostUserControllerSendCodePath = '/api/user/code';

  /**
   * 연락처 인증 코드 발송.
   *
   * 연락처로 인증을 요청합니다. 이름과 함께 요청하는 경우, 계정과 이름이 일치하는지 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserControllerSendCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerSendCode$Response(params: PostUserControllerSendCode$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'token'?: string;
}>> {
    return postUserControllerSendCode(this.http, this.rootUrl, params, context);
  }

  /**
   * 연락처 인증 코드 발송.
   *
   * 연락처로 인증을 요청합니다. 이름과 함께 요청하는 경우, 계정과 이름이 일치하는지 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postUserControllerSendCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerSendCode(params: PostUserControllerSendCode$Params, context?: HttpContext): Observable<{
'token'?: string;
}> {
    return this.postUserControllerSendCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'token'?: string;
}>): {
'token'?: string;
} => r.body)
    );
  }

  /** Path part for operation `postUserControllerVerifyCode()` */
  static readonly PostUserControllerVerifyCodePath = '/api/user/code/verify';

  /**
   * 연락처 인증 코드 확인.
   *
   * 연락처로 전송된 인증 코드를 확인합니다
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserControllerVerifyCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerVerifyCode$Response(params: PostUserControllerVerifyCode$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return postUserControllerVerifyCode(this.http, this.rootUrl, params, context);
  }

  /**
   * 연락처 인증 코드 확인.
   *
   * 연락처로 전송된 인증 코드를 확인합니다
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postUserControllerVerifyCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerVerifyCode(params: PostUserControllerVerifyCode$Params, context?: HttpContext): Observable<boolean> {
    return this.postUserControllerVerifyCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `postUserControllerCheckDuplicateContact()` */
  static readonly PostUserControllerCheckDuplicateContactPath = '/api/user/check-duplicate-contact';

  /**
   * 연락처 중복 여부.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserControllerCheckDuplicateContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerCheckDuplicateContact$Response(params: PostUserControllerCheckDuplicateContact$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return postUserControllerCheckDuplicateContact(this.http, this.rootUrl, params, context);
  }

  /**
   * 연락처 중복 여부.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postUserControllerCheckDuplicateContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserControllerCheckDuplicateContact(params: PostUserControllerCheckDuplicateContact$Params, context?: HttpContext): Observable<boolean> {
    return this.postUserControllerCheckDuplicateContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `patchUserControllerNoAuthUpdatePassword()` */
  static readonly PatchUserControllerNoAuthUpdatePasswordPath = '/api/user/no-auth/password';

  /**
   * 비밀번호 변경(로그인X).
   *
   * 비밀번호를 변경합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserControllerNoAuthUpdatePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerNoAuthUpdatePassword$Response(params: PatchUserControllerNoAuthUpdatePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return patchUserControllerNoAuthUpdatePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * 비밀번호 변경(로그인X).
   *
   * 비밀번호를 변경합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserControllerNoAuthUpdatePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerNoAuthUpdatePassword(params: PatchUserControllerNoAuthUpdatePassword$Params, context?: HttpContext): Observable<boolean> {
    return this.patchUserControllerNoAuthUpdatePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `patchUserControllerCheckPassword()` */
  static readonly PatchUserControllerCheckPasswordPath = '/api/user/check/password';

  /**
   * 현재 비밀번호 확인.
   *
   * 현재 비밀번호가 일치하는지 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserControllerCheckPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerCheckPassword$Response(params: PatchUserControllerCheckPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return patchUserControllerCheckPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * 현재 비밀번호 확인.
   *
   * 현재 비밀번호가 일치하는지 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserControllerCheckPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerCheckPassword(params: PatchUserControllerCheckPassword$Params, context?: HttpContext): Observable<boolean> {
    return this.patchUserControllerCheckPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `patchUserControllerUpdatePasswordOnLogin()` */
  static readonly PatchUserControllerUpdatePasswordOnLoginPath = '/api/user/password';

  /**
   * 내 이메일 계정 비밀번호 변경 (로그인O).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserControllerUpdatePasswordOnLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerUpdatePasswordOnLogin$Response(params: PatchUserControllerUpdatePasswordOnLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return patchUserControllerUpdatePasswordOnLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 이메일 계정 비밀번호 변경 (로그인O).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserControllerUpdatePasswordOnLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerUpdatePasswordOnLogin(params: PatchUserControllerUpdatePasswordOnLogin$Params, context?: HttpContext): Observable<boolean> {
    return this.patchUserControllerUpdatePasswordOnLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `patchUserControllerUpdateContact()` */
  static readonly PatchUserControllerUpdateContactPath = '/api/user/contact';

  /**
   * 연락처 변경.
   *
   * 연락처를 변경합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserControllerUpdateContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerUpdateContact$Response(params: PatchUserControllerUpdateContact$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return patchUserControllerUpdateContact(this.http, this.rootUrl, params, context);
  }

  /**
   * 연락처 변경.
   *
   * 연락처를 변경합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserControllerUpdateContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerUpdateContact(params: PatchUserControllerUpdateContact$Params, context?: HttpContext): Observable<boolean> {
    return this.patchUserControllerUpdateContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `deleteUserControllerDeleteMany()` */
  static readonly DeleteUserControllerDeleteManyPath = '/api/user';

  /**
   * 사용자 다중 삭제.
   *
   * 여러 사용자를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserControllerDeleteMany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUserControllerDeleteMany$Response(params: DeleteUserControllerDeleteMany$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'count'?: number;
}>> {
    return deleteUserControllerDeleteMany(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 다중 삭제.
   *
   * 여러 사용자를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUserControllerDeleteMany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUserControllerDeleteMany(params: DeleteUserControllerDeleteMany$Params, context?: HttpContext): Observable<{
'count'?: number;
}> {
    return this.deleteUserControllerDeleteMany$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>): {
'count'?: number;
} => r.body)
    );
  }

  /** Path part for operation `patchUserControllerUpdate()` */
  static readonly PatchUserControllerUpdatePath = '/api/user';

  /**
   * 사용자 정보 수정.
   *
   * 사용자 정보를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerUpdate$Response(params: PatchUserControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return patchUserControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 정보 수정.
   *
   * 사용자 정보를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerUpdate(params: PatchUserControllerUpdate$Params, context?: HttpContext): Observable<UserDto> {
    return this.patchUserControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `patchUserControllerAdminUpdateEmail()` */
  static readonly PatchUserControllerAdminUpdateEmailPath = '/api/user/admin/email';

  /**
   * 관리자에 의한 사용자 email 수정.
   *
   * 관리자가 사용자 이메일을 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserControllerAdminUpdateEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerAdminUpdateEmail$Response(params: PatchUserControllerAdminUpdateEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return patchUserControllerAdminUpdateEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 관리자에 의한 사용자 email 수정.
   *
   * 관리자가 사용자 이메일을 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserControllerAdminUpdateEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerAdminUpdateEmail(params: PatchUserControllerAdminUpdateEmail$Params, context?: HttpContext): Observable<boolean> {
    return this.patchUserControllerAdminUpdateEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `patchUserControllerUpdateEmail()` */
  static readonly PatchUserControllerUpdateEmailPath = '/api/user/email';

  /**
   * email 수정.
   *
   * 이메일을 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserControllerUpdateEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerUpdateEmail$Response(params: PatchUserControllerUpdateEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return patchUserControllerUpdateEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * email 수정.
   *
   * 이메일을 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserControllerUpdateEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerUpdateEmail(params: PatchUserControllerUpdateEmail$Params, context?: HttpContext): Observable<boolean> {
    return this.patchUserControllerUpdateEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `patchUserControllerWithdraw()` */
  static readonly PatchUserControllerWithdrawPath = '/api/user/withdraw';

  /**
   * 회원 탈퇴.
   *
   * 회원을 탈퇴
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserControllerWithdraw()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerWithdraw$Response(params: PatchUserControllerWithdraw$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return patchUserControllerWithdraw(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 탈퇴.
   *
   * 회원을 탈퇴
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserControllerWithdraw$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserControllerWithdraw(params: PatchUserControllerWithdraw$Params, context?: HttpContext): Observable<boolean> {
    return this.patchUserControllerWithdraw$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `patchUserControllerBlock()` */
  static readonly PatchUserControllerBlockPath = '/api/user/{id}/block';

  /**
   * 사용자 차단.
   *
   * 사용자를 차단합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserControllerBlock()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchUserControllerBlock$Response(params: PatchUserControllerBlock$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return patchUserControllerBlock(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 차단.
   *
   * 사용자를 차단합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserControllerBlock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchUserControllerBlock(params: PatchUserControllerBlock$Params, context?: HttpContext): Observable<boolean> {
    return this.patchUserControllerBlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `patchUserControllerUnblock()` */
  static readonly PatchUserControllerUnblockPath = '/api/user/{id}/unblock';

  /**
   * 사용자 차단 해제.
   *
   * 사용자의 차단을 해제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserControllerUnblock()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchUserControllerUnblock$Response(params: PatchUserControllerUnblock$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return patchUserControllerUnblock(this.http, this.rootUrl, params, context);
  }

  /**
   * 사용자 차단 해제.
   *
   * 사용자의 차단을 해제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserControllerUnblock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchUserControllerUnblock(params: PatchUserControllerUnblock$Params, context?: HttpContext): Observable<boolean> {
    return this.patchUserControllerUnblock$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `patchUserControllerUpdateNotificationEnabled()` */
  static readonly PatchUserControllerUpdateNotificationEnabledPath = '/api/user/toggle-notification';

  /**
   * 푸시 알림 변경.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserControllerUpdateNotificationEnabled()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchUserControllerUpdateNotificationEnabled$Response(params?: PatchUserControllerUpdateNotificationEnabled$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return patchUserControllerUpdateNotificationEnabled(this.http, this.rootUrl, params, context);
  }

  /**
   * 푸시 알림 변경.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserControllerUpdateNotificationEnabled$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchUserControllerUpdateNotificationEnabled(params?: PatchUserControllerUpdateNotificationEnabled$Params, context?: HttpContext): Observable<boolean> {
    return this.patchUserControllerUpdateNotificationEnabled$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `deleteUserControllerGoogleTest()` */
  static readonly DeleteUserControllerGoogleTestPath = '/api/user/delete-user';

  /**
   * 구글 테스트.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserControllerGoogleTest()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserControllerGoogleTest$Response(params?: DeleteUserControllerGoogleTest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteUserControllerGoogleTest(this.http, this.rootUrl, params, context);
  }

  /**
   * 구글 테스트.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUserControllerGoogleTest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserControllerGoogleTest(params?: DeleteUserControllerGoogleTest$Params, context?: HttpContext): Observable<void> {
    return this.deleteUserControllerGoogleTest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `parentControllerFindParent()` */
  static readonly ParentControllerFindParentPath = '/api/user/parent/search';

  /**
   * 보호자 조회.
   *
   * 보호자를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentControllerFindParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentControllerFindParent$Response(params?: ParentControllerFindParent$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDto>>> {
    return parentControllerFindParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 조회.
   *
   * 보호자를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentControllerFindParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentControllerFindParent(params?: ParentControllerFindParent$Params, context?: HttpContext): Observable<Array<UserDto>> {
    return this.parentControllerFindParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>): Array<UserDto> => r.body)
    );
  }

}
