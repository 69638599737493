/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AllScheduleDto } from '../../models/all-schedule-dto';
import { Object } from '../../models/object';

export interface AllScheduleControllerFind$Params {

/**
 * 유저 ID
 */
  userId?: string;

/**
 * 아동 ID
 */
  childId?: string;

/**
 * 센터 ID
 */
  centerId?: string;

/**
 * 선생님 ID
 */
  teacherIds?: Array<string>;

/**
 * 치료 상태
 */
  ticketStatusList?: Array<'BEFORE_COUNSELING' | 'PENDING' | 'PROGRESS' | 'COMPLETED'>;

/**
 * 치료 유형
 */
  ticketTypes?: Array<string>;

/**
 * 바우처
 */
  vouchers?: Array<string>;

/**
 * 유형
 */
  types?: Array<'PERSONAL' | 'CENTER' | 'TREATMENT'>;

/**
 * 날짜 유형
 */
  dateType?: Object;

/**
 * 센터 검색 유형
 */
  centerSearchType?: 'CURRENT_CENTER' | 'ALL';

/**
 * 시작 날짜
 */
  date?: string;
}

export function allScheduleControllerFind(http: HttpClient, rootUrl: string, params?: AllScheduleControllerFind$Params, context?: HttpContext): Observable<StrictHttpResponse<AllScheduleDto>> {
  const rb = new RequestBuilder(rootUrl, allScheduleControllerFind.PATH, 'get');
  if (params) {
    rb.query('userId', params.userId, {});
    rb.query('childId', params.childId, {});
    rb.query('centerId', params.centerId, {});
    rb.query('teacherIds', params.teacherIds, {});
    rb.query('ticketStatusList', params.ticketStatusList, {});
    rb.query('ticketTypes', params.ticketTypes, {});
    rb.query('vouchers', params.vouchers, {});
    rb.query('types', params.types, {});
    rb.query('dateType', params.dateType, {});
    rb.query('centerSearchType', params.centerSearchType, {});
    rb.query('date', params.date, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AllScheduleDto>;
    })
  );
}

allScheduleControllerFind.PATH = '/api/all-schedule';
