/**
 * company-info 키
 */
export const COMPANY_INFO_KEY = 'malirang_company_info.json';

/**
 * 개인정보취급방침 키
 */
export const PRIVACY_KEY = 'malirang_privacy.txt';

/**
 * 이용약관 키
 */
export const USE_KEY = 'malirang_use.txt';

/**
 * 컨테이너 키
 */
export const INFO_CONTAINER = 'info';
