/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { fnGetKeyByValue, OAuthProvider } from '@malirang/common';

@Component({
  selector: 'app-o-auth-icon',
  imports: [CommonModule],
  templateUrl: './o-auth-icon.component.html',
})
export class OAuthIconComponent {
  provider = input.required<keyof typeof OAuthProvider>();
  disabled = input<boolean>(false);
  onClick = input<() => void>();

  fnGetKeyByValue = fnGetKeyByValue;
  oathProvider = OAuthProvider;

  clickHandler(ev?: Event) {
    ev?.stopPropagation();
    if (this.onClick() && this.disabled()) {
      this.onClick()!();
    }
  }
}
