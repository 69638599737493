import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  inject,
  OnInit,
  viewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MarkdownComponent, provideMarkdown } from 'ngx-markdown';
import { ThemeStore } from '../../stores/theme.store';
import { Button } from '../button/button.component';
import { Color, Icon, Input } from '../components';
import { Textarea } from '../textarea/textarea.component';
import { AlertOptions, AlertResult } from './alert.type';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
  imports: [
    Icon,
    Input,
    Button,
    Textarea,
    FormsModule,
    ReactiveFormsModule,
    MarkdownComponent,
    CommonModule,
  ],
  providers: [provideMarkdown()],
})
export class AlertComponent implements OnInit, AfterViewInit {
  readonly dialogRef = inject<DialogRef<AlertResult>>(DialogRef<AlertResult>);
  readonly dialogData = inject<AlertOptions>(DIALOG_DATA);
  readonly themeStore = inject(ThemeStore);

  modalContainer = viewChild<ElementRef<HTMLElement>>('modalContainer');

  scrollIndicator =
    viewChild.required<ElementRef<HTMLDivElement>>('scrollIndicator');

  form: FormGroup | null = null;

  theme = this.themeStore.theme;

  iconName = computed(() => {
    const _icon = this.dialogData.icon?.name;
    if (_icon) {
      return _icon;
    }

    switch (this.dialogData.type) {
      case 'success':
        return 'material-symbols:check-circle';
      case 'warning':
        return 'material-symbols:warning-rounded';
      case 'danger':
        return 'solar:danger-circle-bold';
    }
  });

  iconColor = computed<Color>(() => {
    if (this.dialogData.icon?.color) {
      return this.dialogData.icon?.color;
    }

    switch (this.dialogData.type) {
      case 'success':
        return 'green';
      case 'warning':
        return 'yellow';
      case 'danger':
        return 'red';
    }
  });

  iconClass = computed(() => {
    switch (this.dialogData.type) {
      case 'success':
        return 'text-green-500';
      case 'warning':
        return 'text-yellow-500';
      case 'danger':
        return 'text-red-500';
    }
  });

  ngAfterViewInit() {
    if (!this.modalContainer()) return;

    this.modalContainer()?.nativeElement.animate(
      [
        { opacity: 0, transform: 'translateY(10px)' },
        { opacity: 1, transform: 'translateY(0)' },
      ],
      {
        duration: 200,
        easing: 'ease-in-out',
      },
    );
  }

  ngOnInit(): void {
    if (this.dialogData.inputs) {
      this.form = new FormGroup({});
      this.dialogData.inputs.forEach((input) => {
        const formControl = new FormControl(input.defaultValue, {
          validators:
            input.required || input.required !== false
              ? [Validators.required]
              : [],
        });
        this.form?.addControl(input.key, formControl);
      });
    }
  }

  onCancel() {
    this.dialogRef.close({
      action: 'cancel',
    });
  }

  onConfirm() {
    this.dialogRef.close({
      action: 'confirm',
      data: this.form?.getRawValue(),
    });
  }

  onScroll(event: any) {
    const target = event.target;
    if (!target) return;
    const clientHeight = target.clientHeight;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;

    const threshold = scrollHeight - clientHeight - 100;

    const hasScrollLeft = threshold > scrollTop;

    this.scrollIndicator().nativeElement.style.opacity = hasScrollLeft
      ? '1'
      : '0';
  }
}
