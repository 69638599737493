/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { centerUserControllerDelegate } from '../fn/center-user/center-user-controller-delegate';
import { CenterUserControllerDelegate$Params } from '../fn/center-user/center-user-controller-delegate';
import { centerUserControllerDeleteUser } from '../fn/center-user/center-user-controller-delete-user';
import { CenterUserControllerDeleteUser$Params } from '../fn/center-user/center-user-controller-delete-user';
import { centerUserControllerFindUserById } from '../fn/center-user/center-user-controller-find-user-by-id';
import { CenterUserControllerFindUserById$Params } from '../fn/center-user/center-user-controller-find-user-by-id';
import { centerUserControllerFindUsersByCenterId } from '../fn/center-user/center-user-controller-find-users-by-center-id';
import { CenterUserControllerFindUsersByCenterId$Params } from '../fn/center-user/center-user-controller-find-users-by-center-id';
import { centerUserControllerSearch } from '../fn/center-user/center-user-controller-search';
import { CenterUserControllerSearch$Params } from '../fn/center-user/center-user-controller-search';
import { centerUserControllerUpdateUser } from '../fn/center-user/center-user-controller-update-user';
import { CenterUserControllerUpdateUser$Params } from '../fn/center-user/center-user-controller-update-user';
import { CenterUserDto } from '../models/center-user-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class CenterUserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `centerUserControllerFindUsersByCenterId()` */
  static readonly CenterUserControllerFindUsersByCenterIdPath = '/api/center-user/{centerId}/users';

  /**
   * 센터 ID 기반 구성원 목록 조회.
   *
   * 센터 ID를 기반으로 구성원 전체를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerUserControllerFindUsersByCenterId()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerFindUsersByCenterId$Response(params: CenterUserControllerFindUsersByCenterId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CenterUserDto>>> {
    return centerUserControllerFindUsersByCenterId(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 ID 기반 구성원 목록 조회.
   *
   * 센터 ID를 기반으로 구성원 전체를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerUserControllerFindUsersByCenterId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerFindUsersByCenterId(params: CenterUserControllerFindUsersByCenterId$Params, context?: HttpContext): Observable<Array<CenterUserDto>> {
    return this.centerUserControllerFindUsersByCenterId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CenterUserDto>>): Array<CenterUserDto> => r.body)
    );
  }

  /** Path part for operation `centerUserControllerSearch()` */
  static readonly CenterUserControllerSearchPath = '/api/center-user/{centerId}/users/search';

  /**
   * 센터 ID 기반 구성원 검색.
   *
   * 센터 ID를 기반으로 구성원 전체를 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerUserControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerSearch$Response(params: CenterUserControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return centerUserControllerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 ID 기반 구성원 검색.
   *
   * 센터 ID를 기반으로 구성원 전체를 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerUserControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerSearch(params: CenterUserControllerSearch$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.centerUserControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerUserControllerFindUserById()` */
  static readonly CenterUserControllerFindUserByIdPath = '/api/center-user/{centerId}/users/{userId}';

  /**
   * 센터 구성원 ID 기반 조회.
   *
   * 센터 구성원을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerUserControllerFindUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerFindUserById$Response(params: CenterUserControllerFindUserById$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterUserDto>> {
    return centerUserControllerFindUserById(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 구성원 ID 기반 조회.
   *
   * 센터 구성원을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerUserControllerFindUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerFindUserById(params: CenterUserControllerFindUserById$Params, context?: HttpContext): Observable<CenterUserDto> {
    return this.centerUserControllerFindUserById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterUserDto>): CenterUserDto => r.body)
    );
  }

  /** Path part for operation `centerUserControllerDeleteUser()` */
  static readonly CenterUserControllerDeleteUserPath = '/api/center-user/{centerId}/users/{userId}';

  /**
   * 센터 구성원 추방.
   *
   * 센터 구성원을 추방합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerUserControllerDeleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerDeleteUser$Response(params: CenterUserControllerDeleteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterUserDto>> {
    return centerUserControllerDeleteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 구성원 추방.
   *
   * 센터 구성원을 추방합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerUserControllerDeleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerDeleteUser(params: CenterUserControllerDeleteUser$Params, context?: HttpContext): Observable<CenterUserDto> {
    return this.centerUserControllerDeleteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterUserDto>): CenterUserDto => r.body)
    );
  }

  /** Path part for operation `centerUserControllerUpdateUser()` */
  static readonly CenterUserControllerUpdateUserPath = '/api/center-user/{centerId}/users/{userId}';

  /**
   * 센터 구성원 역할 변경.
   *
   * 센터 구성원의 역할을 변경합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerUserControllerUpdateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerUserControllerUpdateUser$Response(params: CenterUserControllerUpdateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterUserDto>> {
    return centerUserControllerUpdateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 구성원 역할 변경.
   *
   * 센터 구성원의 역할을 변경합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerUserControllerUpdateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerUserControllerUpdateUser(params: CenterUserControllerUpdateUser$Params, context?: HttpContext): Observable<CenterUserDto> {
    return this.centerUserControllerUpdateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterUserDto>): CenterUserDto => r.body)
    );
  }

  /** Path part for operation `centerUserControllerDelegate()` */
  static readonly CenterUserControllerDelegatePath = '/api/center-user/{centerId}/users/{userId}/delegate';

  /**
   * 센터 대표 위임.
   *
   * 센터 대표를 위임합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerUserControllerDelegate()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerDelegate$Response(params: CenterUserControllerDelegate$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterUserDto>> {
    return centerUserControllerDelegate(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 대표 위임.
   *
   * 센터 대표를 위임합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerUserControllerDelegate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerUserControllerDelegate(params: CenterUserControllerDelegate$Params, context?: HttpContext): Observable<CenterUserDto> {
    return this.centerUserControllerDelegate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterUserDto>): CenterUserDto => r.body)
    );
  }

}
