<div class="flex gap-3 cursor-pointer group items-start">
  <div [attr.data-theme]="theme()" class="wrapper" [class.active]="active()">
    <div
      class="circle"
      [attr.data-theme]="theme()"
      [class.active]="active()"
    ></div>
  </div>
  <div class="flex flex-col gap-1 leading-none">
    <p [attr.data-theme]="theme()" class="theme-text text-base">
      {{ label() }}
    </p>
    @if (description(); as description) {
      <p
        [attr.data-theme]="theme()"
        class="theme-text"
        class="text-sm text-gray-500"
      >
        {{ description }}
      </p>
    }
  </div>
</div>
