/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminContentControllerChangeStatus } from '../fn/content/admin-content-controller-change-status';
import { AdminContentControllerChangeStatus$Params } from '../fn/content/admin-content-controller-change-status';
import { adminContentControllerCreate } from '../fn/content/admin-content-controller-create';
import { AdminContentControllerCreate$Params } from '../fn/content/admin-content-controller-create';
import { adminContentControllerDelete } from '../fn/content/admin-content-controller-delete';
import { AdminContentControllerDelete$Params } from '../fn/content/admin-content-controller-delete';
import { adminContentControllerFindById } from '../fn/content/admin-content-controller-find-by-id';
import { AdminContentControllerFindById$Params } from '../fn/content/admin-content-controller-find-by-id';
import { adminContentControllerPin } from '../fn/content/admin-content-controller-pin';
import { AdminContentControllerPin$Params } from '../fn/content/admin-content-controller-pin';
import { adminContentControllerSearch } from '../fn/content/admin-content-controller-search';
import { AdminContentControllerSearch$Params } from '../fn/content/admin-content-controller-search';
import { adminContentControllerUpdate } from '../fn/content/admin-content-controller-update';
import { AdminContentControllerUpdate$Params } from '../fn/content/admin-content-controller-update';
import { ContentDto } from '../models/content-dto';
import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { userContentControllerFindById } from '../fn/content/user-content-controller-find-by-id';
import { UserContentControllerFindById$Params } from '../fn/content/user-content-controller-find-by-id';
import { userContentControllerSearchCursor } from '../fn/content/user-content-controller-search-cursor';
import { UserContentControllerSearchCursor$Params } from '../fn/content/user-content-controller-search-cursor';

@Injectable({ providedIn: 'root' })
export class ContentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminContentControllerSearch()` */
  static readonly AdminContentControllerSearchPath = '/api/content/admin/search';

  /**
   * 콘텐츠 검색.
   *
   * 콘텐츠를 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentControllerSearch$Response(params: AdminContentControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return adminContentControllerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * 콘텐츠 검색.
   *
   * 콘텐츠를 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentControllerSearch(params: AdminContentControllerSearch$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.adminContentControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `adminContentControllerFindById()` */
  static readonly AdminContentControllerFindByIdPath = '/api/content/admin/{id}';

  /**
   * 콘텐츠 조회.
   *
   * 콘텐츠를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentControllerFindById$Response(params: AdminContentControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentDto>> {
    return adminContentControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 콘텐츠 조회.
   *
   * 콘텐츠를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentControllerFindById(params: AdminContentControllerFindById$Params, context?: HttpContext): Observable<ContentDto> {
    return this.adminContentControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDto>): ContentDto => r.body)
    );
  }

  /** Path part for operation `adminContentControllerDelete()` */
  static readonly AdminContentControllerDeletePath = '/api/content/admin/{id}';

  /**
   * 콘텐츠 삭제.
   *
   * 콘텐츠를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentControllerDelete$Response(params: AdminContentControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminContentControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 콘텐츠 삭제.
   *
   * 콘텐츠를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentControllerDelete(params: AdminContentControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.adminContentControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminContentControllerUpdate()` */
  static readonly AdminContentControllerUpdatePath = '/api/content/admin/{id}';

  /**
   * 콘텐츠 수정.
   *
   * 콘텐츠를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminContentControllerUpdate$Response(params: AdminContentControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentDto>> {
    return adminContentControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 콘텐츠 수정.
   *
   * 콘텐츠를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminContentControllerUpdate(params: AdminContentControllerUpdate$Params, context?: HttpContext): Observable<ContentDto> {
    return this.adminContentControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDto>): ContentDto => r.body)
    );
  }

  /** Path part for operation `adminContentControllerCreate()` */
  static readonly AdminContentControllerCreatePath = '/api/content/admin';

  /**
   * 콘텐츠 등록.
   *
   * 콘텐츠를 등록합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminContentControllerCreate$Response(params: AdminContentControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentDto>> {
    return adminContentControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 콘텐츠 등록.
   *
   * 콘텐츠를 등록합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminContentControllerCreate(params: AdminContentControllerCreate$Params, context?: HttpContext): Observable<ContentDto> {
    return this.adminContentControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDto>): ContentDto => r.body)
    );
  }

  /** Path part for operation `adminContentControllerChangeStatus()` */
  static readonly AdminContentControllerChangeStatusPath = '/api/content/admin/{id}/status';

  /**
   * 콘텐츠 상태 변경.
   *
   * 콘텐츠의 상태를 변경합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentControllerChangeStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentControllerChangeStatus$Response(params: AdminContentControllerChangeStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentDto>> {
    return adminContentControllerChangeStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * 콘텐츠 상태 변경.
   *
   * 콘텐츠의 상태를 변경합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentControllerChangeStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentControllerChangeStatus(params: AdminContentControllerChangeStatus$Params, context?: HttpContext): Observable<ContentDto> {
    return this.adminContentControllerChangeStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDto>): ContentDto => r.body)
    );
  }

  /** Path part for operation `adminContentControllerPin()` */
  static readonly AdminContentControllerPinPath = '/api/content/admin/{id}/pin';

  /**
   * 콘텐츠 고정.
   *
   * 콘텐츠를 고정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentControllerPin()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentControllerPin$Response(params: AdminContentControllerPin$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentDto>> {
    return adminContentControllerPin(this.http, this.rootUrl, params, context);
  }

  /**
   * 콘텐츠 고정.
   *
   * 콘텐츠를 고정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentControllerPin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentControllerPin(params: AdminContentControllerPin$Params, context?: HttpContext): Observable<ContentDto> {
    return this.adminContentControllerPin$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDto>): ContentDto => r.body)
    );
  }

  /** Path part for operation `userContentControllerSearchCursor()` */
  static readonly UserContentControllerSearchCursorPath = '/api/content/user/search';

  /**
   * 콘텐츠 검색(노출된 것만).
   *
   * 콘텐츠를 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userContentControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  userContentControllerSearchCursor$Response(params: UserContentControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return userContentControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 콘텐츠 검색(노출된 것만).
   *
   * 콘텐츠를 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userContentControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userContentControllerSearchCursor(params: UserContentControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.userContentControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `userContentControllerFindById()` */
  static readonly UserContentControllerFindByIdPath = '/api/content/user/{id}';

  /**
   * 콘텐츠 조회.
   *
   * 콘텐츠를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userContentControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  userContentControllerFindById$Response(params: UserContentControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentDto>> {
    return userContentControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 콘텐츠 조회.
   *
   * 콘텐츠를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userContentControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userContentControllerFindById(params: UserContentControllerFindById$Params, context?: HttpContext): Observable<ContentDto> {
    return this.userContentControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDto>): ContentDto => r.body)
    );
  }

}
