<div
  [class.border-r]="index() !== 6"
  [attr.data-theme]="theme()"
  [style.height]="height()"
  class="group item border-t theme-border"
>
  @if (selected() && options()?.day?.selected) {
    <div
      class="absolute inset-0 border-primary w-full h-full border-2 z-[999] pointer-events-none"
    ></div>
  }
  <div class="flex w-full justify-center md:justify-between items-center">
    <p
      class="text theme-text rounded-full"
      [attr.data-theme]="theme()"
      [attr.data-text]="type()"
      [attr.data-background]="type()"
    >
      {{ day().format('DD') }}
    </p>

    <!-- 등록 버튼  -->
    @if (options()?.event?.create) {
      <button
        (click)="handleCreate($event)"
        class="group-hover:md:flex create-button hidden rounded-full bg-primary size-8 justify-center items-center z-[999]"
      >
        <app-icon name="mdi:plus" class="text-white" />
      </button>
    }
  </div>
  <app-block-calendar-mobile-event [day]="day()" />
</div>
