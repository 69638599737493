/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { centerInviteLogControlllerAcceptInvite } from '../fn/center-invite-log/center-invite-log-controlller-accept-invite';
import { CenterInviteLogControlllerAcceptInvite$Params } from '../fn/center-invite-log/center-invite-log-controlller-accept-invite';
import { centerInviteLogControlllerInviteUser } from '../fn/center-invite-log/center-invite-log-controlller-invite-user';
import { CenterInviteLogControlllerInviteUser$Params } from '../fn/center-invite-log/center-invite-log-controlller-invite-user';
import { centerInviteLogControlllerRejectInvite } from '../fn/center-invite-log/center-invite-log-controlller-reject-invite';
import { CenterInviteLogControlllerRejectInvite$Params } from '../fn/center-invite-log/center-invite-log-controlller-reject-invite';
import { centerInviteLogControlllerSearchCursor } from '../fn/center-invite-log/center-invite-log-controlller-search-cursor';
import { CenterInviteLogControlllerSearchCursor$Params } from '../fn/center-invite-log/center-invite-log-controlller-search-cursor';
import { centerInviteLogControlllerSearchOffset } from '../fn/center-invite-log/center-invite-log-controlller-search-offset';
import { CenterInviteLogControlllerSearchOffset$Params } from '../fn/center-invite-log/center-invite-log-controlller-search-offset';
import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { InviteLogDto } from '../models/invite-log-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class CenterInviteLogService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `centerInviteLogControlllerSearchOffset()` */
  static readonly CenterInviteLogControlllerSearchOffsetPath = '/api/center-invite-log/invite/search/offset';

  /**
   * 센터 초대 내역 오프셋 기반 검색.
   *
   * 센터 초대 내역을 오프셋 기반으로 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerInviteLogControlllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerSearchOffset$Response(params: CenterInviteLogControlllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return centerInviteLogControlllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 초대 내역 오프셋 기반 검색.
   *
   * 센터 초대 내역을 오프셋 기반으로 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerInviteLogControlllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerSearchOffset(params: CenterInviteLogControlllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.centerInviteLogControlllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerInviteLogControlllerSearchCursor()` */
  static readonly CenterInviteLogControlllerSearchCursorPath = '/api/center-invite-log/invite/search/cursor';

  /**
   * 센터 초대 내역 커서 기반 검색.
   *
   * 센터 초대 내역을 커서 기반으로 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerInviteLogControlllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerSearchCursor$Response(params: CenterInviteLogControlllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return centerInviteLogControlllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 초대 내역 커서 기반 검색.
   *
   * 센터 초대 내역을 커서 기반으로 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerInviteLogControlllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerSearchCursor(params: CenterInviteLogControlllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.centerInviteLogControlllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerInviteLogControlllerInviteUser()` */
  static readonly CenterInviteLogControlllerInviteUserPath = '/api/center-invite-log/{id}/invite';

  /**
   * 센터 구성원 초대.
   *
   * 센터 멤버를 초대합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerInviteLogControlllerInviteUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerInviteLogControlllerInviteUser$Response(params: CenterInviteLogControlllerInviteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<InviteLogDto>> {
    return centerInviteLogControlllerInviteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 구성원 초대.
   *
   * 센터 멤버를 초대합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerInviteLogControlllerInviteUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerInviteLogControlllerInviteUser(params: CenterInviteLogControlllerInviteUser$Params, context?: HttpContext): Observable<InviteLogDto> {
    return this.centerInviteLogControlllerInviteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<InviteLogDto>): InviteLogDto => r.body)
    );
  }

  /** Path part for operation `centerInviteLogControlllerAcceptInvite()` */
  static readonly CenterInviteLogControlllerAcceptInvitePath = '/api/center-invite-log/invite/{id}/accept';

  /**
   * 센터 초대 수락.
   *
   * 센터 초대를 수락합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerInviteLogControlllerAcceptInvite()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerAcceptInvite$Response(params: CenterInviteLogControlllerAcceptInvite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return centerInviteLogControlllerAcceptInvite(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 초대 수락.
   *
   * 센터 초대를 수락합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerInviteLogControlllerAcceptInvite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerAcceptInvite(params: CenterInviteLogControlllerAcceptInvite$Params, context?: HttpContext): Observable<void> {
    return this.centerInviteLogControlllerAcceptInvite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `centerInviteLogControlllerRejectInvite()` */
  static readonly CenterInviteLogControlllerRejectInvitePath = '/api/center-invite-log/invite/{id}/reject';

  /**
   * 센터 초대 거절.
   *
   * 센터 초대를 거절합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerInviteLogControlllerRejectInvite()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerRejectInvite$Response(params: CenterInviteLogControlllerRejectInvite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return centerInviteLogControlllerRejectInvite(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 초대 거절.
   *
   * 센터 초대를 거절합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerInviteLogControlllerRejectInvite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerInviteLogControlllerRejectInvite(params: CenterInviteLogControlllerRejectInvite$Params, context?: HttpContext): Observable<void> {
    return this.centerInviteLogControlllerRejectInvite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
