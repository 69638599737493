/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { naverAuthControllerConnect } from '../fn/naver/naver-auth-controller-connect';
import { NaverAuthControllerConnect$Params } from '../fn/naver/naver-auth-controller-connect';
import { naverAuthControllerNaverLogin } from '../fn/naver/naver-auth-controller-naver-login';
import { NaverAuthControllerNaverLogin$Params } from '../fn/naver/naver-auth-controller-naver-login';
import { naverAuthControllerNaverLoginWithIos } from '../fn/naver/naver-auth-controller-naver-login-with-ios';
import { NaverAuthControllerNaverLoginWithIos$Params } from '../fn/naver/naver-auth-controller-naver-login-with-ios';
import { OAuthResponseDto } from '../models/o-auth-response-dto';
import { UserDto } from '../models/user-dto';

@Injectable({ providedIn: 'root' })
export class NaverService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `naverAuthControllerNaverLogin()` */
  static readonly NaverAuthControllerNaverLoginPath = '/api/oauth/naver/signin';

  /**
   * 네이버 로그인.
   *
   * 네이버 로그인을 수행합니다. 로그인에 성공하면, 엑세스, 리프레쉬 토큰은 응답 헤더에 포함됩니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `naverAuthControllerNaverLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  naverAuthControllerNaverLogin$Response(params: NaverAuthControllerNaverLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<OAuthResponseDto>> {
    return naverAuthControllerNaverLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * 네이버 로그인.
   *
   * 네이버 로그인을 수행합니다. 로그인에 성공하면, 엑세스, 리프레쉬 토큰은 응답 헤더에 포함됩니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `naverAuthControllerNaverLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  naverAuthControllerNaverLogin(params: NaverAuthControllerNaverLogin$Params, context?: HttpContext): Observable<OAuthResponseDto> {
    return this.naverAuthControllerNaverLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<OAuthResponseDto>): OAuthResponseDto => r.body)
    );
  }

  /** Path part for operation `naverAuthControllerNaverLoginWithIos()` */
  static readonly NaverAuthControllerNaverLoginWithIosPath = '/api/oauth/naver/ios/signin';

  /**
   * 네이버 로그인.
   *
   * 네이버 로그인을 수행합니다. 로그인에 성공하면, 엑세스, 리프레쉬 토큰은 응답 헤더에 포함됩니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `naverAuthControllerNaverLoginWithIos()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  naverAuthControllerNaverLoginWithIos$Response(params: NaverAuthControllerNaverLoginWithIos$Params, context?: HttpContext): Observable<StrictHttpResponse<OAuthResponseDto>> {
    return naverAuthControllerNaverLoginWithIos(this.http, this.rootUrl, params, context);
  }

  /**
   * 네이버 로그인.
   *
   * 네이버 로그인을 수행합니다. 로그인에 성공하면, 엑세스, 리프레쉬 토큰은 응답 헤더에 포함됩니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `naverAuthControllerNaverLoginWithIos$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  naverAuthControllerNaverLoginWithIos(params: NaverAuthControllerNaverLoginWithIos$Params, context?: HttpContext): Observable<OAuthResponseDto> {
    return this.naverAuthControllerNaverLoginWithIos$Response(params, context).pipe(
      map((r: StrictHttpResponse<OAuthResponseDto>): OAuthResponseDto => r.body)
    );
  }

  /** Path part for operation `naverAuthControllerConnect()` */
  static readonly NaverAuthControllerConnectPath = '/api/oauth/naver/connect';

  /**
   * 네이버 계정 연결.
   *
   * 기존에 가입된 계정이 있는 경우 소셜 계정을 연결합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `naverAuthControllerConnect()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  naverAuthControllerConnect$Response(params: NaverAuthControllerConnect$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return naverAuthControllerConnect(this.http, this.rootUrl, params, context);
  }

  /**
   * 네이버 계정 연결.
   *
   * 기존에 가입된 계정이 있는 경우 소셜 계정을 연결합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `naverAuthControllerConnect$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  naverAuthControllerConnect(params: NaverAuthControllerConnect$Params, context?: HttpContext): Observable<UserDto> {
    return this.naverAuthControllerConnect$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

}
