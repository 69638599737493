/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminChildControllerDelete } from '../fn/child/admin-child-controller-delete';
import { AdminChildControllerDelete$Params } from '../fn/child/admin-child-controller-delete';
import { adminChildControllerFindById } from '../fn/child/admin-child-controller-find-by-id';
import { AdminChildControllerFindById$Params } from '../fn/child/admin-child-controller-find-by-id';
import { adminChildControllerFindByRegistrationNo } from '../fn/child/admin-child-controller-find-by-registration-no';
import { AdminChildControllerFindByRegistrationNo$Params } from '../fn/child/admin-child-controller-find-by-registration-no';
import { adminChildControllerSearch } from '../fn/child/admin-child-controller-search';
import { AdminChildControllerSearch$Params } from '../fn/child/admin-child-controller-search';
import { adminChildControllerUpdate } from '../fn/child/admin-child-controller-update';
import { AdminChildControllerUpdate$Params } from '../fn/child/admin-child-controller-update';
import { adminChildControllerValidConflict } from '../fn/child/admin-child-controller-valid-conflict';
import { AdminChildControllerValidConflict$Params } from '../fn/child/admin-child-controller-valid-conflict';
import { ChildDto } from '../models/child-dto';
import { ChildSearchFilterItemsDto } from '../models/child-search-filter-items-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { parentChildControllerDisconnectByParent } from '../fn/child/parent-child-controller-disconnect-by-parent';
import { ParentChildControllerDisconnectByParent$Params } from '../fn/child/parent-child-controller-disconnect-by-parent';
import { parentChildControllerFindOneByParent } from '../fn/child/parent-child-controller-find-one-by-parent';
import { ParentChildControllerFindOneByParent$Params } from '../fn/child/parent-child-controller-find-one-by-parent';
import { parentChildControllerParentCreate } from '../fn/child/parent-child-controller-parent-create';
import { ParentChildControllerParentCreate$Params } from '../fn/child/parent-child-controller-parent-create';
import { parentChildControllerSearchByParent } from '../fn/child/parent-child-controller-search-by-parent';
import { ParentChildControllerSearchByParent$Params } from '../fn/child/parent-child-controller-search-by-parent';
import { parentChildControllerUpdateByParent } from '../fn/child/parent-child-controller-update-by-parent';
import { ParentChildControllerUpdateByParent$Params } from '../fn/child/parent-child-controller-update-by-parent';
import { SearchChildResponseDto } from '../models/search-child-response-dto';
import { userChildControllerCenterCreate } from '../fn/child/user-child-controller-center-create';
import { UserChildControllerCenterCreate$Params } from '../fn/child/user-child-controller-center-create';
import { userChildControllerConnect } from '../fn/child/user-child-controller-connect';
import { UserChildControllerConnect$Params } from '../fn/child/user-child-controller-connect';
import { userChildControllerDisconnect } from '../fn/child/user-child-controller-disconnect';
import { UserChildControllerDisconnect$Params } from '../fn/child/user-child-controller-disconnect';
import { userChildControllerFilter } from '../fn/child/user-child-controller-filter';
import { UserChildControllerFilter$Params } from '../fn/child/user-child-controller-filter';
import { userChildControllerFindById } from '../fn/child/user-child-controller-find-by-id';
import { UserChildControllerFindById$Params } from '../fn/child/user-child-controller-find-by-id';
import { userChildControllerFindByParentEmail } from '../fn/child/user-child-controller-find-by-parent-email';
import { UserChildControllerFindByParentEmail$Params } from '../fn/child/user-child-controller-find-by-parent-email';
import { userChildControllerFindByRegistrationNo } from '../fn/child/user-child-controller-find-by-registration-no';
import { UserChildControllerFindByRegistrationNo$Params } from '../fn/child/user-child-controller-find-by-registration-no';
import { userChildControllerSearch } from '../fn/child/user-child-controller-search';
import { UserChildControllerSearch$Params } from '../fn/child/user-child-controller-search';
import { userChildControllerUpdate } from '../fn/child/user-child-controller-update';
import { UserChildControllerUpdate$Params } from '../fn/child/user-child-controller-update';

@Injectable({ providedIn: 'root' })
export class ChildService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `parentChildControllerSearchByParent()` */
  static readonly ParentChildControllerSearchByParentPath = '/api/child/parent';

  /**
   * 보호자가 자신의 아동을 검색합니다.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentChildControllerSearchByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentChildControllerSearchByParent$Response(params: ParentChildControllerSearchByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchChildResponseDto>> {
    return parentChildControllerSearchByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 자신의 아동을 검색합니다.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentChildControllerSearchByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentChildControllerSearchByParent(params: ParentChildControllerSearchByParent$Params, context?: HttpContext): Observable<SearchChildResponseDto> {
    return this.parentChildControllerSearchByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchChildResponseDto>): SearchChildResponseDto => r.body)
    );
  }

  /** Path part for operation `parentChildControllerFindOneByParent()` */
  static readonly ParentChildControllerFindOneByParentPath = '/api/child/parent/my-child/{id}';

  /**
   * 보호자가 내 아동의 상세 정보를 조회합니다.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentChildControllerFindOneByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentChildControllerFindOneByParent$Response(params: ParentChildControllerFindOneByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildDto>> {
    return parentChildControllerFindOneByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 상세 정보를 조회합니다.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentChildControllerFindOneByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentChildControllerFindOneByParent(params: ParentChildControllerFindOneByParent$Params, context?: HttpContext): Observable<ChildDto> {
    return this.parentChildControllerFindOneByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildDto>): ChildDto => r.body)
    );
  }

  /** Path part for operation `parentChildControllerParentCreate()` */
  static readonly ParentChildControllerParentCreatePath = '/api/child/parent/guardian';

  /**
   * 보호자 아동 등록.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentChildControllerParentCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentChildControllerParentCreate$Response(params: ParentChildControllerParentCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return parentChildControllerParentCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 아동 등록.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentChildControllerParentCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentChildControllerParentCreate(params: ParentChildControllerParentCreate$Params, context?: HttpContext): Observable<boolean> {
    return this.parentChildControllerParentCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `parentChildControllerUpdateByParent()` */
  static readonly ParentChildControllerUpdateByParentPath = '/api/child/parent/{childId}';

  /**
   * 보호자가 내 아동의 정보를 수정합니다.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentChildControllerUpdateByParent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentChildControllerUpdateByParent$Response(params: ParentChildControllerUpdateByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return parentChildControllerUpdateByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 정보를 수정합니다.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentChildControllerUpdateByParent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentChildControllerUpdateByParent(params: ParentChildControllerUpdateByParent$Params, context?: HttpContext): Observable<boolean> {
    return this.parentChildControllerUpdateByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `parentChildControllerDisconnectByParent()` */
  static readonly ParentChildControllerDisconnectByParentPath = '/api/child/parent/disconnect/{childId}';

  /**
   * 보호자가 내 아동을 삭제합니다. (연결 해제).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentChildControllerDisconnectByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentChildControllerDisconnectByParent$Response(params: ParentChildControllerDisconnectByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return parentChildControllerDisconnectByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동을 삭제합니다. (연결 해제).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentChildControllerDisconnectByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentChildControllerDisconnectByParent(params: ParentChildControllerDisconnectByParent$Params, context?: HttpContext): Observable<boolean> {
    return this.parentChildControllerDisconnectByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userChildControllerFilter()` */
  static readonly UserChildControllerFilterPath = '/api/child/user/filter';

  /**
   * 아동 필터 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildControllerFilter()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildControllerFilter$Response(params: UserChildControllerFilter$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildSearchFilterItemsDto>> {
    return userChildControllerFilter(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 필터 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildControllerFilter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildControllerFilter(params: UserChildControllerFilter$Params, context?: HttpContext): Observable<ChildSearchFilterItemsDto> {
    return this.userChildControllerFilter$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildSearchFilterItemsDto>): ChildSearchFilterItemsDto => r.body)
    );
  }

  /** Path part for operation `userChildControllerSearch()` */
  static readonly UserChildControllerSearchPath = '/api/child/user/search';

  /**
   * 아동 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildControllerSearch$Response(params: UserChildControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return userChildControllerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildControllerSearch(params: UserChildControllerSearch$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.userChildControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `userChildControllerFindByRegistrationNo()` */
  static readonly UserChildControllerFindByRegistrationNoPath = '/api/child/user/registrationNo';

  /**
   * 아동 조회.
   *
   * 이름과 주민등록번호로 아동을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildControllerFindByRegistrationNo()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildControllerFindByRegistrationNo$Response(params: UserChildControllerFindByRegistrationNo$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildDto>> {
    return userChildControllerFindByRegistrationNo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 조회.
   *
   * 이름과 주민등록번호로 아동을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildControllerFindByRegistrationNo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildControllerFindByRegistrationNo(params: UserChildControllerFindByRegistrationNo$Params, context?: HttpContext): Observable<ChildDto> {
    return this.userChildControllerFindByRegistrationNo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildDto>): ChildDto => r.body)
    );
  }

  /** Path part for operation `userChildControllerFindByParentEmail()` */
  static readonly UserChildControllerFindByParentEmailPath = '/api/child/user/my-children';

  /**
   * 보호자 계정으로 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildControllerFindByParentEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildControllerFindByParentEmail$Response(params: UserChildControllerFindByParentEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChildDto>>> {
    return userChildControllerFindByParentEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 계정으로 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildControllerFindByParentEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildControllerFindByParentEmail(params: UserChildControllerFindByParentEmail$Params, context?: HttpContext): Observable<Array<ChildDto>> {
    return this.userChildControllerFindByParentEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChildDto>>): Array<ChildDto> => r.body)
    );
  }

  /** Path part for operation `userChildControllerFindById()` */
  static readonly UserChildControllerFindByIdPath = '/api/child/user/{id}';

  /**
   * 아동 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildControllerFindById$Response(params: UserChildControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildDto>> {
    return userChildControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildControllerFindById(params: UserChildControllerFindById$Params, context?: HttpContext): Observable<ChildDto> {
    return this.userChildControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildDto>): ChildDto => r.body)
    );
  }

  /** Path part for operation `userChildControllerUpdate()` */
  static readonly UserChildControllerUpdatePath = '/api/child/user/{id}';

  /**
   * 아동 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChildControllerUpdate$Response(params: UserChildControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userChildControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChildControllerUpdate(params: UserChildControllerUpdate$Params, context?: HttpContext): Observable<void> {
    return this.userChildControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userChildControllerCenterCreate()` */
  static readonly UserChildControllerCenterCreatePath = '/api/child/user/center/{centerId}';

  /**
   * 센터 아동 등록.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildControllerCenterCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChildControllerCenterCreate$Response(params: UserChildControllerCenterCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildDto>> {
    return userChildControllerCenterCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 아동 등록.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildControllerCenterCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChildControllerCenterCreate(params: UserChildControllerCenterCreate$Params, context?: HttpContext): Observable<ChildDto> {
    return this.userChildControllerCenterCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildDto>): ChildDto => r.body)
    );
  }

  /** Path part for operation `userChildControllerConnect()` */
  static readonly UserChildControllerConnectPath = '/api/child/user/connect/{id}';

  /**
   * 아동 연결.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildControllerConnect()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildControllerConnect$Response(params: UserChildControllerConnect$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userChildControllerConnect(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 연결.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildControllerConnect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildControllerConnect(params: UserChildControllerConnect$Params, context?: HttpContext): Observable<void> {
    return this.userChildControllerConnect$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userChildControllerDisconnect()` */
  static readonly UserChildControllerDisconnectPath = '/api/child/user/disconnect/{id}';

  /**
   * 아동 연결 해제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildControllerDisconnect()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildControllerDisconnect$Response(params: UserChildControllerDisconnect$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userChildControllerDisconnect(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 연결 해제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildControllerDisconnect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildControllerDisconnect(params: UserChildControllerDisconnect$Params, context?: HttpContext): Observable<void> {
    return this.userChildControllerDisconnect$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminChildControllerSearch()` */
  static readonly AdminChildControllerSearchPath = '/api/child/admin/search';

  /**
   * 아동 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminChildControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminChildControllerSearch$Response(params: AdminChildControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return adminChildControllerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminChildControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminChildControllerSearch(params: AdminChildControllerSearch$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.adminChildControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `adminChildControllerFindByRegistrationNo()` */
  static readonly AdminChildControllerFindByRegistrationNoPath = '/api/child/admin/registrationNo';

  /**
   * 아동 조회.
   *
   * 이름과 주민등록번호로 아동을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminChildControllerFindByRegistrationNo()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminChildControllerFindByRegistrationNo$Response(params: AdminChildControllerFindByRegistrationNo$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildDto>> {
    return adminChildControllerFindByRegistrationNo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 조회.
   *
   * 이름과 주민등록번호로 아동을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminChildControllerFindByRegistrationNo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminChildControllerFindByRegistrationNo(params: AdminChildControllerFindByRegistrationNo$Params, context?: HttpContext): Observable<ChildDto> {
    return this.adminChildControllerFindByRegistrationNo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildDto>): ChildDto => r.body)
    );
  }

  /** Path part for operation `adminChildControllerFindById()` */
  static readonly AdminChildControllerFindByIdPath = '/api/child/admin/{id}';

  /**
   * 아동 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminChildControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminChildControllerFindById$Response(params: AdminChildControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildDto>> {
    return adminChildControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminChildControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminChildControllerFindById(params: AdminChildControllerFindById$Params, context?: HttpContext): Observable<ChildDto> {
    return this.adminChildControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildDto>): ChildDto => r.body)
    );
  }

  /** Path part for operation `adminChildControllerDelete()` */
  static readonly AdminChildControllerDeletePath = '/api/child/admin/{id}';

  /**
   * 아동 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminChildControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminChildControllerDelete$Response(params: AdminChildControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminChildControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminChildControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminChildControllerDelete(params: AdminChildControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.adminChildControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminChildControllerUpdate()` */
  static readonly AdminChildControllerUpdatePath = '/api/child/admin/{id}';

  /**
   * 아동 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminChildControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminChildControllerUpdate$Response(params: AdminChildControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminChildControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminChildControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminChildControllerUpdate(params: AdminChildControllerUpdate$Params, context?: HttpContext): Observable<void> {
    return this.adminChildControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminChildControllerValidConflict()` */
  static readonly AdminChildControllerValidConflictPath = '/api/child/admin/regitrationNo/conflict';

  /**
   * 아동 중복 조회.
   *
   * 주민등록번호로 중복을 확인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminChildControllerValidConflict()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminChildControllerValidConflict$Response(params: AdminChildControllerValidConflict$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildDto>> {
    return adminChildControllerValidConflict(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 중복 조회.
   *
   * 주민등록번호로 중복을 확인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminChildControllerValidConflict$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminChildControllerValidConflict(params: AdminChildControllerValidConflict$Params, context?: HttpContext): Observable<ChildDto> {
    return this.adminChildControllerValidConflict$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildDto>): ChildDto => r.body)
    );
  }

}
