/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminCenterChildControllerDeleteMany } from '../fn/center-child/admin-center-child-controller-delete-many';
import { AdminCenterChildControllerDeleteMany$Params } from '../fn/center-child/admin-center-child-controller-delete-many';
import { adminCenterChildControllerFindAll } from '../fn/center-child/admin-center-child-controller-find-all';
import { AdminCenterChildControllerFindAll$Params } from '../fn/center-child/admin-center-child-controller-find-all';
import { adminCenterChildControllerFindById } from '../fn/center-child/admin-center-child-controller-find-by-id';
import { AdminCenterChildControllerFindById$Params } from '../fn/center-child/admin-center-child-controller-find-by-id';
import { adminCenterChildControllerSearch } from '../fn/center-child/admin-center-child-controller-search';
import { AdminCenterChildControllerSearch$Params } from '../fn/center-child/admin-center-child-controller-search';
import { CenterChildDto } from '../models/center-child-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { userCenterChildControllerDeleteMany } from '../fn/center-child/user-center-child-controller-delete-many';
import { UserCenterChildControllerDeleteMany$Params } from '../fn/center-child/user-center-child-controller-delete-many';
import { userCenterChildControllerFindAll } from '../fn/center-child/user-center-child-controller-find-all';
import { UserCenterChildControllerFindAll$Params } from '../fn/center-child/user-center-child-controller-find-all';
import { userCenterChildControllerFindById } from '../fn/center-child/user-center-child-controller-find-by-id';
import { UserCenterChildControllerFindById$Params } from '../fn/center-child/user-center-child-controller-find-by-id';
import { userCenterChildControllerSearch } from '../fn/center-child/user-center-child-controller-search';
import { UserCenterChildControllerSearch$Params } from '../fn/center-child/user-center-child-controller-search';

@Injectable({ providedIn: 'root' })
export class CenterChildService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userCenterChildControllerFindAll()` */
  static readonly UserCenterChildControllerFindAllPath = '/api/center-child/user/all';

  /**
   * 센터에 소속된 아동 목록 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCenterChildControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterChildControllerFindAll$Response(params: UserCenterChildControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CenterChildDto>>> {
    return userCenterChildControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터에 소속된 아동 목록 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCenterChildControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterChildControllerFindAll(params: UserCenterChildControllerFindAll$Params, context?: HttpContext): Observable<Array<CenterChildDto>> {
    return this.userCenterChildControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CenterChildDto>>): Array<CenterChildDto> => r.body)
    );
  }

  /** Path part for operation `userCenterChildControllerSearch()` */
  static readonly UserCenterChildControllerSearchPath = '/api/center-child/user/search';

  /**
   * 센터에 소속된 아동 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCenterChildControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterChildControllerSearch$Response(params: UserCenterChildControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return userCenterChildControllerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터에 소속된 아동 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCenterChildControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterChildControllerSearch(params: UserCenterChildControllerSearch$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.userCenterChildControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `userCenterChildControllerFindById()` */
  static readonly UserCenterChildControllerFindByIdPath = '/api/center-child/user/{id}';

  /**
   * 센터에 소속된 아동 상세 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCenterChildControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterChildControllerFindById$Response(params: UserCenterChildControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterChildDto>> {
    return userCenterChildControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터에 소속된 아동 상세 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCenterChildControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterChildControllerFindById(params: UserCenterChildControllerFindById$Params, context?: HttpContext): Observable<CenterChildDto> {
    return this.userCenterChildControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterChildDto>): CenterChildDto => r.body)
    );
  }

  /** Path part for operation `userCenterChildControllerDeleteMany()` */
  static readonly UserCenterChildControllerDeleteManyPath = '/api/center-child/user/{id}';

  /**
   * 센터에 소속된 아동 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCenterChildControllerDeleteMany()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterChildControllerDeleteMany$Response(params: UserCenterChildControllerDeleteMany$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return userCenterChildControllerDeleteMany(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터에 소속된 아동 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCenterChildControllerDeleteMany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterChildControllerDeleteMany(params: UserCenterChildControllerDeleteMany$Params, context?: HttpContext): Observable<boolean> {
    return this.userCenterChildControllerDeleteMany$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `adminCenterChildControllerFindAll()` */
  static readonly AdminCenterChildControllerFindAllPath = '/api/center-child/admin/all';

  /**
   * 센터에 소속된 아동 목록 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCenterChildControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterChildControllerFindAll$Response(params: AdminCenterChildControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CenterChildDto>>> {
    return adminCenterChildControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터에 소속된 아동 목록 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCenterChildControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterChildControllerFindAll(params: AdminCenterChildControllerFindAll$Params, context?: HttpContext): Observable<Array<CenterChildDto>> {
    return this.adminCenterChildControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CenterChildDto>>): Array<CenterChildDto> => r.body)
    );
  }

  /** Path part for operation `adminCenterChildControllerSearch()` */
  static readonly AdminCenterChildControllerSearchPath = '/api/center-child/admin/search';

  /**
   * 센터에 소속된 아동 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCenterChildControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterChildControllerSearch$Response(params: AdminCenterChildControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return adminCenterChildControllerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터에 소속된 아동 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCenterChildControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterChildControllerSearch(params: AdminCenterChildControllerSearch$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.adminCenterChildControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `adminCenterChildControllerFindById()` */
  static readonly AdminCenterChildControllerFindByIdPath = '/api/center-child/admin/{id}';

  /**
   * 센터에 소속된 아동 상세 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCenterChildControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterChildControllerFindById$Response(params: AdminCenterChildControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterChildDto>> {
    return adminCenterChildControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터에 소속된 아동 상세 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCenterChildControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterChildControllerFindById(params: AdminCenterChildControllerFindById$Params, context?: HttpContext): Observable<CenterChildDto> {
    return this.adminCenterChildControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterChildDto>): CenterChildDto => r.body)
    );
  }

  /** Path part for operation `adminCenterChildControllerDeleteMany()` */
  static readonly AdminCenterChildControllerDeleteManyPath = '/api/center-child/admin/{id}';

  /**
   * 센터에 소속된 아동 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCenterChildControllerDeleteMany()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterChildControllerDeleteMany$Response(params: AdminCenterChildControllerDeleteMany$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return adminCenterChildControllerDeleteMany(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터에 소속된 아동 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCenterChildControllerDeleteMany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterChildControllerDeleteMany(params: AdminCenterChildControllerDeleteMany$Params, context?: HttpContext): Observable<boolean> {
    return this.adminCenterChildControllerDeleteMany$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
