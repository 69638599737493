<label class="select-none">
  @if (label(); as label) {
    <app-label [required]="required()">
      {{ label }}
    </app-label>
  }
  <div
    [attr.data-size]="currentSize()"
    [attr.data-rounded]="currentRounded()"
    [attr.data-theme]="theme()"
    [attr.data-disabled]="disabled()"
    class="input-wrapper input-rounded input-padding"
  >
    <div
      [attr.data-theme]="theme()"
      [attr.data-color]="currentColor()"
      [attr.data-rounded]="currentRounded()"
      class="input-outline input-rounded"
    ></div>
    <ng-container *ngTemplateOutlet="prefixIconSection" />
    <input
      #inputRef
      (click)="inputClick.emit()"
      (focus)="inputFocus.emit()"
      (blur)="inputBlur.emit()"
      (keydown)="inputKeydown.emit($event)"
      (change)="handleChange()"
      class="w-full input-text-size theme-text"
      [attr.data-size]="currentSize()"
      [attr.data-theme]="theme()"
      [type]="type()"
      [attr.list]="list()"
      [placeholder]="placeholder()"
      [spellcheck]="spellcheck()"
      [autocomplete]="autocomplete()"
      [autofocus]="autofocus()"
      [readOnly]="readonly()"
      [disabled]="disabled()"
      [maxLength]="maxlength() || 524288"
      [value]="value() || inputValue() || ''"
      [inputMode]="inputMode()"
      [required]="required()"
    />
    <ng-container *ngTemplateOutlet="suffixIconSection" />
    <div class="min-h-6">
      <ng-container *ngTemplateOutlet="suffixSection" />
    </div>
  </div>
</label>

<ng-template #prefixIconSection>
  @if (prefixIcon(); as icon) {
    <div class="flex items-center mr-2">
      <app-icon
        [attr.data-theme]="theme()"
        [name]="icon"
        class="text-gray-400 theme-text"
        size="sm"
      />
    </div>
  }
</ng-template>

<ng-template #suffixIconSection>
  @if (icon() || suffixIcon()) {
    <app-icon
      [attr.data-theme]="theme()"
      [name]="icon() || suffixIcon() || ''"
      class="theme-text !size-5 min-w-5"
    />
  } @else {
    <ng-content name="[icons]" />
  }
</ng-template>

<ng-template #suffixSection>
  @if (suffix()) {
    <p
      [attr.data-theme]="theme()"
      class="text-sm text-gray-500 min-w-max theme-text"
    >
      {{ suffix() }}
    </p>
  }
</ng-template>
