/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  booleanAttribute,
  inject,
  input,
  model,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Button } from '../../button/button.component';
import { Color } from '../../common/types';
import { Label } from '../../label/label.component';
import { Input } from '../input.component';

declare const daum: any;

interface AddressData {
  address: string;
  addressEnglish: string;
  addressType: string;
  apartment: string;
  autoJibunAddress: string;
  autoJibunAddressEnglish: string;
  autoRoadAddress: string;
  autoRoadAddressEnglish: string;
  bcode: string;
  bname: string;
  bname1: string;
  bname1English: string;
  bname2: string;
  bname2English: string;
  bnameEnglish: string;
  buildingCode: string;
  buildingName: string;
  hname: string;
  jibunAddress: string;
  jibunAddressEnglish: string;
  noSelected: string;
  postcode: string;
  postcode1: string;
  postcode2: string;
  postcodeSeq: string;
  query: string;
  roadAddress: string;
  roadAddressEnglish: string;
  roadname: string;
  roadnameCode: string;
  roadnameEnglish: string;
  sido: string;
  sidoEnglish: string;
  sigungu: string;
  sigunguCode: string;
  sigunguEnglish: string;
  userLanguageType: string;
  userSelectedType: string;
  zonecode: string;
}

export type AddressResult = {
  address: string;
  addressDetail?: string;
};

@Component({
  selector: 'app-input-address',
  standalone: true,
  imports: [CommonModule, Label, FormsModule, Button, Input],
  templateUrl: './input-address.component.html',
  styleUrls: ['./input-address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputAddress,
      multi: true,
    },
  ],
})
export class InputAddress implements ControlValueAccessor, OnInit {
  readonly changeRef = inject(ChangeDetectorRef);

  label = input<string>('');
  placeholder = input<string>('');
  required = input<boolean, string>(false, { transform: booleanAttribute });
  disabled = input<boolean>(false);
  readonly = input<boolean>(false);
  color = input<Color>('primary');

  value = model<AddressResult | null>(null);

  address = model<string>('');
  address$ = toObservable(this.address);

  addressDetail = model<string>('');
  addressDetail$ = toObservable(this.addressDetail);

  ngOnInit(): void {
    // timeout 안걸면 value가 안들어옴
    setTimeout(() => {
      if (this.value()?.address || this.value()?.addressDetail) {
        this.address.set(this.value()?.address || '');
        this.addressDetail.set(this.value()?.addressDetail || '');
      }
    }, 500);

    this.address$.subscribe({
      next: () => this.handleValueChange(),
    });

    this.addressDetail$.subscribe({
      next: () => this.handleValueChange(),
    });
  }

  handleValueChange() {
    if (this.address() || this.addressDetail()) {
      this.writeValue({
        address: this.address(),
        addressDetail: this.addressDetail(),
      });
      this.onChange(this.value()!);
    } else {
      this.writeValue(null);
      this.onChange(null);
    }
  }

  handleAddress(ev: Event) {
    ev.stopPropagation();
    ev.preventDefault();

    new daum.Postcode({
      oncomplete: (data: AddressData) => {
        this.address.set(data.address ? data.address : data.roadAddress);
      },
    }).open();
  }

  writeValue(value: AddressResult | null) {
    this.value.set(value);
    this.changeRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  onChange = (value: AddressResult | null) => {};
  onTouched = () => {};
}
