import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  inject,
  OnInit,
  output,
  signal,
} from '@angular/core';
import dayjs from 'dayjs';
import { BaseConfig } from 'libs/front-share/src/components/common/config/config.adapter';
import { IconButton, Menu } from 'libs/front-share/src/components/components';
import { CalendarStore } from '../../../calendar.store';

@Component({
  selector: 'app-year-select',
  styleUrls: ['./year-select.component.scss'],
  standalone: true,
  imports: [CommonModule, IconButton, Menu],
  template: `
    <app-menu>
      <div class="flex w-full justify-between px-4 py-2">
        <p
          [attr.data-theme]="theme()"
          class="font-semibold theme-text text-base md:text-lg"
        >
          {{ firstYear() }}년 - {{ lastYear() }}년
        </p>
        <div class="flex gap-3 items-center">
          <app-icon-button
            (click)="handleNavigate('prev', 'year', 10)"
            icon="ic:baseline-chevron-left"
          />
          <app-icon-button
            (click)="handleNavigate('next', 'year', 10)"
            icon="ic:baseline-chevron-right"
          />
        </div>
      </div>
      <div class="grid grid-cols-4 px-4 py-2 gap-4">
        @for (year of yearItems(); track year) {
          <div
            (click)="handleSelect(year)"
            [attr.data-active]="active(year)"
            [attr.data-theme]="theme()"
            class="year-item theme-hover theme-text"
          >
            {{ year }}년
          </div>
        }
      </div>
    </app-menu>
  `,
})
export class CalendarYearSelect extends BaseConfig implements OnInit {
  readonly store = inject(CalendarStore);

  select = output<Date>();

  date = signal<Date | null>(new Date());
  months = this.store.months;

  yearItems = computed(() => {
    const value = this.date();

    if (!value) return [];

    const base = dayjs(value).format('YYYY').substring(0, 3);

    const start = dayjs(`${base}0`).year();
    const end = dayjs(`${base}9`).year();

    const result = [];

    for (let i = start; i <= end; i++) {
      result.push(i);
    }

    return result;
  });

  firstYear = computed(() => this.yearItems()[0]);
  lastYear = computed(() => this.yearItems()[this.yearItems().length - 1]);

  ngOnInit(): void {
    this.date.set(this.store.value() || new Date());
  }

  active(year: number) {
    return dayjs(this.date()).get('year') === year;
  }

  /**
   * @description 년도와 달을 이동하는 통합 함수
   * @param type
   * @param unit
   * @param number
   */
  handleNavigate(
    type: 'prev' | 'next',
    unit: 'year' | 'month',
    number: number,
  ) {
    if (type === 'prev') {
      const value = dayjs(this.date()).subtract(number, unit).toDate();
      this.date.set(value);
    } else {
      const value = dayjs(this.date()).add(number, unit).toDate();
      this.date.set(value);
    }
  }

  /**
   * @description 년도와 달을 선택했을 경우 이벤트 핸들링
   * @param month
   */
  handleSelect(year: number) {
    const date = dayjs(this.date()).set('year', year).toDate();
    this.select.emit(date);
  }
}
