export const CenterServiceType = {
  LANGUAGE: '언어',
  ART: '미술',
  PSYCHOLOGY: '심리',
} as const;

export const CenterUserStatus = {
  PENDING: '승인 대기',
  ACCEPTED: '승인됨',
  REJECTED: '반려됨',
  CANCELED: '가입 취소',
  LEFT: '탈퇴',
} as const;

export const TherapyArea = {
  LANGUAGE: '언어',
  HEARING: '청능',
  ART_MUSIC: '미술,음악',
  BEHAVIOR_PLAY_PSYCHOLOGY: '행동,놀이,심리',
  SENSORY_MOVEMENT: '감각,운동',
} as const;

export const BusinessType = {
  FREELANCER: '프리랜서',
  BUSINESS: '사업자',
} as const;
