/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { centerRoleControllerCreateRole } from '../fn/center-role/center-role-controller-create-role';
import { CenterRoleControllerCreateRole$Params } from '../fn/center-role/center-role-controller-create-role';
import { centerRoleControllerDeleteRole } from '../fn/center-role/center-role-controller-delete-role';
import { CenterRoleControllerDeleteRole$Params } from '../fn/center-role/center-role-controller-delete-role';
import { centerRoleControllerFindRole } from '../fn/center-role/center-role-controller-find-role';
import { CenterRoleControllerFindRole$Params } from '../fn/center-role/center-role-controller-find-role';
import { centerRoleControllerFindRoles } from '../fn/center-role/center-role-controller-find-roles';
import { CenterRoleControllerFindRoles$Params } from '../fn/center-role/center-role-controller-find-roles';
import { centerRoleControllerUpdateRole } from '../fn/center-role/center-role-controller-update-role';
import { CenterRoleControllerUpdateRole$Params } from '../fn/center-role/center-role-controller-update-role';
import { CenterRoleDto } from '../models/center-role-dto';

@Injectable({ providedIn: 'root' })
export class CenterRoleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `centerRoleControllerFindRoles()` */
  static readonly CenterRoleControllerFindRolesPath = '/api/center-role/{id}/roles';

  /**
   * 센터 ID 기반 역할 조회.
   *
   * 센터 ID를 기반으로 모든 역할을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerRoleControllerFindRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerRoleControllerFindRoles$Response(params: CenterRoleControllerFindRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CenterRoleDto>>> {
    return centerRoleControllerFindRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 ID 기반 역할 조회.
   *
   * 센터 ID를 기반으로 모든 역할을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerRoleControllerFindRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerRoleControllerFindRoles(params: CenterRoleControllerFindRoles$Params, context?: HttpContext): Observable<Array<CenterRoleDto>> {
    return this.centerRoleControllerFindRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CenterRoleDto>>): Array<CenterRoleDto> => r.body)
    );
  }

  /** Path part for operation `centerRoleControllerCreateRole()` */
  static readonly CenterRoleControllerCreateRolePath = '/api/center-role/{id}/roles';

  /**
   * 센터 내 역할 생성.
   *
   * 센터 ID를 기반으로 역할을 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerRoleControllerCreateRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerRoleControllerCreateRole$Response(params: CenterRoleControllerCreateRole$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterRoleDto>> {
    return centerRoleControllerCreateRole(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 내 역할 생성.
   *
   * 센터 ID를 기반으로 역할을 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerRoleControllerCreateRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerRoleControllerCreateRole(params: CenterRoleControllerCreateRole$Params, context?: HttpContext): Observable<CenterRoleDto> {
    return this.centerRoleControllerCreateRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterRoleDto>): CenterRoleDto => r.body)
    );
  }

  /** Path part for operation `centerRoleControllerFindRole()` */
  static readonly CenterRoleControllerFindRolePath = '/api/center-role/{id}/roles/{roleId}';

  /**
   * 센터 역할 ID 기반 조회.
   *
   * 센터의 역할을 ID 기반으로 조회
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerRoleControllerFindRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerRoleControllerFindRole$Response(params: CenterRoleControllerFindRole$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterRoleDto>> {
    return centerRoleControllerFindRole(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 역할 ID 기반 조회.
   *
   * 센터의 역할을 ID 기반으로 조회
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerRoleControllerFindRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerRoleControllerFindRole(params: CenterRoleControllerFindRole$Params, context?: HttpContext): Observable<CenterRoleDto> {
    return this.centerRoleControllerFindRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterRoleDto>): CenterRoleDto => r.body)
    );
  }

  /** Path part for operation `centerRoleControllerDeleteRole()` */
  static readonly CenterRoleControllerDeleteRolePath = '/api/center-role/{id}/roles/{roleId}';

  /**
   * 센터 역할 ID를 기반으로 역할을 삭제합니다.
   *
   * 센터 역할 삭제
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerRoleControllerDeleteRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerRoleControllerDeleteRole$Response(params: CenterRoleControllerDeleteRole$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterRoleDto>> {
    return centerRoleControllerDeleteRole(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 역할 ID를 기반으로 역할을 삭제합니다.
   *
   * 센터 역할 삭제
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerRoleControllerDeleteRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerRoleControllerDeleteRole(params: CenterRoleControllerDeleteRole$Params, context?: HttpContext): Observable<CenterRoleDto> {
    return this.centerRoleControllerDeleteRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterRoleDto>): CenterRoleDto => r.body)
    );
  }

  /** Path part for operation `centerRoleControllerUpdateRole()` */
  static readonly CenterRoleControllerUpdateRolePath = '/api/center-role/{id}/roles/{roleId}';

  /**
   * 센터 역할 수정.
   *
   * 센터 역할 ID를 기반으로 역할을 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerRoleControllerUpdateRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerRoleControllerUpdateRole$Response(params: CenterRoleControllerUpdateRole$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterRoleDto>> {
    return centerRoleControllerUpdateRole(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 역할 수정.
   *
   * 센터 역할 ID를 기반으로 역할을 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerRoleControllerUpdateRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerRoleControllerUpdateRole(params: CenterRoleControllerUpdateRole$Params, context?: HttpContext): Observable<CenterRoleDto> {
    return this.centerRoleControllerUpdateRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterRoleDto>): CenterRoleDto => r.body)
    );
  }

}
