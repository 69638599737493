/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { allScheduleControllerFind } from '../fn/all-schedule/all-schedule-controller-find';
import { AllScheduleControllerFind$Params } from '../fn/all-schedule/all-schedule-controller-find';
import { allScheduleControllerGetFilterItems } from '../fn/all-schedule/all-schedule-controller-get-filter-items';
import { AllScheduleControllerGetFilterItems$Params } from '../fn/all-schedule/all-schedule-controller-get-filter-items';
import { allScheduleControllerSearchCursor } from '../fn/all-schedule/all-schedule-controller-search-cursor';
import { AllScheduleControllerSearchCursor$Params } from '../fn/all-schedule/all-schedule-controller-search-cursor';
import { AllScheduleCursorPaginationDto } from '../models/all-schedule-cursor-pagination-dto';
import { AllScheduleDto } from '../models/all-schedule-dto';
import { ScheduleFilterItemsDto } from '../models/schedule-filter-items-dto';

@Injectable({ providedIn: 'root' })
export class AllScheduleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `allScheduleControllerFind()` */
  static readonly AllScheduleControllerFindPath = '/api/all-schedule';

  /**
   * 일정 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allScheduleControllerFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  allScheduleControllerFind$Response(params?: AllScheduleControllerFind$Params, context?: HttpContext): Observable<StrictHttpResponse<AllScheduleDto>> {
    return allScheduleControllerFind(this.http, this.rootUrl, params, context);
  }

  /**
   * 일정 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allScheduleControllerFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allScheduleControllerFind(params?: AllScheduleControllerFind$Params, context?: HttpContext): Observable<AllScheduleDto> {
    return this.allScheduleControllerFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<AllScheduleDto>): AllScheduleDto => r.body)
    );
  }

  /** Path part for operation `allScheduleControllerSearchCursor()` */
  static readonly AllScheduleControllerSearchCursorPath = '/api/all-schedule/cursor';

  /**
   * 일정 검색 (Cursor).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allScheduleControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  allScheduleControllerSearchCursor$Response(params: AllScheduleControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<AllScheduleCursorPaginationDto>> {
    return allScheduleControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 일정 검색 (Cursor).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allScheduleControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allScheduleControllerSearchCursor(params: AllScheduleControllerSearchCursor$Params, context?: HttpContext): Observable<AllScheduleCursorPaginationDto> {
    return this.allScheduleControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<AllScheduleCursorPaginationDto>): AllScheduleCursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `allScheduleControllerGetFilterItems()` */
  static readonly AllScheduleControllerGetFilterItemsPath = '/api/all-schedule/filter';

  /**
   * 일정 필터 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allScheduleControllerGetFilterItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  allScheduleControllerGetFilterItems$Response(params: AllScheduleControllerGetFilterItems$Params, context?: HttpContext): Observable<StrictHttpResponse<ScheduleFilterItemsDto>> {
    return allScheduleControllerGetFilterItems(this.http, this.rootUrl, params, context);
  }

  /**
   * 일정 필터 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allScheduleControllerGetFilterItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allScheduleControllerGetFilterItems(params: AllScheduleControllerGetFilterItems$Params, context?: HttpContext): Observable<ScheduleFilterItemsDto> {
    return this.allScheduleControllerGetFilterItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScheduleFilterItemsDto>): ScheduleFilterItemsDto => r.body)
    );
  }

}
