import { HttpClient } from '@angular/common/http';
import {
  booleanAttribute,
  Component,
  computed,
  inject,
  input,
  model,
  output,
  signal,
} from '@angular/core';
import { ControlValueAccessor, Validators } from '@angular/forms';

export type FileItem = {
  url: string;
  filename: string;
  status: 'success' | 'fail';
};

export type UploadedFile = {
  url: string;
  filename: string;
};

export const FileAccept = {
  IMAGE: 'image/*',
  VIDEO: 'video/*',
  AUDIO: 'audio/*',
  ALL: '*/*',
} as const;

@Component({
  selector: 'app-file-uploader-adapter',
  template: '',
})
export class FileUploaderAdapter implements ControlValueAccessor, Validators {
  readonly httpClient = inject(HttpClient);

  error = output<string>();
  change = output<string[]>();

  placeholder = input<string>();
  label = input<string>();
  required = input<boolean, string>(false, { transform: booleanAttribute });
  maxlength = input<number>(1);
  minlength = input<number>(1);
  direction = input<'row' | 'column'>('column');
  hint = input<string>();
  uploadHandler = input<(file: File) => Promise<UploadedFile>>();
  deleteHandler = input<(url: string) => Promise<void>>();
  accept = input<string>();

  value = model<string[]>([]);

  type = computed(() => (this.accept()?.includes('image') ? 'image' : 'file'));

  disabled = signal(false);

  items = signal<FileItem[]>([]);

  isLoading = signal(false);

  onChange = (value: string[]) => {};
  onTouched = () => {};

  constructor() {}

  validate() {
    if (this.required() && this.items().length === 0) {
      return {
        required: true,
      };
    }
    if (this.items().some((item) => item.status === 'fail'))
      return {
        invalid: true,
      };
    return null;
  }

  openFileSelect() {
    const input = document.getElementById('app-file-uploader');

    if (input) {
      input.addEventListener('change', (e) => {
        const files = (e.target as HTMLInputElement).files;
        if (this.items().length + files!.length > this.maxlength()) {
          this.error.emit(
            `최대 업로드 개수(${this.maxlength()}개)를 초과하였습니다.`,
          );
          return;
        }
        if (files) this.onFileSelect(files);
      });
      input.click();
    }
  }

  onFileDrop(ev: DragEvent) {
    ev.preventDefault();
    const files = ev.dataTransfer?.files;
    if (files) this.onFileSelect(files);
  }

  async onFileSelect(files: FileList) {
    const fileList = Array.from(files);
    if (this.items().length + fileList.length > this.maxlength()) {
      this.error.emit(
        `최대 업로드 개수(${this.maxlength()}개)를 초과하였습니다.`,
      );
      return;
    }

    for (const file of fileList) {
      if (this.items().length === this.maxlength()) {
        this.error.emit(
          `최대 업로드 개수(${this.maxlength()}개)를 초과하였습니다.`,
        );
        return;
      }

      const result = await this.uploadFile(file);

      this.items.update((items) => {
        items.push({
          ...result,
          status: 'success',
        });
        return items;
      });
    }

    this.writeValue(this.items().map((item) => item.url));
    this.onChange(this.value());
  }

  /** 파일을 업로드 합니다. */
  async uploadFile(file: File): Promise<UploadedFile> {
    this.isLoading.set(true);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<UploadedFile>(async (resolve, reject) => {
      try {
        const res = await this.uploadHandler()!(file);
        resolve(res);
        this.isLoading.set(false);
      } catch (error: any) {
        this.error.emit(error.message);
        reject(error.message);
        this.isLoading.set(false);
      }
    });
  }

  /** 파일을 삭제합니다. */
  async deleteFile(url: string): Promise<void> {
    this.isLoading.set(true);
    try {
      await this.deleteHandler()!(url);
      this.items.update((items) => {
        const index = items.findIndex((item) => item.url === url);
        if (index !== -1) items.splice(index, 1);
        return items;
      });
      this.writeValue(this.items().map((item) => item.url));
      this.onChange(this.value());
      this.isLoading.set(false);
    } catch (error: any) {
      this.items.update((items) => {
        const index = items.findIndex((item) => item.url === url);
        if (index !== -1) items.splice(index, 1);
        return items;
      });
      this.writeValue(this.items().map((item) => item.url));
      this.onChange(this.value());
      this.isLoading.set(false);
    }
  }

  getFilenameFromUrl(url: string): string {
    if (!url) return '';
    if (!url.includes('/')) return url;
    return url.split('/').pop() ?? '';
  }

  writeValue(value?: string[]): void {
    this.value.set(value || []);
    this.setItems(this.value());
    this.change.emit(this.value());
  }

  setItems(value: string[]) {
    this.items.set(
      value.map((url) => ({
        url,
        filename: this.getFilenameFromUrl(url),
        status: 'success',
      })),
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }
}
