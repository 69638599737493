/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { UserService } from './services/user.service';
import { AdminService } from './services/admin.service';
import { OAuthService } from './services/o-auth.service';
import { GoogleService } from './services/google.service';
import { KakaoService } from './services/kakao.service';
import { NaverService } from './services/naver.service';
import { AppleService } from './services/apple.service';
import { StorageService } from './services/storage.service';
import { SettingService } from './services/setting.service';
import { InfoService } from './services/info.service';
import { VoucherService } from './services/voucher.service';
import { EnrollmentService } from './services/enrollment.service';
import { TicketService } from './services/ticket.service';
import { TicketTypeService } from './services/ticket-type.service';
import { UserActionLogService } from './services/user-action-log.service';
import { GuardianService } from './services/guardian.service';
import { CenterService } from './services/center.service';
import { CenterUserService } from './services/center-user.service';
import { CenterRoleService } from './services/center-role.service';
import { CenterGroupService } from './services/center-group.service';
import { CenterInviteLogService } from './services/center-invite-log.service';
import { CenterJoinLogService } from './services/center-join-log.service';
import { InquiryService } from './services/inquiry.service';
import { FaqService } from './services/faq.service';
import { ParentProfileService } from './services/parent-profile.service';
import { ChildService } from './services/child.service';
import { ChildMemoService } from './services/child-memo.service';
import { CenterChildService } from './services/center-child.service';
import { NoticeService } from './services/notice.service';
import { NotificationService } from './services/notification.service';
import { AppVersionService } from './services/app-version.service';
import { MemoService } from './services/memo.service';
import { DevelopmentStageService } from './services/development-stage.service';
import { DiaryService } from './services/diary.service';
import { EnrollmentScheduleService } from './services/enrollment-schedule.service';
import { AllScheduleService } from './services/all-schedule.service';
import { ScheduleService } from './services/schedule.service';
import { KeywordService } from './services/keyword.service';
import { TagService } from './services/tag.service';
import { ContentService } from './services/content.service';
import { ContentCategoryService } from './services/content-category.service';
import { ContentLikeService } from './services/content-like.service';
import { UrlService } from './services/url.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    UserService,
    AdminService,
    OAuthService,
    GoogleService,
    KakaoService,
    NaverService,
    AppleService,
    StorageService,
    SettingService,
    InfoService,
    VoucherService,
    EnrollmentService,
    TicketService,
    TicketTypeService,
    UserActionLogService,
    GuardianService,
    CenterService,
    CenterUserService,
    CenterRoleService,
    CenterGroupService,
    CenterInviteLogService,
    CenterJoinLogService,
    InquiryService,
    FaqService,
    ParentProfileService,
    ChildService,
    ChildMemoService,
    CenterChildService,
    NoticeService,
    NotificationService,
    AppVersionService,
    MemoService,
    DevelopmentStageService,
    DiaryService,
    EnrollmentScheduleService,
    AllScheduleService,
    ScheduleService,
    KeywordService,
    TagService,
    ContentService,
    ContentCategoryService,
    ContentLikeService,
    UrlService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
