// 구독 Plan 상수

interface PlanItem {
  name: string;
  price: number;
  maxUsers: number;
}

export const Plan = {
  FREE: 'FREE',
  PLUS: 'PLUS',
  BUSINESS: 'BUSINESS',
  BUSINESS_PLUS: 'BUSINESS_PLUS',
} as const;

export const Plans: { [K in keyof typeof Plan]: PlanItem } = {
  FREE: {
    name: '무료',
    price: 0,
    maxUsers: 1,
  },
  PLUS: {
    name: '플러스',
    price: 10000,
    maxUsers: 1,
  },
  BUSINESS: {
    name: '비즈니스',
    price: 25000,
    maxUsers: 3,
  },
  BUSINESS_PLUS: {
    name: '비즈니스 플러스',
    price: 8000,
    maxUsers: 3,
  },
} as const;
