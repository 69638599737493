/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CenterUserDto } from '../../models/center-user-dto';
import { UpdateCenterUserDto } from '../../models/update-center-user-dto';

export interface CenterUserControllerUpdateUser$Params {

/**
 * 구성원 ID
 */
  userId: string;

/**
 * 센터 ID
 */
  centerId: any;
      body: UpdateCenterUserDto
}

export function centerUserControllerUpdateUser(http: HttpClient, rootUrl: string, params: CenterUserControllerUpdateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterUserDto>> {
  const rb = new RequestBuilder(rootUrl, centerUserControllerUpdateUser.PATH, 'patch');
  if (params) {
    rb.path('userId', params.userId, {});
    rb.path('centerId', params.centerId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CenterUserDto>;
    })
  );
}

centerUserControllerUpdateUser.PATH = '/api/center-user/{centerId}/users/{userId}';
