import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, input } from '@angular/core';
import { Button } from '../button/button.component';
import { Size } from '../components';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss'],
  standalone: true,
  imports: [CommonModule, Button],
})
export default class FileDownload {
  readonly http = inject(HttpClient);

  name = input<string>('');
  url = input<string>('');

  size = input<Size>('md');

  handleDownload() {
    if (!this.url()) return;

    this.downloadFile(this.url(), this.name());
  }

  async downloadFile(url: string, name: string) {
    const res = await fetch(url);
    const blob = await res.blob();
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = name;
    a.target = '_blank';
    a.click();
  }
}
