/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface UserChildMemoControllerDeleteMemo$Params {
  memoId: string;
  teacherUserId?: string;
  guardianUserId?: string;
}

export function userChildMemoControllerDeleteMemo(http: HttpClient, rootUrl: string, params: UserChildMemoControllerDeleteMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, userChildMemoControllerDeleteMemo.PATH, 'delete');
  if (params) {
    rb.path('memoId', params.memoId, {});
    rb.query('teacherUserId', params.teacherUserId, {});
    rb.query('guardianUserId', params.guardianUserId, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

userChildMemoControllerDeleteMemo.PATH = '/api/child-memo/user/{memoId}';
