/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SearchDiaryResponseDto } from '../../models/search-diary-response-dto';

export interface DiaryControllerSearchV1$Params {

/**
 * 페이지 크기
 */
  pageSize: number;

/**
 * 정렬 기준
 */
  orderBy?: string;

/**
 * 정렬 방향
 */
  align?: 'asc' | 'desc';

/**
 * 검색어
 */
  query?: string;

/**
 * 페이지 번호
 */
  pageNo: number;

/**
 * 아동 ID
 */
  childId?: string | null;

/**
 * 일지 타입
 */
  type?: 'GENERAL' | 'TREATMENT' | 'COMPLAINT';

/**
 * 시작 날짜
 */
  startDate?: string | null;

/**
 * 종료 날짜
 */
  endDate?: string | null;

/**
 * 쿼리
 */
  queries?: Array<string>;
}

export function diaryControllerSearchV1(http: HttpClient, rootUrl: string, params: DiaryControllerSearchV1$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchDiaryResponseDto>> {
  const rb = new RequestBuilder(rootUrl, diaryControllerSearchV1.PATH, 'get');
  if (params) {
    rb.query('pageSize', params.pageSize, {});
    rb.query('orderBy', params.orderBy, {});
    rb.query('align', params.align, {});
    rb.query('query', params.query, {});
    rb.query('pageNo', params.pageNo, {});
    rb.query('childId', params.childId, {});
    rb.query('type', params.type, {});
    rb.query('startDate', params.startDate, {});
    rb.query('endDate', params.endDate, {});
    rb.query('queries', params.queries, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SearchDiaryResponseDto>;
    })
  );
}

diaryControllerSearchV1.PATH = '/api/v1/diary/search';
