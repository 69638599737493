<section
  class="flex md:flex-row flex-col w-full justify-between items-center my-3 gap-3"
>
  <section
    class="relative flex w-full md:justify-start justify-between items-center gap-2"
  >
    <app-icon-button
      (click)="handleNavigate('prev', _mode()!, 1)"
      icon="ic:baseline-chevron-left"
    />
    <div class="flex gap-1 items-center">
      <app-icon-button (click)="yearOpen.set(true)"
        ><p class="font-semibold text-base md:text-lg">
          {{ value() | date: 'y년' }}
        </p></app-icon-button
      >
      <app-icon-button (click)="monthOpen.set(true)"
        ><p class="font-semibold text-base md:text-lg">
          {{ value() | date: 'MM월' }}
        </p></app-icon-button
      >
    </div>
    <app-icon-button
      (click)="handleNavigate('next', _mode()!, 1)"
      icon="ic:baseline-chevron-right"
    />
    @if (monthOpen()) {
      <app-month-select
        (clickOutside)="monthOpen.set(false)"
        (select)="handleSelectMonth($event)"
        class="absolute z-[99] left-0 top-[calc(100%+4px)]"
      />
    }
    @if (yearOpen()) {
      <app-year-select
        (clickOutside)="yearOpen.set(false)"
        (select)="handleSelectYear($event)"
        class="absolute z-[99] left-0 top-[calc(100%+4px)]"
      />
    }
  </section>
  <section
    class="flex flex-row gap-3 items-center min-w-max w-full md:w-auto justify-between md:justify-start"
  >
    <app-tabs
      [options]="modes"
      [value]="_mode() || 'month'"
      (valueChange)="handleModeChange($event)"
    />
    <ng-content />
  </section>
</section>
