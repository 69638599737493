/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ChildMemoDto } from '../models/child-memo-dto';
import { parentChildMemoControllerCreateMemoByParent } from '../fn/child-memo/parent-child-memo-controller-create-memo-by-parent';
import { ParentChildMemoControllerCreateMemoByParent$Params } from '../fn/child-memo/parent-child-memo-controller-create-memo-by-parent';
import { parentChildMemoControllerDeleteMemoByParent } from '../fn/child-memo/parent-child-memo-controller-delete-memo-by-parent';
import { ParentChildMemoControllerDeleteMemoByParent$Params } from '../fn/child-memo/parent-child-memo-controller-delete-memo-by-parent';
import { parentChildMemoControllerFindOneMemoByParent } from '../fn/child-memo/parent-child-memo-controller-find-one-memo-by-parent';
import { ParentChildMemoControllerFindOneMemoByParent$Params } from '../fn/child-memo/parent-child-memo-controller-find-one-memo-by-parent';
import { parentChildMemoControllerSearchMemoByParent } from '../fn/child-memo/parent-child-memo-controller-search-memo-by-parent';
import { ParentChildMemoControllerSearchMemoByParent$Params } from '../fn/child-memo/parent-child-memo-controller-search-memo-by-parent';
import { parentChildMemoControllerShareMemoByParent } from '../fn/child-memo/parent-child-memo-controller-share-memo-by-parent';
import { ParentChildMemoControllerShareMemoByParent$Params } from '../fn/child-memo/parent-child-memo-controller-share-memo-by-parent';
import { parentChildMemoControllerUpdateMemoByParent } from '../fn/child-memo/parent-child-memo-controller-update-memo-by-parent';
import { ParentChildMemoControllerUpdateMemoByParent$Params } from '../fn/child-memo/parent-child-memo-controller-update-memo-by-parent';
import { SearchChildMemoResponseDto } from '../models/search-child-memo-response-dto';
import { userChildMemoControllerAddSharedUser } from '../fn/child-memo/user-child-memo-controller-add-shared-user';
import { UserChildMemoControllerAddSharedUser$Params } from '../fn/child-memo/user-child-memo-controller-add-shared-user';
import { userChildMemoControllerCreateGuardianCounselingMemo } from '../fn/child-memo/user-child-memo-controller-create-guardian-counseling-memo';
import { UserChildMemoControllerCreateGuardianCounselingMemo$Params } from '../fn/child-memo/user-child-memo-controller-create-guardian-counseling-memo';
import { userChildMemoControllerCreateMemo } from '../fn/child-memo/user-child-memo-controller-create-memo';
import { UserChildMemoControllerCreateMemo$Params } from '../fn/child-memo/user-child-memo-controller-create-memo';
import { userChildMemoControllerDeleteMemo } from '../fn/child-memo/user-child-memo-controller-delete-memo';
import { UserChildMemoControllerDeleteMemo$Params } from '../fn/child-memo/user-child-memo-controller-delete-memo';
import { userChildMemoControllerFindGuardianCounselingMemo } from '../fn/child-memo/user-child-memo-controller-find-guardian-counseling-memo';
import { UserChildMemoControllerFindGuardianCounselingMemo$Params } from '../fn/child-memo/user-child-memo-controller-find-guardian-counseling-memo';
import { userChildMemoControllerFindMemoByCenter } from '../fn/child-memo/user-child-memo-controller-find-memo-by-center';
import { UserChildMemoControllerFindMemoByCenter$Params } from '../fn/child-memo/user-child-memo-controller-find-memo-by-center';
import { userChildMemoControllerUpdateGuardianCounselingMemo } from '../fn/child-memo/user-child-memo-controller-update-guardian-counseling-memo';
import { UserChildMemoControllerUpdateGuardianCounselingMemo$Params } from '../fn/child-memo/user-child-memo-controller-update-guardian-counseling-memo';
import { userChildMemoControllerUpdateMemo } from '../fn/child-memo/user-child-memo-controller-update-memo';
import { UserChildMemoControllerUpdateMemo$Params } from '../fn/child-memo/user-child-memo-controller-update-memo';

@Injectable({ providedIn: 'root' })
export class ChildMemoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userChildMemoControllerFindMemoByCenter()` */
  static readonly UserChildMemoControllerFindMemoByCenterPath = '/api/child-memo/user';

  /**
   * 아동 메모 전체 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildMemoControllerFindMemoByCenter()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildMemoControllerFindMemoByCenter$Response(params: UserChildMemoControllerFindMemoByCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChildMemoDto>>> {
    return userChildMemoControllerFindMemoByCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 메모 전체 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildMemoControllerFindMemoByCenter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildMemoControllerFindMemoByCenter(params: UserChildMemoControllerFindMemoByCenter$Params, context?: HttpContext): Observable<Array<ChildMemoDto>> {
    return this.userChildMemoControllerFindMemoByCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChildMemoDto>>): Array<ChildMemoDto> => r.body)
    );
  }

  /** Path part for operation `userChildMemoControllerCreateMemo()` */
  static readonly UserChildMemoControllerCreateMemoPath = '/api/child-memo/user';

  /**
   * 아동 메모 등록.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildMemoControllerCreateMemo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChildMemoControllerCreateMemo$Response(params: UserChildMemoControllerCreateMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildMemoDto>> {
    return userChildMemoControllerCreateMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 메모 등록.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildMemoControllerCreateMemo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChildMemoControllerCreateMemo(params: UserChildMemoControllerCreateMemo$Params, context?: HttpContext): Observable<ChildMemoDto> {
    return this.userChildMemoControllerCreateMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildMemoDto>): ChildMemoDto => r.body)
    );
  }

  /** Path part for operation `userChildMemoControllerFindGuardianCounselingMemo()` */
  static readonly UserChildMemoControllerFindGuardianCounselingMemoPath = '/api/child-memo/user/guardian-counseling';

  /**
   * 아동 메모 전체 조회 (보호자 상담).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildMemoControllerFindGuardianCounselingMemo()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildMemoControllerFindGuardianCounselingMemo$Response(params: UserChildMemoControllerFindGuardianCounselingMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChildMemoDto>>> {
    return userChildMemoControllerFindGuardianCounselingMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 메모 전체 조회 (보호자 상담).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildMemoControllerFindGuardianCounselingMemo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildMemoControllerFindGuardianCounselingMemo(params: UserChildMemoControllerFindGuardianCounselingMemo$Params, context?: HttpContext): Observable<Array<ChildMemoDto>> {
    return this.userChildMemoControllerFindGuardianCounselingMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChildMemoDto>>): Array<ChildMemoDto> => r.body)
    );
  }

  /** Path part for operation `userChildMemoControllerCreateGuardianCounselingMemo()` */
  static readonly UserChildMemoControllerCreateGuardianCounselingMemoPath = '/api/child-memo/user/guardian-counseling';

  /**
   * 아동 메모 등록 (보호자 상담).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildMemoControllerCreateGuardianCounselingMemo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChildMemoControllerCreateGuardianCounselingMemo$Response(params: UserChildMemoControllerCreateGuardianCounselingMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildMemoDto>> {
    return userChildMemoControllerCreateGuardianCounselingMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 메모 등록 (보호자 상담).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildMemoControllerCreateGuardianCounselingMemo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChildMemoControllerCreateGuardianCounselingMemo(params: UserChildMemoControllerCreateGuardianCounselingMemo$Params, context?: HttpContext): Observable<ChildMemoDto> {
    return this.userChildMemoControllerCreateGuardianCounselingMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildMemoDto>): ChildMemoDto => r.body)
    );
  }

  /** Path part for operation `userChildMemoControllerUpdateGuardianCounselingMemo()` */
  static readonly UserChildMemoControllerUpdateGuardianCounselingMemoPath = '/api/child-memo/user/guardian-counseling/{memoId}';

  /**
   * 아동 메모 수정 (보호자 상담).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildMemoControllerUpdateGuardianCounselingMemo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChildMemoControllerUpdateGuardianCounselingMemo$Response(params: UserChildMemoControllerUpdateGuardianCounselingMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildMemoDto>> {
    return userChildMemoControllerUpdateGuardianCounselingMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 메모 수정 (보호자 상담).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildMemoControllerUpdateGuardianCounselingMemo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChildMemoControllerUpdateGuardianCounselingMemo(params: UserChildMemoControllerUpdateGuardianCounselingMemo$Params, context?: HttpContext): Observable<ChildMemoDto> {
    return this.userChildMemoControllerUpdateGuardianCounselingMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildMemoDto>): ChildMemoDto => r.body)
    );
  }

  /** Path part for operation `userChildMemoControllerAddSharedUser()` */
  static readonly UserChildMemoControllerAddSharedUserPath = '/api/child-memo/user/shared/{memoId}';

  /**
   * 공유 여부 토글.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildMemoControllerAddSharedUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildMemoControllerAddSharedUser$Response(params: UserChildMemoControllerAddSharedUser$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildMemoDto>> {
    return userChildMemoControllerAddSharedUser(this.http, this.rootUrl, params, context);
  }

  /**
   * 공유 여부 토글.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildMemoControllerAddSharedUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildMemoControllerAddSharedUser(params: UserChildMemoControllerAddSharedUser$Params, context?: HttpContext): Observable<ChildMemoDto> {
    return this.userChildMemoControllerAddSharedUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildMemoDto>): ChildMemoDto => r.body)
    );
  }

  /** Path part for operation `userChildMemoControllerDeleteMemo()` */
  static readonly UserChildMemoControllerDeleteMemoPath = '/api/child-memo/user/{memoId}';

  /**
   * 아동 메모 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildMemoControllerDeleteMemo()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildMemoControllerDeleteMemo$Response(params: UserChildMemoControllerDeleteMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userChildMemoControllerDeleteMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 메모 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildMemoControllerDeleteMemo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userChildMemoControllerDeleteMemo(params: UserChildMemoControllerDeleteMemo$Params, context?: HttpContext): Observable<void> {
    return this.userChildMemoControllerDeleteMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userChildMemoControllerUpdateMemo()` */
  static readonly UserChildMemoControllerUpdateMemoPath = '/api/child-memo/user/{memoId}';

  /**
   * 아동 메모 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChildMemoControllerUpdateMemo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChildMemoControllerUpdateMemo$Response(params: UserChildMemoControllerUpdateMemo$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildMemoDto>> {
    return userChildMemoControllerUpdateMemo(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동 메모 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChildMemoControllerUpdateMemo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChildMemoControllerUpdateMemo(params: UserChildMemoControllerUpdateMemo$Params, context?: HttpContext): Observable<ChildMemoDto> {
    return this.userChildMemoControllerUpdateMemo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildMemoDto>): ChildMemoDto => r.body)
    );
  }

  /** Path part for operation `parentChildMemoControllerSearchMemoByParent()` */
  static readonly ParentChildMemoControllerSearchMemoByParentPath = '/api/child-memo/parent/search';

  /**
   * 보호자가 내 아동 1명의 배경정보 정보 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentChildMemoControllerSearchMemoByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentChildMemoControllerSearchMemoByParent$Response(params: ParentChildMemoControllerSearchMemoByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchChildMemoResponseDto>> {
    return parentChildMemoControllerSearchMemoByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동 1명의 배경정보 정보 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentChildMemoControllerSearchMemoByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentChildMemoControllerSearchMemoByParent(params: ParentChildMemoControllerSearchMemoByParent$Params, context?: HttpContext): Observable<SearchChildMemoResponseDto> {
    return this.parentChildMemoControllerSearchMemoByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchChildMemoResponseDto>): SearchChildMemoResponseDto => r.body)
    );
  }

  /** Path part for operation `parentChildMemoControllerFindOneMemoByParent()` */
  static readonly ParentChildMemoControllerFindOneMemoByParentPath = '/api/child-memo/parent/{id}';

  /**
   * 보호자가 내 아동 메모 상세 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentChildMemoControllerFindOneMemoByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentChildMemoControllerFindOneMemoByParent$Response(params: ParentChildMemoControllerFindOneMemoByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<ChildMemoDto>> {
    return parentChildMemoControllerFindOneMemoByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동 메모 상세 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentChildMemoControllerFindOneMemoByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentChildMemoControllerFindOneMemoByParent(params: ParentChildMemoControllerFindOneMemoByParent$Params, context?: HttpContext): Observable<ChildMemoDto> {
    return this.parentChildMemoControllerFindOneMemoByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChildMemoDto>): ChildMemoDto => r.body)
    );
  }

  /** Path part for operation `parentChildMemoControllerCreateMemoByParent()` */
  static readonly ParentChildMemoControllerCreateMemoByParentPath = '/api/child-memo/parent';

  /**
   * 보호자가 아동 메모 등록.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentChildMemoControllerCreateMemoByParent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentChildMemoControllerCreateMemoByParent$Response(params: ParentChildMemoControllerCreateMemoByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return parentChildMemoControllerCreateMemoByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 아동 메모 등록.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentChildMemoControllerCreateMemoByParent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentChildMemoControllerCreateMemoByParent(params: ParentChildMemoControllerCreateMemoByParent$Params, context?: HttpContext): Observable<boolean> {
    return this.parentChildMemoControllerCreateMemoByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `parentChildMemoControllerDeleteMemoByParent()` */
  static readonly ParentChildMemoControllerDeleteMemoByParentPath = '/api/child-memo/parent/{memoId}';

  /**
   * 보호자가 내 아동 메모 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentChildMemoControllerDeleteMemoByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentChildMemoControllerDeleteMemoByParent$Response(params: ParentChildMemoControllerDeleteMemoByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return parentChildMemoControllerDeleteMemoByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동 메모 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentChildMemoControllerDeleteMemoByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentChildMemoControllerDeleteMemoByParent(params: ParentChildMemoControllerDeleteMemoByParent$Params, context?: HttpContext): Observable<boolean> {
    return this.parentChildMemoControllerDeleteMemoByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `parentChildMemoControllerUpdateMemoByParent()` */
  static readonly ParentChildMemoControllerUpdateMemoByParentPath = '/api/child-memo/parent/{memoId}';

  /**
   * 보호자가 내 아동 메모 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentChildMemoControllerUpdateMemoByParent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentChildMemoControllerUpdateMemoByParent$Response(params: ParentChildMemoControllerUpdateMemoByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return parentChildMemoControllerUpdateMemoByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동 메모 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentChildMemoControllerUpdateMemoByParent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentChildMemoControllerUpdateMemoByParent(params: ParentChildMemoControllerUpdateMemoByParent$Params, context?: HttpContext): Observable<boolean> {
    return this.parentChildMemoControllerUpdateMemoByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `parentChildMemoControllerShareMemoByParent()` */
  static readonly ParentChildMemoControllerShareMemoByParentPath = '/api/child-memo/parent/share';

  /**
   * 보호자가 내 아동 메모를 센터와 공유.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentChildMemoControllerShareMemoByParent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentChildMemoControllerShareMemoByParent$Response(params: ParentChildMemoControllerShareMemoByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return parentChildMemoControllerShareMemoByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동 메모를 센터와 공유.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentChildMemoControllerShareMemoByParent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentChildMemoControllerShareMemoByParent(params: ParentChildMemoControllerShareMemoByParent$Params, context?: HttpContext): Observable<boolean> {
    return this.parentChildMemoControllerShareMemoByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
