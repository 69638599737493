/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { SearchTicketResponseDto } from '../models/search-ticket-response-dto';
import { ticketControllerCreate } from '../fn/ticket/ticket-controller-create';
import { TicketControllerCreate$Params } from '../fn/ticket/ticket-controller-create';
import { ticketControllerDelete } from '../fn/ticket/ticket-controller-delete';
import { TicketControllerDelete$Params } from '../fn/ticket/ticket-controller-delete';
import { ticketControllerEdit } from '../fn/ticket/ticket-controller-edit';
import { TicketControllerEdit$Params } from '../fn/ticket/ticket-controller-edit';
import { ticketControllerFindAll } from '../fn/ticket/ticket-controller-find-all';
import { TicketControllerFindAll$Params } from '../fn/ticket/ticket-controller-find-all';
import { ticketControllerFindById } from '../fn/ticket/ticket-controller-find-by-id';
import { TicketControllerFindById$Params } from '../fn/ticket/ticket-controller-find-by-id';
import { ticketControllerFindOneByParent } from '../fn/ticket/ticket-controller-find-one-by-parent';
import { TicketControllerFindOneByParent$Params } from '../fn/ticket/ticket-controller-find-one-by-parent';
import { ticketControllerMultipleDelete } from '../fn/ticket/ticket-controller-multiple-delete';
import { TicketControllerMultipleDelete$Params } from '../fn/ticket/ticket-controller-multiple-delete';
import { ticketControllerSearchByParent } from '../fn/ticket/ticket-controller-search-by-parent';
import { TicketControllerSearchByParent$Params } from '../fn/ticket/ticket-controller-search-by-parent';
import { ticketControllerSearchCursor } from '../fn/ticket/ticket-controller-search-cursor';
import { TicketControllerSearchCursor$Params } from '../fn/ticket/ticket-controller-search-cursor';
import { ticketControllerSearchOffset } from '../fn/ticket/ticket-controller-search-offset';
import { TicketControllerSearchOffset$Params } from '../fn/ticket/ticket-controller-search-offset';
import { ticketControllerUpdate } from '../fn/ticket/ticket-controller-update';
import { TicketControllerUpdate$Params } from '../fn/ticket/ticket-controller-update';
import { TicketDto } from '../models/ticket-dto';
import { ticketTemplateControllerCreate } from '../fn/ticket/ticket-template-controller-create';
import { TicketTemplateControllerCreate$Params } from '../fn/ticket/ticket-template-controller-create';
import { ticketTemplateControllerDelete } from '../fn/ticket/ticket-template-controller-delete';
import { TicketTemplateControllerDelete$Params } from '../fn/ticket/ticket-template-controller-delete';
import { ticketTemplateControllerSearch } from '../fn/ticket/ticket-template-controller-search';
import { TicketTemplateControllerSearch$Params } from '../fn/ticket/ticket-template-controller-search';
import { ticketTemplateControllerUpdate } from '../fn/ticket/ticket-template-controller-update';
import { TicketTemplateControllerUpdate$Params } from '../fn/ticket/ticket-template-controller-update';
import { TicketTemplateDto } from '../models/ticket-template-dto';

@Injectable({ providedIn: 'root' })
export class TicketService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `ticketControllerFindAll()` */
  static readonly TicketControllerFindAllPath = '/api/ticket';

  /**
   * 발달서비스 전체 조회.
   *
   * 발달서비스 전체를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerFindAll$Response(params?: TicketControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketDto>>> {
    return ticketControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 전체 조회.
   *
   * 발달서비스 전체를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerFindAll(params?: TicketControllerFindAll$Params, context?: HttpContext): Observable<Array<TicketDto>> {
    return this.ticketControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketDto>>): Array<TicketDto> => r.body)
    );
  }

  /** Path part for operation `ticketControllerCreate()` */
  static readonly TicketControllerCreatePath = '/api/ticket';

  /**
   * 발달서비스 생성.
   *
   * 발달서비스을(를) 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketControllerCreate$Response(params: TicketControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketDto>> {
    return ticketControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 생성.
   *
   * 발달서비스을(를) 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketControllerCreate(params: TicketControllerCreate$Params, context?: HttpContext): Observable<TicketDto> {
    return this.ticketControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketDto>): TicketDto => r.body)
    );
  }

  /** Path part for operation `ticketControllerSearchOffset()` */
  static readonly TicketControllerSearchOffsetPath = '/api/ticket/search/offset';

  /**
   * 발달서비스 오프셋 기반 조회.
   *
   * 발달서비스을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerSearchOffset$Response(params: TicketControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return ticketControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 오프셋 기반 조회.
   *
   * 발달서비스을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerSearchOffset(params: TicketControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.ticketControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `ticketControllerSearchByParent()` */
  static readonly TicketControllerSearchByParentPath = '/api/ticket/search-by-parent';

  /**
   * 보호자가 내 아동의 발달서비스 검색 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketControllerSearchByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerSearchByParent$Response(params: TicketControllerSearchByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchTicketResponseDto>> {
    return ticketControllerSearchByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 발달서비스 검색 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketControllerSearchByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerSearchByParent(params: TicketControllerSearchByParent$Params, context?: HttpContext): Observable<SearchTicketResponseDto> {
    return this.ticketControllerSearchByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchTicketResponseDto>): SearchTicketResponseDto => r.body)
    );
  }

  /** Path part for operation `ticketControllerSearchCursor()` */
  static readonly TicketControllerSearchCursorPath = '/api/ticket/search/cursor';

  /**
   * 발달서비스 커서 기반 조회.
   *
   * 발달서비스을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerSearchCursor$Response(params: TicketControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return ticketControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 커서 기반 조회.
   *
   * 발달서비스을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerSearchCursor(params: TicketControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.ticketControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `ticketControllerFindOneByParent()` */
  static readonly TicketControllerFindOneByParentPath = '/api/ticket/{id}/by-parent';

  /**
   * 부모가 내 아동의 발달 서비스 상세 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketControllerFindOneByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerFindOneByParent$Response(params: TicketControllerFindOneByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketDto>> {
    return ticketControllerFindOneByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 부모가 내 아동의 발달 서비스 상세 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketControllerFindOneByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerFindOneByParent(params: TicketControllerFindOneByParent$Params, context?: HttpContext): Observable<TicketDto> {
    return this.ticketControllerFindOneByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketDto>): TicketDto => r.body)
    );
  }

  /** Path part for operation `ticketControllerFindById()` */
  static readonly TicketControllerFindByIdPath = '/api/ticket/{id}';

  /**
   * 발달서비스 ID 기반 조회.
   *
   * 발달서비스을(를) ID를 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerFindById$Response(params: TicketControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketDto>> {
    return ticketControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 ID 기반 조회.
   *
   * 발달서비스을(를) ID를 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerFindById(params: TicketControllerFindById$Params, context?: HttpContext): Observable<TicketDto> {
    return this.ticketControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketDto>): TicketDto => r.body)
    );
  }

  /** Path part for operation `ticketControllerDelete()` */
  static readonly TicketControllerDeletePath = '/api/ticket/{id}';

  /**
   * 발달서비스 삭제.
   *
   * 발달서비스을(를) 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerDelete$Response(params: TicketControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketDto>> {
    return ticketControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 삭제.
   *
   * 발달서비스을(를) 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerDelete(params: TicketControllerDelete$Params, context?: HttpContext): Observable<TicketDto> {
    return this.ticketControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketDto>): TicketDto => r.body)
    );
  }

  /** Path part for operation `ticketControllerUpdate()` */
  static readonly TicketControllerUpdatePath = '/api/ticket/{id}';

  /**
   * 발달서비스 수정.
   *
   * 발달서비스을(를) 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketControllerUpdate$Response(params: TicketControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketDto>> {
    return ticketControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 수정.
   *
   * 발달서비스을(를) 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketControllerUpdate(params: TicketControllerUpdate$Params, context?: HttpContext): Observable<TicketDto> {
    return this.ticketControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketDto>): TicketDto => r.body)
    );
  }

  /** Path part for operation `ticketControllerEdit()` */
  static readonly TicketControllerEditPath = '/api/ticket/edit';

  /**
   * 발달서비스 일괄 편집.
   *
   * 발달서비스을(를) 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketControllerEdit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketControllerEdit$Response(params: TicketControllerEdit$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return ticketControllerEdit(this.http, this.rootUrl, params, context);
  }

  /**
   * 발달서비스 일괄 편집.
   *
   * 발달서비스을(를) 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketControllerEdit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketControllerEdit(params: TicketControllerEdit$Params, context?: HttpContext): Observable<boolean> {
    return this.ticketControllerEdit$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `ticketControllerMultipleDelete()` */
  static readonly TicketControllerMultipleDeletePath = '/api/ticket/multiple';

  /**
   * 여러 발달서비스 삭제.
   *
   * 여러개의 발달서비스을 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketControllerMultipleDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerMultipleDelete$Response(params: TicketControllerMultipleDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketDto>> {
    return ticketControllerMultipleDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 여러 발달서비스 삭제.
   *
   * 여러개의 발달서비스을 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketControllerMultipleDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketControllerMultipleDelete(params: TicketControllerMultipleDelete$Params, context?: HttpContext): Observable<TicketDto> {
    return this.ticketControllerMultipleDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketDto>): TicketDto => r.body)
    );
  }

  /** Path part for operation `ticketTemplateControllerSearch()` */
  static readonly TicketTemplateControllerSearchPath = '/api/ticket/{centerId}/template/search';

  /**
   * 티켓 템플릿 검색.
   *
   * 티켓 템플릿을 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketTemplateControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketTemplateControllerSearch$Response(params: TicketTemplateControllerSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return ticketTemplateControllerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * 티켓 템플릿 검색.
   *
   * 티켓 템플릿을 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketTemplateControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketTemplateControllerSearch(params: TicketTemplateControllerSearch$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.ticketTemplateControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `ticketTemplateControllerCreate()` */
  static readonly TicketTemplateControllerCreatePath = '/api/ticket/{centerId}/template';

  /**
   * 티켓 템플릿 생성.
   *
   * 티켓 템플릿을 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketTemplateControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketTemplateControllerCreate$Response(params: TicketTemplateControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketTemplateDto>> {
    return ticketTemplateControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 티켓 템플릿 생성.
   *
   * 티켓 템플릿을 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketTemplateControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketTemplateControllerCreate(params: TicketTemplateControllerCreate$Params, context?: HttpContext): Observable<TicketTemplateDto> {
    return this.ticketTemplateControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketTemplateDto>): TicketTemplateDto => r.body)
    );
  }

  /** Path part for operation `ticketTemplateControllerDelete()` */
  static readonly TicketTemplateControllerDeletePath = '/api/ticket/{centerId}/template/{id}';

  /**
   * 티켓 템플릿 삭제.
   *
   * 티켓 템플릿을 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketTemplateControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketTemplateControllerDelete$Response(params: TicketTemplateControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ticketTemplateControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 티켓 템플릿 삭제.
   *
   * 티켓 템플릿을 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketTemplateControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketTemplateControllerDelete(params: TicketTemplateControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.ticketTemplateControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ticketTemplateControllerUpdate()` */
  static readonly TicketTemplateControllerUpdatePath = '/api/ticket/{centerId}/template/{id}';

  /**
   * 티켓 템플릿 수정.
   *
   * 티켓 템플릿을 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketTemplateControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketTemplateControllerUpdate$Response(params: TicketTemplateControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketTemplateDto>> {
    return ticketTemplateControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 티켓 템플릿 수정.
   *
   * 티켓 템플릿을 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketTemplateControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketTemplateControllerUpdate(params: TicketTemplateControllerUpdate$Params, context?: HttpContext): Observable<TicketTemplateDto> {
    return this.ticketTemplateControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketTemplateDto>): TicketTemplateDto => r.body)
    );
  }

}
