/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminVoucherControllerCreate } from '../fn/voucher/admin-voucher-controller-create';
import { AdminVoucherControllerCreate$Params } from '../fn/voucher/admin-voucher-controller-create';
import { adminVoucherControllerDelete } from '../fn/voucher/admin-voucher-controller-delete';
import { AdminVoucherControllerDelete$Params } from '../fn/voucher/admin-voucher-controller-delete';
import { adminVoucherControllerFindAll } from '../fn/voucher/admin-voucher-controller-find-all';
import { AdminVoucherControllerFindAll$Params } from '../fn/voucher/admin-voucher-controller-find-all';
import { adminVoucherControllerFindById } from '../fn/voucher/admin-voucher-controller-find-by-id';
import { AdminVoucherControllerFindById$Params } from '../fn/voucher/admin-voucher-controller-find-by-id';
import { adminVoucherControllerSearchCursor } from '../fn/voucher/admin-voucher-controller-search-cursor';
import { AdminVoucherControllerSearchCursor$Params } from '../fn/voucher/admin-voucher-controller-search-cursor';
import { adminVoucherControllerSearchOffset } from '../fn/voucher/admin-voucher-controller-search-offset';
import { AdminVoucherControllerSearchOffset$Params } from '../fn/voucher/admin-voucher-controller-search-offset';
import { adminVoucherControllerUpdate } from '../fn/voucher/admin-voucher-controller-update';
import { AdminVoucherControllerUpdate$Params } from '../fn/voucher/admin-voucher-controller-update';
import { centerVoucherControllerCreate } from '../fn/voucher/center-voucher-controller-create';
import { CenterVoucherControllerCreate$Params } from '../fn/voucher/center-voucher-controller-create';
import { centerVoucherControllerDelete } from '../fn/voucher/center-voucher-controller-delete';
import { CenterVoucherControllerDelete$Params } from '../fn/voucher/center-voucher-controller-delete';
import { centerVoucherControllerFindAll } from '../fn/voucher/center-voucher-controller-find-all';
import { CenterVoucherControllerFindAll$Params } from '../fn/voucher/center-voucher-controller-find-all';
import { centerVoucherControllerSearchCursor } from '../fn/voucher/center-voucher-controller-search-cursor';
import { CenterVoucherControllerSearchCursor$Params } from '../fn/voucher/center-voucher-controller-search-cursor';
import { centerVoucherControllerSearchOffset } from '../fn/voucher/center-voucher-controller-search-offset';
import { CenterVoucherControllerSearchOffset$Params } from '../fn/voucher/center-voucher-controller-search-offset';
import { centerVoucherControllerUpdate } from '../fn/voucher/center-voucher-controller-update';
import { CenterVoucherControllerUpdate$Params } from '../fn/voucher/center-voucher-controller-update';
import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { VoucherDto } from '../models/voucher-dto';

@Injectable({ providedIn: 'root' })
export class VoucherService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminVoucherControllerFindAll()` */
  static readonly AdminVoucherControllerFindAllPath = '/api/voucher/admin';

  /**
   * 바우처 전체 조회.
   *
   * 바우처 전체를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminVoucherControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminVoucherControllerFindAll$Response(params: AdminVoucherControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VoucherDto>>> {
    return adminVoucherControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 전체 조회.
   *
   * 바우처 전체를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminVoucherControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminVoucherControllerFindAll(params: AdminVoucherControllerFindAll$Params, context?: HttpContext): Observable<Array<VoucherDto>> {
    return this.adminVoucherControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VoucherDto>>): Array<VoucherDto> => r.body)
    );
  }

  /** Path part for operation `adminVoucherControllerCreate()` */
  static readonly AdminVoucherControllerCreatePath = '/api/voucher/admin';

  /**
   * 바우처 생성.
   *
   * 바우처을(를) 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminVoucherControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminVoucherControllerCreate$Response(params: AdminVoucherControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<VoucherDto>> {
    return adminVoucherControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 생성.
   *
   * 바우처을(를) 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminVoucherControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminVoucherControllerCreate(params: AdminVoucherControllerCreate$Params, context?: HttpContext): Observable<VoucherDto> {
    return this.adminVoucherControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<VoucherDto>): VoucherDto => r.body)
    );
  }

  /** Path part for operation `adminVoucherControllerSearchOffset()` */
  static readonly AdminVoucherControllerSearchOffsetPath = '/api/voucher/admin/search/offset';

  /**
   * 바우처 오프셋 기반 조회.
   *
   * 바우처을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminVoucherControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminVoucherControllerSearchOffset$Response(params: AdminVoucherControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return adminVoucherControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 오프셋 기반 조회.
   *
   * 바우처을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminVoucherControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminVoucherControllerSearchOffset(params: AdminVoucherControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.adminVoucherControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `adminVoucherControllerSearchCursor()` */
  static readonly AdminVoucherControllerSearchCursorPath = '/api/voucher/admin/search/cursor';

  /**
   * 바우처 커서 기반 조회.
   *
   * 바우처을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminVoucherControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminVoucherControllerSearchCursor$Response(params: AdminVoucherControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return adminVoucherControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 커서 기반 조회.
   *
   * 바우처을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminVoucherControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminVoucherControllerSearchCursor(params: AdminVoucherControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.adminVoucherControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `adminVoucherControllerFindById()` */
  static readonly AdminVoucherControllerFindByIdPath = '/api/voucher/admin/{id}';

  /**
   * 바우처 ID 기반 조회.
   *
   * 바우처을(를) ID를 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminVoucherControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminVoucherControllerFindById$Response(params: AdminVoucherControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<VoucherDto>> {
    return adminVoucherControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 ID 기반 조회.
   *
   * 바우처을(를) ID를 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminVoucherControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminVoucherControllerFindById(params: AdminVoucherControllerFindById$Params, context?: HttpContext): Observable<VoucherDto> {
    return this.adminVoucherControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<VoucherDto>): VoucherDto => r.body)
    );
  }

  /** Path part for operation `adminVoucherControllerDelete()` */
  static readonly AdminVoucherControllerDeletePath = '/api/voucher/admin/{id}';

  /**
   * 바우처 삭제.
   *
   * 바우처을(를) 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminVoucherControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminVoucherControllerDelete$Response(params: AdminVoucherControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<VoucherDto>> {
    return adminVoucherControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 삭제.
   *
   * 바우처을(를) 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminVoucherControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminVoucherControllerDelete(params: AdminVoucherControllerDelete$Params, context?: HttpContext): Observable<VoucherDto> {
    return this.adminVoucherControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<VoucherDto>): VoucherDto => r.body)
    );
  }

  /** Path part for operation `adminVoucherControllerUpdate()` */
  static readonly AdminVoucherControllerUpdatePath = '/api/voucher/admin/{id}';

  /**
   * 바우처 수정.
   *
   * 바우처을(를) 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminVoucherControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminVoucherControllerUpdate$Response(params: AdminVoucherControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<VoucherDto>> {
    return adminVoucherControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 수정.
   *
   * 바우처을(를) 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminVoucherControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminVoucherControllerUpdate(params: AdminVoucherControllerUpdate$Params, context?: HttpContext): Observable<VoucherDto> {
    return this.adminVoucherControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<VoucherDto>): VoucherDto => r.body)
    );
  }

  /** Path part for operation `centerVoucherControllerFindAll()` */
  static readonly CenterVoucherControllerFindAllPath = '/api/voucher/center';

  /**
   * 바우처 전체 조회.
   *
   * 바우처 전체를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerVoucherControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerVoucherControllerFindAll$Response(params: CenterVoucherControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VoucherDto>>> {
    return centerVoucherControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 전체 조회.
   *
   * 바우처 전체를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerVoucherControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerVoucherControllerFindAll(params: CenterVoucherControllerFindAll$Params, context?: HttpContext): Observable<Array<VoucherDto>> {
    return this.centerVoucherControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VoucherDto>>): Array<VoucherDto> => r.body)
    );
  }

  /** Path part for operation `centerVoucherControllerCreate()` */
  static readonly CenterVoucherControllerCreatePath = '/api/voucher/center';

  /**
   * 바우처 생성.
   *
   * 바우처을(를) 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerVoucherControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerVoucherControllerCreate$Response(params: CenterVoucherControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<VoucherDto>> {
    return centerVoucherControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 생성.
   *
   * 바우처을(를) 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerVoucherControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerVoucherControllerCreate(params: CenterVoucherControllerCreate$Params, context?: HttpContext): Observable<VoucherDto> {
    return this.centerVoucherControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<VoucherDto>): VoucherDto => r.body)
    );
  }

  /** Path part for operation `centerVoucherControllerSearchOffset()` */
  static readonly CenterVoucherControllerSearchOffsetPath = '/api/voucher/center/search/offset';

  /**
   * 바우처 오프셋 기반 조회.
   *
   * 바우처을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerVoucherControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerVoucherControllerSearchOffset$Response(params: CenterVoucherControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return centerVoucherControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 오프셋 기반 조회.
   *
   * 바우처을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerVoucherControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerVoucherControllerSearchOffset(params: CenterVoucherControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.centerVoucherControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerVoucherControllerSearchCursor()` */
  static readonly CenterVoucherControllerSearchCursorPath = '/api/voucher/center/search/cursor';

  /**
   * 바우처 커서 기반 조회.
   *
   * 바우처을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerVoucherControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerVoucherControllerSearchCursor$Response(params: CenterVoucherControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return centerVoucherControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 커서 기반 조회.
   *
   * 바우처을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerVoucherControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerVoucherControllerSearchCursor(params: CenterVoucherControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.centerVoucherControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerVoucherControllerDelete()` */
  static readonly CenterVoucherControllerDeletePath = '/api/voucher/center/{id}';

  /**
   * 바우처 삭제.
   *
   * 바우처을(를) 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerVoucherControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerVoucherControllerDelete$Response(params: CenterVoucherControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<VoucherDto>> {
    return centerVoucherControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 삭제.
   *
   * 바우처을(를) 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerVoucherControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerVoucherControllerDelete(params: CenterVoucherControllerDelete$Params, context?: HttpContext): Observable<VoucherDto> {
    return this.centerVoucherControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<VoucherDto>): VoucherDto => r.body)
    );
  }

  /** Path part for operation `centerVoucherControllerUpdate()` */
  static readonly CenterVoucherControllerUpdatePath = '/api/voucher/center/{id}';

  /**
   * 바우처 수정.
   *
   * 바우처을(를) 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerVoucherControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerVoucherControllerUpdate$Response(params: CenterVoucherControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<VoucherDto>> {
    return centerVoucherControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 바우처 수정.
   *
   * 바우처을(를) 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerVoucherControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerVoucherControllerUpdate(params: CenterVoucherControllerUpdate$Params, context?: HttpContext): Observable<VoucherDto> {
    return this.centerVoucherControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<VoucherDto>): VoucherDto => r.body)
    );
  }

}
