/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { centerGroupControllerConnectUser } from '../fn/center-group/center-group-controller-connect-user';
import { CenterGroupControllerConnectUser$Params } from '../fn/center-group/center-group-controller-connect-user';
import { centerGroupControllerCreateGroup } from '../fn/center-group/center-group-controller-create-group';
import { CenterGroupControllerCreateGroup$Params } from '../fn/center-group/center-group-controller-create-group';
import { centerGroupControllerDeleteGroup } from '../fn/center-group/center-group-controller-delete-group';
import { CenterGroupControllerDeleteGroup$Params } from '../fn/center-group/center-group-controller-delete-group';
import { centerGroupControllerDisconnectUser } from '../fn/center-group/center-group-controller-disconnect-user';
import { CenterGroupControllerDisconnectUser$Params } from '../fn/center-group/center-group-controller-disconnect-user';
import { centerGroupControllerFindAllGroups } from '../fn/center-group/center-group-controller-find-all-groups';
import { CenterGroupControllerFindAllGroups$Params } from '../fn/center-group/center-group-controller-find-all-groups';
import { centerGroupControllerUpdateGroup } from '../fn/center-group/center-group-controller-update-group';
import { CenterGroupControllerUpdateGroup$Params } from '../fn/center-group/center-group-controller-update-group';
import { CenterGroupDto } from '../models/center-group-dto';

@Injectable({ providedIn: 'root' })
export class CenterGroupService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `centerGroupControllerFindAllGroups()` */
  static readonly CenterGroupControllerFindAllGroupsPath = '/api/center-group';

  /**
   * 센터의 모든 그룹 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerGroupControllerFindAllGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerGroupControllerFindAllGroups$Response(params: CenterGroupControllerFindAllGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CenterGroupDto>>> {
    return centerGroupControllerFindAllGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터의 모든 그룹 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerGroupControllerFindAllGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerGroupControllerFindAllGroups(params: CenterGroupControllerFindAllGroups$Params, context?: HttpContext): Observable<Array<CenterGroupDto>> {
    return this.centerGroupControllerFindAllGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CenterGroupDto>>): Array<CenterGroupDto> => r.body)
    );
  }

  /** Path part for operation `centerGroupControllerCreateGroup()` */
  static readonly CenterGroupControllerCreateGroupPath = '/api/center-group';

  /**
   * 그룹 생성.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerGroupControllerCreateGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerGroupControllerCreateGroup$Response(params: CenterGroupControllerCreateGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterGroupDto>> {
    return centerGroupControllerCreateGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * 그룹 생성.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerGroupControllerCreateGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerGroupControllerCreateGroup(params: CenterGroupControllerCreateGroup$Params, context?: HttpContext): Observable<CenterGroupDto> {
    return this.centerGroupControllerCreateGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterGroupDto>): CenterGroupDto => r.body)
    );
  }

  /** Path part for operation `centerGroupControllerConnectUser()` */
  static readonly CenterGroupControllerConnectUserPath = '/api/center-group/connect-user';

  /**
   * 그룹에 사용자 연결.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerGroupControllerConnectUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerGroupControllerConnectUser$Response(params: CenterGroupControllerConnectUser$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return centerGroupControllerConnectUser(this.http, this.rootUrl, params, context);
  }

  /**
   * 그룹에 사용자 연결.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerGroupControllerConnectUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerGroupControllerConnectUser(params: CenterGroupControllerConnectUser$Params, context?: HttpContext): Observable<boolean> {
    return this.centerGroupControllerConnectUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `centerGroupControllerDisconnectUser()` */
  static readonly CenterGroupControllerDisconnectUserPath = '/api/center-group/disconnect-user';

  /**
   * 그룹에서 사용자 연결 해제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerGroupControllerDisconnectUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerGroupControllerDisconnectUser$Response(params: CenterGroupControllerDisconnectUser$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return centerGroupControllerDisconnectUser(this.http, this.rootUrl, params, context);
  }

  /**
   * 그룹에서 사용자 연결 해제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerGroupControllerDisconnectUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerGroupControllerDisconnectUser(params: CenterGroupControllerDisconnectUser$Params, context?: HttpContext): Observable<boolean> {
    return this.centerGroupControllerDisconnectUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `centerGroupControllerDeleteGroup()` */
  static readonly CenterGroupControllerDeleteGroupPath = '/api/center-group/{id}';

  /**
   * 그룹 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerGroupControllerDeleteGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerGroupControllerDeleteGroup$Response(params: CenterGroupControllerDeleteGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return centerGroupControllerDeleteGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * 그룹 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerGroupControllerDeleteGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerGroupControllerDeleteGroup(params: CenterGroupControllerDeleteGroup$Params, context?: HttpContext): Observable<boolean> {
    return this.centerGroupControllerDeleteGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `centerGroupControllerUpdateGroup()` */
  static readonly CenterGroupControllerUpdateGroupPath = '/api/center-group/{id}';

  /**
   * 그룹 정보 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerGroupControllerUpdateGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerGroupControllerUpdateGroup$Response(params: CenterGroupControllerUpdateGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterGroupDto>> {
    return centerGroupControllerUpdateGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * 그룹 정보 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerGroupControllerUpdateGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerGroupControllerUpdateGroup(params: CenterGroupControllerUpdateGroup$Params, context?: HttpContext): Observable<CenterGroupDto> {
    return this.centerGroupControllerUpdateGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterGroupDto>): CenterGroupDto => r.body)
    );
  }

}
