<button (click)="clickHandler($event)">
  @switch (provider()) {
    @case (fnGetKeyByValue(oathProvider, oathProvider.EMAIL)) {
      @if (disabled()) {
        <img
          src="https://api.iconify.design/mdi:email.svg?color=%23d1d5db"
          alt="이메일 아이콘"
          class="size-6"
          draggable="false"
        />
      } @else {
        <img
          src="https://api.iconify.design/mdi:email.svg?color=%231693c9"
          alt="이메일 아이콘"
          class="size-6"
          draggable="false"
        />
      }
    }

    @case (fnGetKeyByValue(oathProvider, oathProvider.KAKAO)) {
      <div
        [ngClass]="
          disabled() ? 'bg-white border border-gray-200' : 'bg-[#FEE500]'
        "
        class="flex items-center justify-center text-sm font-extrabold rounded-full size-9"
      >
        @if (disabled()) {
          <img
            src="https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_2355fbddd74e499f89b6640b98832b2c/file-storage/gray-kakao.svg"
            alt="카카오 아이콘"
            class="size-5"
          />
        } @else {
          <img
            src="https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_2355fbddd74e499f89b6640b98832b2c/file-storage/kakao-svgrepo-com.svg"
            alt="카카오 아이콘"
            class="size-5"
          />
        }
      </div>
    }
    @case (fnGetKeyByValue(oathProvider, oathProvider.NAVER)) {
      <div
        [ngClass]="disabled() ? 'bg-gray-300' : 'bg-[#03C759]'"
        class="flex items-center justify-center text-sm font-extrabold rounded-full size-9"
      >
        <img
          src="https://api.iconify.design/simple-icons:naver.svg?color=%23ffffff"
          alt="네이버 아이콘"
          class="size-4"
          draggable="false"
        />
      </div>
    }
    @case (fnGetKeyByValue(oathProvider, oathProvider.GOOGLE)) {
      <div
        class="flex items-center justify-center bg-white border rounded-full size-9"
      >
        @if (disabled()) {
          <img
            src="https://api.iconify.design/mingcute:google-fill.svg?color=%23E0E0E0"
            alt="구글 아이콘"
            class="size-7"
            draggable="false"
          />
        } @else {
          <img
            src="https://api.iconify.design/logos:google-icon.svg"
            alt="구글 아이콘"
            class="size-6"
            draggable="false"
          />
        }
      </div>
    }
    @case (fnGetKeyByValue(oathProvider, oathProvider.APPLE)) {
      <div
        class="flex items-center justify-center bg-white border rounded-full size-9"
      >
        @if (disabled()) {
          <img
            src="https://api.iconify.design/ic:round-apple.svg?color=%23E0E0E0"
            alt="애플 아이콘"
            class="size-7"
            draggable="false"
          />
        } @else {
          <img
            src="https://api.iconify.design/ic:round-apple.svg?color=black"
            alt="애플 아이콘"
            class="size-7"
            draggable="false"
          />
        }
      </div>
    }
  }
</button>
