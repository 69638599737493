/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminCenterControllerActive } from '../fn/center/admin-center-controller-active';
import { AdminCenterControllerActive$Params } from '../fn/center/admin-center-controller-active';
import { adminCenterControllerAdminDelete } from '../fn/center/admin-center-controller-admin-delete';
import { AdminCenterControllerAdminDelete$Params } from '../fn/center/admin-center-controller-admin-delete';
import { adminCenterControllerAdminUpdate } from '../fn/center/admin-center-controller-admin-update';
import { AdminCenterControllerAdminUpdate$Params } from '../fn/center/admin-center-controller-admin-update';
import { adminCenterControllerCreateByOwnerId } from '../fn/center/admin-center-controller-create-by-owner-id';
import { AdminCenterControllerCreateByOwnerId$Params } from '../fn/center/admin-center-controller-create-by-owner-id';
import { adminCenterControllerFindById } from '../fn/center/admin-center-controller-find-by-id';
import { AdminCenterControllerFindById$Params } from '../fn/center/admin-center-controller-find-by-id';
import { adminCenterControllerFindPermissions } from '../fn/center/admin-center-controller-find-permissions';
import { AdminCenterControllerFindPermissions$Params } from '../fn/center/admin-center-controller-find-permissions';
import { adminCenterControllerSearchCursor } from '../fn/center/admin-center-controller-search-cursor';
import { AdminCenterControllerSearchCursor$Params } from '../fn/center/admin-center-controller-search-cursor';
import { adminCenterControllerSearchOffset } from '../fn/center/admin-center-controller-search-offset';
import { AdminCenterControllerSearchOffset$Params } from '../fn/center/admin-center-controller-search-offset';
import { CenterDto } from '../models/center-dto';
import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { SearchCenterResponseDto } from '../models/search-center-response-dto';
import { userCenterControllerCreate } from '../fn/center/user-center-controller-create';
import { UserCenterControllerCreate$Params } from '../fn/center/user-center-controller-create';
import { userCenterControllerDelete } from '../fn/center/user-center-controller-delete';
import { UserCenterControllerDelete$Params } from '../fn/center/user-center-controller-delete';
import { userCenterControllerFindById } from '../fn/center/user-center-controller-find-by-id';
import { UserCenterControllerFindById$Params } from '../fn/center/user-center-controller-find-by-id';
import { userCenterControllerFindPermissions } from '../fn/center/user-center-controller-find-permissions';
import { UserCenterControllerFindPermissions$Params } from '../fn/center/user-center-controller-find-permissions';
import { userCenterControllerGetMyCenterList } from '../fn/center/user-center-controller-get-my-center-list';
import { UserCenterControllerGetMyCenterList$Params } from '../fn/center/user-center-controller-get-my-center-list';
import { userCenterControllerSearchCursor } from '../fn/center/user-center-controller-search-cursor';
import { UserCenterControllerSearchCursor$Params } from '../fn/center/user-center-controller-search-cursor';
import { userCenterControllerSearchOffset } from '../fn/center/user-center-controller-search-offset';
import { UserCenterControllerSearchOffset$Params } from '../fn/center/user-center-controller-search-offset';
import { userCenterControllerUpdate } from '../fn/center/user-center-controller-update';
import { UserCenterControllerUpdate$Params } from '../fn/center/user-center-controller-update';

@Injectable({ providedIn: 'root' })
export class CenterService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminCenterControllerFindPermissions()` */
  static readonly AdminCenterControllerFindPermissionsPath = '/api/center/admin/permissions';

  /**
   * 센터 권한 조회.
   *
   * 센터의 권한을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCenterControllerFindPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterControllerFindPermissions$Response(params?: AdminCenterControllerFindPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return adminCenterControllerFindPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 권한 조회.
   *
   * 센터의 권한을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCenterControllerFindPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterControllerFindPermissions(params?: AdminCenterControllerFindPermissions$Params, context?: HttpContext): Observable<Array<string>> {
    return this.adminCenterControllerFindPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `adminCenterControllerFindById()` */
  static readonly AdminCenterControllerFindByIdPath = '/api/center/admin/{id}';

  /**
   * 센터 ID 기반 조회.
   *
   * 센터 ID를 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCenterControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterControllerFindById$Response(params: AdminCenterControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return adminCenterControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 ID 기반 조회.
   *
   * 센터 ID를 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCenterControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterControllerFindById(params: AdminCenterControllerFindById$Params, context?: HttpContext): Observable<CenterDto> {
    return this.adminCenterControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `adminCenterControllerSearchOffset()` */
  static readonly AdminCenterControllerSearchOffsetPath = '/api/center/admin/search/offset';

  /**
   * 센터 오프셋 기반 검색.
   *
   * 센터를 오프셋 기반으로 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCenterControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterControllerSearchOffset$Response(params: AdminCenterControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchCenterResponseDto>> {
    return adminCenterControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 오프셋 기반 검색.
   *
   * 센터를 오프셋 기반으로 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCenterControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterControllerSearchOffset(params: AdminCenterControllerSearchOffset$Params, context?: HttpContext): Observable<SearchCenterResponseDto> {
    return this.adminCenterControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchCenterResponseDto>): SearchCenterResponseDto => r.body)
    );
  }

  /** Path part for operation `adminCenterControllerSearchCursor()` */
  static readonly AdminCenterControllerSearchCursorPath = '/api/center/admin/search/cursor';

  /**
   * 센터 커서 기반 검색.
   *
   * 센터를 커서 기반으로 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCenterControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterControllerSearchCursor$Response(params: AdminCenterControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return adminCenterControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 커서 기반 검색.
   *
   * 센터를 커서 기반으로 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCenterControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterControllerSearchCursor(params: AdminCenterControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.adminCenterControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `adminCenterControllerCreateByOwnerId()` */
  static readonly AdminCenterControllerCreateByOwnerIdPath = '/api/center/admin/{ownerId}';

  /**
   * 센터 생성(관리자).
   *
   * 센터를 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCenterControllerCreateByOwnerId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminCenterControllerCreateByOwnerId$Response(params: AdminCenterControllerCreateByOwnerId$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return adminCenterControllerCreateByOwnerId(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 생성(관리자).
   *
   * 센터를 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCenterControllerCreateByOwnerId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminCenterControllerCreateByOwnerId(params: AdminCenterControllerCreateByOwnerId$Params, context?: HttpContext): Observable<CenterDto> {
    return this.adminCenterControllerCreateByOwnerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `adminCenterControllerAdminDelete()` */
  static readonly AdminCenterControllerAdminDeletePath = '/api/center/admin/{id}/admin';

  /**
   * 센터 삭제(관리자).
   *
   * 센터를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCenterControllerAdminDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterControllerAdminDelete$Response(params: AdminCenterControllerAdminDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return adminCenterControllerAdminDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 삭제(관리자).
   *
   * 센터를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCenterControllerAdminDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCenterControllerAdminDelete(params: AdminCenterControllerAdminDelete$Params, context?: HttpContext): Observable<CenterDto> {
    return this.adminCenterControllerAdminDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `adminCenterControllerAdminUpdate()` */
  static readonly AdminCenterControllerAdminUpdatePath = '/api/center/admin/{id}/admin';

  /**
   * 센터 수정.
   *
   * 센터를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCenterControllerAdminUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminCenterControllerAdminUpdate$Response(params: AdminCenterControllerAdminUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return adminCenterControllerAdminUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 수정.
   *
   * 센터를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCenterControllerAdminUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminCenterControllerAdminUpdate(params: AdminCenterControllerAdminUpdate$Params, context?: HttpContext): Observable<CenterDto> {
    return this.adminCenterControllerAdminUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `adminCenterControllerActive()` */
  static readonly AdminCenterControllerActivePath = '/api/center/admin/{id}/active';

  /**
   * 센터 활성화.
   *
   * 센터를 활성화합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCenterControllerActive()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminCenterControllerActive$Response(params: AdminCenterControllerActive$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return adminCenterControllerActive(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 활성화.
   *
   * 센터를 활성화합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCenterControllerActive$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminCenterControllerActive(params: AdminCenterControllerActive$Params, context?: HttpContext): Observable<CenterDto> {
    return this.adminCenterControllerActive$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `userCenterControllerFindPermissions()` */
  static readonly UserCenterControllerFindPermissionsPath = '/api/center/user/permissions';

  /**
   * 센터 권한 조회.
   *
   * 센터의 권한을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCenterControllerFindPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterControllerFindPermissions$Response(params?: UserCenterControllerFindPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return userCenterControllerFindPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 권한 조회.
   *
   * 센터의 권한을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCenterControllerFindPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterControllerFindPermissions(params?: UserCenterControllerFindPermissions$Params, context?: HttpContext): Observable<Array<string>> {
    return this.userCenterControllerFindPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `userCenterControllerGetMyCenterList()` */
  static readonly UserCenterControllerGetMyCenterListPath = '/api/center/user/me';

  /**
   * 내 센터 조회.
   *
   * 속해 있는 센터를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCenterControllerGetMyCenterList()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterControllerGetMyCenterList$Response(params?: UserCenterControllerGetMyCenterList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CenterDto>>> {
    return userCenterControllerGetMyCenterList(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 센터 조회.
   *
   * 속해 있는 센터를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCenterControllerGetMyCenterList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterControllerGetMyCenterList(params?: UserCenterControllerGetMyCenterList$Params, context?: HttpContext): Observable<Array<CenterDto>> {
    return this.userCenterControllerGetMyCenterList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CenterDto>>): Array<CenterDto> => r.body)
    );
  }

  /** Path part for operation `userCenterControllerFindById()` */
  static readonly UserCenterControllerFindByIdPath = '/api/center/user/{id}';

  /**
   * 센터 ID 기반 조회.
   *
   * 센터 ID를 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCenterControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterControllerFindById$Response(params: UserCenterControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return userCenterControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 ID 기반 조회.
   *
   * 센터 ID를 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCenterControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterControllerFindById(params: UserCenterControllerFindById$Params, context?: HttpContext): Observable<CenterDto> {
    return this.userCenterControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `userCenterControllerDelete()` */
  static readonly UserCenterControllerDeletePath = '/api/center/user/{id}';

  /**
   * 센터 삭제.
   *
   * 센터를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCenterControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterControllerDelete$Response(params: UserCenterControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return userCenterControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 삭제.
   *
   * 센터를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCenterControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterControllerDelete(params: UserCenterControllerDelete$Params, context?: HttpContext): Observable<CenterDto> {
    return this.userCenterControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `userCenterControllerUpdate()` */
  static readonly UserCenterControllerUpdatePath = '/api/center/user/{id}';

  /**
   * 센터 수정.
   *
   * 센터를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCenterControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCenterControllerUpdate$Response(params: UserCenterControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return userCenterControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 수정.
   *
   * 센터를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCenterControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCenterControllerUpdate(params: UserCenterControllerUpdate$Params, context?: HttpContext): Observable<CenterDto> {
    return this.userCenterControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

  /** Path part for operation `userCenterControllerSearchOffset()` */
  static readonly UserCenterControllerSearchOffsetPath = '/api/center/user/search/offset';

  /**
   * 센터 오프셋 기반 검색.
   *
   * 센터를 오프셋 기반으로 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCenterControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterControllerSearchOffset$Response(params: UserCenterControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchCenterResponseDto>> {
    return userCenterControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 오프셋 기반 검색.
   *
   * 센터를 오프셋 기반으로 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCenterControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterControllerSearchOffset(params: UserCenterControllerSearchOffset$Params, context?: HttpContext): Observable<SearchCenterResponseDto> {
    return this.userCenterControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchCenterResponseDto>): SearchCenterResponseDto => r.body)
    );
  }

  /** Path part for operation `userCenterControllerSearchCursor()` */
  static readonly UserCenterControllerSearchCursorPath = '/api/center/user/search/cursor';

  /**
   * 센터 커서 기반 검색.
   *
   * 센터를 커서 기반으로 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCenterControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterControllerSearchCursor$Response(params: UserCenterControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return userCenterControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 커서 기반 검색.
   *
   * 센터를 커서 기반으로 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCenterControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCenterControllerSearchCursor(params: UserCenterControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.userCenterControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `userCenterControllerCreate()` */
  static readonly UserCenterControllerCreatePath = '/api/center/user';

  /**
   * 센터 생성.
   *
   * 센터를 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCenterControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCenterControllerCreate$Response(params: UserCenterControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CenterDto>> {
    return userCenterControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 생성.
   *
   * 센터를 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCenterControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCenterControllerCreate(params: UserCenterControllerCreate$Params, context?: HttpContext): Observable<CenterDto> {
    return this.userCenterControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CenterDto>): CenterDto => r.body)
    );
  }

}
