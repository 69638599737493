import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  Component,
  ElementRef,
  input,
  viewChild,
} from '@angular/core';
import { BaseConfig } from '../../common/config/config.adapter';

@Component({
  selector: '[modalContainer]',
  template: `
    <section [attr.data-theme]="theme()" #modal class="modal-container slide">
      <section class="xl:rounded-t-[20px] overflow-hidden">
        <ng-content select="[header]" />
      </section>
      <section class="modal-content" [class.overflow-y-auto]="scrollEnabled()">
        <ng-content />
        @if (!invisibleWhitespace()) {
          <div class="h-[10rem] md:h-[5rem]"></div>
        }
      </section>
      <section
        class="md:static fixed bottom-0 w-full xl:rounded-b-[20px] overflow-hidden bg-white"
      >
        <ng-content select="[footer]" />
      </section>
    </section>
  `,
  imports: [CommonModule],
  styleUrls: ['./modal-container.component.scss'],
})
export class ModalContainer extends BaseConfig {
  scrollEnabled = input<boolean, string>(false, {
    transform: booleanAttribute,
  });
  modalRef = viewChild<ElementRef<HTMLElement>>('modal');
  invisibleWhitespace = input<boolean, string>(false, {
    transform: booleanAttribute,
  });

  ngAfterViewInit() {
    this.modalRef()?.nativeElement.animate(
      [
        { opacity: 0, transform: 'translateY(10px)' },
        { opacity: 1, transform: 'translateY(0)' },
      ],
      {
        duration: 200,
        easing: 'ease-in-out',
      },
    );
  }
}
