<div class="overflow-hidden bg-white rounded-md">
  <app-button
    (click)="handleDownload()"
    variant="outline"
    icon="material-symbols:download-2"
    [size]="size()"
  >
    <p class="truncate max-w-[15rem]">{{ name() }}</p>
  </app-button>
</div>
