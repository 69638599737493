/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { centerJoinLogControllerAcceptJoinRequest } from '../fn/center-join-log/center-join-log-controller-accept-join-request';
import { CenterJoinLogControllerAcceptJoinRequest$Params } from '../fn/center-join-log/center-join-log-controller-accept-join-request';
import { centerJoinLogControllerCancelJoinRequest } from '../fn/center-join-log/center-join-log-controller-cancel-join-request';
import { CenterJoinLogControllerCancelJoinRequest$Params } from '../fn/center-join-log/center-join-log-controller-cancel-join-request';
import { centerJoinLogControllerJoinUser } from '../fn/center-join-log/center-join-log-controller-join-user';
import { CenterJoinLogControllerJoinUser$Params } from '../fn/center-join-log/center-join-log-controller-join-user';
import { centerJoinLogControllerRejectJoinRequest } from '../fn/center-join-log/center-join-log-controller-reject-join-request';
import { CenterJoinLogControllerRejectJoinRequest$Params } from '../fn/center-join-log/center-join-log-controller-reject-join-request';
import { centerJoinLogControllerSearchCursor } from '../fn/center-join-log/center-join-log-controller-search-cursor';
import { CenterJoinLogControllerSearchCursor$Params } from '../fn/center-join-log/center-join-log-controller-search-cursor';
import { centerJoinLogControllerSearchOffset } from '../fn/center-join-log/center-join-log-controller-search-offset';
import { CenterJoinLogControllerSearchOffset$Params } from '../fn/center-join-log/center-join-log-controller-search-offset';
import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { JoinLogDto } from '../models/join-log-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class CenterJoinLogService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `centerJoinLogControllerSearchOffset()` */
  static readonly CenterJoinLogControllerSearchOffsetPath = '/api/center-join-log/join/search/offset';

  /**
   * 센터 가입 내역 오프셋 기반 검색.
   *
   * 센터 가입 내역을 오프셋 기반으로 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerJoinLogControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerSearchOffset$Response(params: CenterJoinLogControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return centerJoinLogControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 가입 내역 오프셋 기반 검색.
   *
   * 센터 가입 내역을 오프셋 기반으로 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerJoinLogControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerSearchOffset(params: CenterJoinLogControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.centerJoinLogControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerJoinLogControllerSearchCursor()` */
  static readonly CenterJoinLogControllerSearchCursorPath = '/api/center-join-log/join/search/cursor';

  /**
   * 센터 가입 내역 커서 기반 검색.
   *
   * 센터 가입 내역을 커서 기반으로 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerJoinLogControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerSearchCursor$Response(params: CenterJoinLogControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return centerJoinLogControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 가입 내역 커서 기반 검색.
   *
   * 센터 가입 내역을 커서 기반으로 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerJoinLogControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerSearchCursor(params: CenterJoinLogControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.centerJoinLogControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerJoinLogControllerJoinUser()` */
  static readonly CenterJoinLogControllerJoinUserPath = '/api/center-join-log/{id}/join';

  /**
   * 센터 가입 요청.
   *
   * 센터에 가입을 요청합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerJoinLogControllerJoinUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerJoinUser$Response(params: CenterJoinLogControllerJoinUser$Params, context?: HttpContext): Observable<StrictHttpResponse<JoinLogDto>> {
    return centerJoinLogControllerJoinUser(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 가입 요청.
   *
   * 센터에 가입을 요청합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerJoinLogControllerJoinUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerJoinUser(params: CenterJoinLogControllerJoinUser$Params, context?: HttpContext): Observable<JoinLogDto> {
    return this.centerJoinLogControllerJoinUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<JoinLogDto>): JoinLogDto => r.body)
    );
  }

  /** Path part for operation `centerJoinLogControllerAcceptJoinRequest()` */
  static readonly CenterJoinLogControllerAcceptJoinRequestPath = '/api/center-join-log/{id}/join/{joinId}/accept';

  /**
   * 센터 가입 승인.
   *
   * 센터에 가입을 요청한 사용자의 가입을 승인합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerJoinLogControllerAcceptJoinRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerAcceptJoinRequest$Response(params: CenterJoinLogControllerAcceptJoinRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<JoinLogDto>> {
    return centerJoinLogControllerAcceptJoinRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 가입 승인.
   *
   * 센터에 가입을 요청한 사용자의 가입을 승인합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerJoinLogControllerAcceptJoinRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerAcceptJoinRequest(params: CenterJoinLogControllerAcceptJoinRequest$Params, context?: HttpContext): Observable<JoinLogDto> {
    return this.centerJoinLogControllerAcceptJoinRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<JoinLogDto>): JoinLogDto => r.body)
    );
  }

  /** Path part for operation `centerJoinLogControllerRejectJoinRequest()` */
  static readonly CenterJoinLogControllerRejectJoinRequestPath = '/api/center-join-log/{id}/join/{joinId}/reject';

  /**
   * 센터 가입 거부.
   *
   * 센터에 가입을 요청한 사용자의 가입을 거부합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerJoinLogControllerRejectJoinRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerRejectJoinRequest$Response(params: CenterJoinLogControllerRejectJoinRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<JoinLogDto>> {
    return centerJoinLogControllerRejectJoinRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * 센터 가입 거부.
   *
   * 센터에 가입을 요청한 사용자의 가입을 거부합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerJoinLogControllerRejectJoinRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerRejectJoinRequest(params: CenterJoinLogControllerRejectJoinRequest$Params, context?: HttpContext): Observable<JoinLogDto> {
    return this.centerJoinLogControllerRejectJoinRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<JoinLogDto>): JoinLogDto => r.body)
    );
  }

  /** Path part for operation `centerJoinLogControllerCancelJoinRequest()` */
  static readonly CenterJoinLogControllerCancelJoinRequestPath = '/api/center-join-log/join/{id}/cancel';

  /**
   * 가입 요청 취소.
   *
   * 가입 요청을 취소합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerJoinLogControllerCancelJoinRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerCancelJoinRequest$Response(params: CenterJoinLogControllerCancelJoinRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<JoinLogDto>> {
    return centerJoinLogControllerCancelJoinRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * 가입 요청 취소.
   *
   * 가입 요청을 취소합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerJoinLogControllerCancelJoinRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerJoinLogControllerCancelJoinRequest(params: CenterJoinLogControllerCancelJoinRequest$Params, context?: HttpContext): Observable<JoinLogDto> {
    return this.centerJoinLogControllerCancelJoinRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<JoinLogDto>): JoinLogDto => r.body)
    );
  }

}
