/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminTicketTypeControllerCreate } from '../fn/ticket-type/admin-ticket-type-controller-create';
import { AdminTicketTypeControllerCreate$Params } from '../fn/ticket-type/admin-ticket-type-controller-create';
import { adminTicketTypeControllerFindAllTypes } from '../fn/ticket-type/admin-ticket-type-controller-find-all-types';
import { AdminTicketTypeControllerFindAllTypes$Params } from '../fn/ticket-type/admin-ticket-type-controller-find-all-types';
import { adminTicketTypeControllerRemove } from '../fn/ticket-type/admin-ticket-type-controller-remove';
import { AdminTicketTypeControllerRemove$Params } from '../fn/ticket-type/admin-ticket-type-controller-remove';
import { adminTicketTypeControllerSearchTypes } from '../fn/ticket-type/admin-ticket-type-controller-search-types';
import { AdminTicketTypeControllerSearchTypes$Params } from '../fn/ticket-type/admin-ticket-type-controller-search-types';
import { adminTicketTypeControllerUpdate } from '../fn/ticket-type/admin-ticket-type-controller-update';
import { AdminTicketTypeControllerUpdate$Params } from '../fn/ticket-type/admin-ticket-type-controller-update';
import { centerTicketTypeControllerCreate } from '../fn/ticket-type/center-ticket-type-controller-create';
import { CenterTicketTypeControllerCreate$Params } from '../fn/ticket-type/center-ticket-type-controller-create';
import { centerTicketTypeControllerFindAllTypes } from '../fn/ticket-type/center-ticket-type-controller-find-all-types';
import { CenterTicketTypeControllerFindAllTypes$Params } from '../fn/ticket-type/center-ticket-type-controller-find-all-types';
import { centerTicketTypeControllerRemove } from '../fn/ticket-type/center-ticket-type-controller-remove';
import { CenterTicketTypeControllerRemove$Params } from '../fn/ticket-type/center-ticket-type-controller-remove';
import { centerTicketTypeControllerSearchTypes } from '../fn/ticket-type/center-ticket-type-controller-search-types';
import { CenterTicketTypeControllerSearchTypes$Params } from '../fn/ticket-type/center-ticket-type-controller-search-types';
import { centerTicketTypeControllerUpdate } from '../fn/ticket-type/center-ticket-type-controller-update';
import { CenterTicketTypeControllerUpdate$Params } from '../fn/ticket-type/center-ticket-type-controller-update';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { TicketTemplateDto } from '../models/ticket-template-dto';
import { TicketTypeDto } from '../models/ticket-type-dto';

@Injectable({ providedIn: 'root' })
export class TicketTypeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminTicketTypeControllerSearchTypes()` */
  static readonly AdminTicketTypeControllerSearchTypesPath = '/api/ticket-type/admin/search';

  /**
   * 치료 유형 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminTicketTypeControllerSearchTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminTicketTypeControllerSearchTypes$Response(params: AdminTicketTypeControllerSearchTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return adminTicketTypeControllerSearchTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * 치료 유형 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminTicketTypeControllerSearchTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminTicketTypeControllerSearchTypes(params: AdminTicketTypeControllerSearchTypes$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.adminTicketTypeControllerSearchTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `adminTicketTypeControllerFindAllTypes()` */
  static readonly AdminTicketTypeControllerFindAllTypesPath = '/api/ticket-type/admin/{centerId}';

  /**
   * 치료 유형 목록 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminTicketTypeControllerFindAllTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminTicketTypeControllerFindAllTypes$Response(params: AdminTicketTypeControllerFindAllTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketTemplateDto>>> {
    return adminTicketTypeControllerFindAllTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * 치료 유형 목록 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminTicketTypeControllerFindAllTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminTicketTypeControllerFindAllTypes(params: AdminTicketTypeControllerFindAllTypes$Params, context?: HttpContext): Observable<Array<TicketTemplateDto>> {
    return this.adminTicketTypeControllerFindAllTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketTemplateDto>>): Array<TicketTemplateDto> => r.body)
    );
  }

  /** Path part for operation `adminTicketTypeControllerCreate()` */
  static readonly AdminTicketTypeControllerCreatePath = '/api/ticket-type/admin';

  /**
   * 치료 유형 등록.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminTicketTypeControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminTicketTypeControllerCreate$Response(params: AdminTicketTypeControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketTypeDto>> {
    return adminTicketTypeControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 치료 유형 등록.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminTicketTypeControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminTicketTypeControllerCreate(params: AdminTicketTypeControllerCreate$Params, context?: HttpContext): Observable<TicketTypeDto> {
    return this.adminTicketTypeControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketTypeDto>): TicketTypeDto => r.body)
    );
  }

  /** Path part for operation `adminTicketTypeControllerUpdate()` */
  static readonly AdminTicketTypeControllerUpdatePath = '/api/ticket-type/admin/{id}';

  /**
   * 치료 유형 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminTicketTypeControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminTicketTypeControllerUpdate$Response(params: AdminTicketTypeControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketTypeDto>> {
    return adminTicketTypeControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 치료 유형 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminTicketTypeControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminTicketTypeControllerUpdate(params: AdminTicketTypeControllerUpdate$Params, context?: HttpContext): Observable<TicketTypeDto> {
    return this.adminTicketTypeControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketTypeDto>): TicketTypeDto => r.body)
    );
  }

  /** Path part for operation `adminTicketTypeControllerRemove()` */
  static readonly AdminTicketTypeControllerRemovePath = '/api/ticket-type/admin/{id}';

  /**
   * 치료 유형 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminTicketTypeControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminTicketTypeControllerRemove$Response(params: AdminTicketTypeControllerRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketTypeDto>> {
    return adminTicketTypeControllerRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * 치료 유형 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminTicketTypeControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminTicketTypeControllerRemove(params: AdminTicketTypeControllerRemove$Params, context?: HttpContext): Observable<TicketTypeDto> {
    return this.adminTicketTypeControllerRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketTypeDto>): TicketTypeDto => r.body)
    );
  }

  /** Path part for operation `centerTicketTypeControllerSearchTypes()` */
  static readonly CenterTicketTypeControllerSearchTypesPath = '/api/ticket-type/center/search';

  /**
   * 치료 유형 검색.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerTicketTypeControllerSearchTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerTicketTypeControllerSearchTypes$Response(params: CenterTicketTypeControllerSearchTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return centerTicketTypeControllerSearchTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * 치료 유형 검색.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerTicketTypeControllerSearchTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerTicketTypeControllerSearchTypes(params: CenterTicketTypeControllerSearchTypes$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.centerTicketTypeControllerSearchTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `centerTicketTypeControllerFindAllTypes()` */
  static readonly CenterTicketTypeControllerFindAllTypesPath = '/api/ticket-type/center/{centerId}';

  /**
   * 치료 유형 목록 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerTicketTypeControllerFindAllTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerTicketTypeControllerFindAllTypes$Response(params: CenterTicketTypeControllerFindAllTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TicketTemplateDto>>> {
    return centerTicketTypeControllerFindAllTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * 치료 유형 목록 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerTicketTypeControllerFindAllTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerTicketTypeControllerFindAllTypes(params: CenterTicketTypeControllerFindAllTypes$Params, context?: HttpContext): Observable<Array<TicketTemplateDto>> {
    return this.centerTicketTypeControllerFindAllTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TicketTemplateDto>>): Array<TicketTemplateDto> => r.body)
    );
  }

  /** Path part for operation `centerTicketTypeControllerCreate()` */
  static readonly CenterTicketTypeControllerCreatePath = '/api/ticket-type/center';

  /**
   * 치료 유형 등록.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerTicketTypeControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerTicketTypeControllerCreate$Response(params: CenterTicketTypeControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketTypeDto>> {
    return centerTicketTypeControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 치료 유형 등록.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerTicketTypeControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerTicketTypeControllerCreate(params: CenterTicketTypeControllerCreate$Params, context?: HttpContext): Observable<TicketTypeDto> {
    return this.centerTicketTypeControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketTypeDto>): TicketTypeDto => r.body)
    );
  }

  /** Path part for operation `centerTicketTypeControllerUpdate()` */
  static readonly CenterTicketTypeControllerUpdatePath = '/api/ticket-type/center/{id}';

  /**
   * 치료 유형 수정.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerTicketTypeControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerTicketTypeControllerUpdate$Response(params: CenterTicketTypeControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketTypeDto>> {
    return centerTicketTypeControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 치료 유형 수정.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerTicketTypeControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  centerTicketTypeControllerUpdate(params: CenterTicketTypeControllerUpdate$Params, context?: HttpContext): Observable<TicketTypeDto> {
    return this.centerTicketTypeControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketTypeDto>): TicketTypeDto => r.body)
    );
  }

  /** Path part for operation `centerTicketTypeControllerRemove()` */
  static readonly CenterTicketTypeControllerRemovePath = '/api/ticket-type/center/{id}';

  /**
   * 치료 유형 삭제.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `centerTicketTypeControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerTicketTypeControllerRemove$Response(params: CenterTicketTypeControllerRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<TicketTypeDto>> {
    return centerTicketTypeControllerRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * 치료 유형 삭제.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `centerTicketTypeControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  centerTicketTypeControllerRemove(params: CenterTicketTypeControllerRemove$Params, context?: HttpContext): Observable<TicketTypeDto> {
    return this.centerTicketTypeControllerRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<TicketTypeDto>): TicketTypeDto => r.body)
    );
  }

}
