import { CommonModule } from "@angular/common";
import { Component, HostBinding, input, model } from "@angular/core";
import { BaseConfig } from "../../common/config/config.adapter";

@Component({
  selector: "app-radio-option",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./radio-option.component.html",
  styleUrls: ["./radio-option.component.scss"],
})
export class RadioOptionItem extends BaseConfig {
  @HostBinding("class") class = "flex gap-2";
  value = model<string | number | boolean>();
  active = model<boolean>(false);
  label = input<string | undefined>();
  disabled = input<boolean>(false);
  description = input<string | undefined>();
}
