<div class="flex flex-col gap-2">
  @if (label(); as label) {
    <app-label [required]="required()">{{ label }}</app-label>
  }
  <div class="flex items-center gap-3">
    <app-input
      class="w-full"
      placeholder="주민등록번호 앞자리"
      [color]="$color()"
      [required]="required()"
      [rounded]="currentRounded()"
      [spellcheck]="spellcheck()"
      [autocomplete]="autocomplete()"
      [autofocus]="autofocus()"
      [readonly]="readonly()"
      [disabled]="disabled()"
      [(value)]="first"
      (valueChange)="valueChange()"
      [maxlength]="6"
      [minlength]="6"
      inputmode="numeric"
    />
    <p class="theme-text" [attr.data-theme]="theme()">-</p>
    <app-input
      type="password"
      class="w-full"
      placeholder="주민등록번호 뒷자리"
      [color]="$color()"
      [required]="required()"
      [rounded]="currentRounded()"
      [spellcheck]="spellcheck()"
      [autocomplete]="autocomplete()"
      [autofocus]="autofocus()"
      [readonly]="readonly()"
      [disabled]="disabled()"
      [(value)]="second"
      (valueChange)="valueChange()"
      [maxlength]="7"
      [minlength]="7"
      inputmode="numeric"
    />
    <ng-content select="[buttons]" />
  </div>
  @if (_invalid() && touched()) {
    <p class="px-2 py-1 mt-2 text-sm text-red-400 rounded-md bg-red-50 w-max">
      주민번호를 올바르게 입력해주세요.
    </p>
  }
</div>
