import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, output, signal } from '@angular/core';
import dayjs from 'dayjs';
import { BaseConfig } from 'libs/front-share/src/components/common/config/config.adapter';
import { IconButton, Menu } from 'libs/front-share/src/components/components';
import { CalendarStore } from '../../../calendar.store';

@Component({
  selector: 'app-month-select',
  styleUrls: ['./month-select.component.scss'],
  standalone: true,
  imports: [CommonModule, IconButton, Menu],
  template: `
    <app-menu>
      <div class="flex w-full justify-between px-4 py-2">
        <p
          [attr.data-theme]="theme()"
          class="font-semibold theme-text text-base md:text-lg"
        >
          {{ date() | date: 'y년' }}
        </p>
        <div class="flex gap-3 items-center">
          <app-icon-button
            (click)="handleNavigate('prev', 'year', 1)"
            icon="ic:baseline-chevron-left"
          />
          <app-icon-button
            (click)="handleNavigate('next', 'year', 1)"
            icon="ic:baseline-chevron-right"
          />
        </div>
      </div>
      <div class="grid grid-cols-4 px-4 py-2 gap-4">
        @for (month of months; track month) {
          <div
            (click)="handleSelect(month)"
            [attr.data-active]="active(month)"
            [attr.data-theme]="theme()"
            class="month-item theme-hover theme-text"
          >
            {{ month }}월
          </div>
        }
      </div>
    </app-menu>
  `,
})
export class CalendarMonthSelect extends BaseConfig implements OnInit {
  readonly store = inject(CalendarStore);

  select = output<Date>();

  date = signal<Date | null>(new Date());
  months = this.store.months;

  ngOnInit(): void {
    this.date.set(this.store.value() || new Date());
  }

  active(month: number) {
    return dayjs(this.date()).get('month') === month - 1;
  }

  /**
   * @description 년도와 달을 이동하는 통합 함수
   * @param type
   * @param unit
   * @param number
   */
  handleNavigate(
    type: 'prev' | 'next',
    unit: 'year' | 'month',
    number: number,
  ) {
    if (type === 'prev') {
      const value = dayjs(this.date()).subtract(number, unit).toDate();
      this.date.set(value);
    } else {
      const value = dayjs(this.date()).add(number, unit).toDate();
      this.date.set(value);
    }
  }

  /**
   * @description 년도와 달을 선택했을 경우 이벤트 핸들링
   * @param month
   */
  handleSelect(month: number) {
    const date = dayjs(this.date())
      .set('month', month - 1)
      .toDate();
    this.select.emit(date);
  }
}
