/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SignInDto } from '../../models/sign-in-dto';

export interface PostUserControllerLogin$Params {
      body: SignInDto
}

export function postUserControllerLogin(http: HttpClient, rootUrl: string, params: PostUserControllerLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'accessToken'?: string;
'refreshToken'?: string;
}>> {
  const rb = new RequestBuilder(rootUrl, postUserControllerLogin.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'accessToken'?: string;
      'refreshToken'?: string;
      }>;
    })
  );
}

postUserControllerLogin.PATH = '/api/user/signin';
