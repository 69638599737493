<button
  [attr.data-theme]="theme()"
  [class.focus]="focus()"
  class="option theme-text min-w-[8rem] theme-hover w-full flex items-center gap-2 p-2 transition-all cursor-pointer outline-none select-none"
>
  <div class="flex items-center">
    @if (icon(); as icon) {
      <app-icon
        size="sm"
        [attr.data-theme]="theme()"
        [name]="icon"
        class="theme-text"
      />
    }
  </div>
  <div class="text-sm xl:text-base">
    <ng-content />
  </div>
  @if (active()) {
    <app-icon
      [attr.data-theme]="theme()"
      name="mdi:check"
      size="sm"
      class="theme-text"
    />
  }
</button>
