/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGuardianControllerDelete } from '../fn/guardian/admin-guardian-controller-delete';
import { AdminGuardianControllerDelete$Params } from '../fn/guardian/admin-guardian-controller-delete';
import { adminGuardianControllerFindAllByChildId } from '../fn/guardian/admin-guardian-controller-find-all-by-child-id';
import { AdminGuardianControllerFindAllByChildId$Params } from '../fn/guardian/admin-guardian-controller-find-all-by-child-id';
import { adminGuardianControllerFindById } from '../fn/guardian/admin-guardian-controller-find-by-id';
import { AdminGuardianControllerFindById$Params } from '../fn/guardian/admin-guardian-controller-find-by-id';
import { adminGuardianControllerManualCreate } from '../fn/guardian/admin-guardian-controller-manual-create';
import { AdminGuardianControllerManualCreate$Params } from '../fn/guardian/admin-guardian-controller-manual-create';
import { adminGuardianControllerSearchCursor } from '../fn/guardian/admin-guardian-controller-search-cursor';
import { AdminGuardianControllerSearchCursor$Params } from '../fn/guardian/admin-guardian-controller-search-cursor';
import { adminGuardianControllerSearchOffset } from '../fn/guardian/admin-guardian-controller-search-offset';
import { AdminGuardianControllerSearchOffset$Params } from '../fn/guardian/admin-guardian-controller-search-offset';
import { adminGuardianControllerUpdate } from '../fn/guardian/admin-guardian-controller-update';
import { AdminGuardianControllerUpdate$Params } from '../fn/guardian/admin-guardian-controller-update';
import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { GuardianDto } from '../models/guardian-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { parentGuardianControllerChangeMainGuardianByParent } from '../fn/guardian/parent-guardian-controller-change-main-guardian-by-parent';
import { ParentGuardianControllerChangeMainGuardianByParent$Params } from '../fn/guardian/parent-guardian-controller-change-main-guardian-by-parent';
import { parentGuardianControllerConnectChildParent } from '../fn/guardian/parent-guardian-controller-connect-child-parent';
import { ParentGuardianControllerConnectChildParent$Params } from '../fn/guardian/parent-guardian-controller-connect-child-parent';
import { parentGuardianControllerSearchByParent } from '../fn/guardian/parent-guardian-controller-search-by-parent';
import { ParentGuardianControllerSearchByParent$Params } from '../fn/guardian/parent-guardian-controller-search-by-parent';
import { SearchGuardianMemoResponseDto } from '../models/search-guardian-memo-response-dto';
import { userGuardianControllerChangeMainGuardian } from '../fn/guardian/user-guardian-controller-change-main-guardian';
import { UserGuardianControllerChangeMainGuardian$Params } from '../fn/guardian/user-guardian-controller-change-main-guardian';
import { userGuardianControllerCreate } from '../fn/guardian/user-guardian-controller-create';
import { UserGuardianControllerCreate$Params } from '../fn/guardian/user-guardian-controller-create';
import { userGuardianControllerDelete } from '../fn/guardian/user-guardian-controller-delete';
import { UserGuardianControllerDelete$Params } from '../fn/guardian/user-guardian-controller-delete';
import { userGuardianControllerFindAllByChildId } from '../fn/guardian/user-guardian-controller-find-all-by-child-id';
import { UserGuardianControllerFindAllByChildId$Params } from '../fn/guardian/user-guardian-controller-find-all-by-child-id';
import { userGuardianControllerFindById } from '../fn/guardian/user-guardian-controller-find-by-id';
import { UserGuardianControllerFindById$Params } from '../fn/guardian/user-guardian-controller-find-by-id';
import { userGuardianControllerSearchCursor } from '../fn/guardian/user-guardian-controller-search-cursor';
import { UserGuardianControllerSearchCursor$Params } from '../fn/guardian/user-guardian-controller-search-cursor';
import { userGuardianControllerSearchOffset } from '../fn/guardian/user-guardian-controller-search-offset';
import { UserGuardianControllerSearchOffset$Params } from '../fn/guardian/user-guardian-controller-search-offset';
import { userGuardianControllerUpdate } from '../fn/guardian/user-guardian-controller-update';
import { UserGuardianControllerUpdate$Params } from '../fn/guardian/user-guardian-controller-update';

@Injectable({ providedIn: 'root' })
export class GuardianService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userGuardianControllerFindAllByChildId()` */
  static readonly UserGuardianControllerFindAllByChildIdPath = '/api/guardian/user/child';

  /**
   * 아동의 보호자 전체 조회.
   *
   * 아동의 보호자를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGuardianControllerFindAllByChildId()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGuardianControllerFindAllByChildId$Response(params: UserGuardianControllerFindAllByChildId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GuardianDto>>> {
    return userGuardianControllerFindAllByChildId(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동의 보호자 전체 조회.
   *
   * 아동의 보호자를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGuardianControllerFindAllByChildId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGuardianControllerFindAllByChildId(params: UserGuardianControllerFindAllByChildId$Params, context?: HttpContext): Observable<Array<GuardianDto>> {
    return this.userGuardianControllerFindAllByChildId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GuardianDto>>): Array<GuardianDto> => r.body)
    );
  }

  /** Path part for operation `userGuardianControllerSearchOffset()` */
  static readonly UserGuardianControllerSearchOffsetPath = '/api/guardian/user/search/offset';

  /**
   * 보호자 오프셋 기반 조회.
   *
   * 보호자을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGuardianControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGuardianControllerSearchOffset$Response(params: UserGuardianControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return userGuardianControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 오프셋 기반 조회.
   *
   * 보호자을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGuardianControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGuardianControllerSearchOffset(params: UserGuardianControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.userGuardianControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `userGuardianControllerSearchCursor()` */
  static readonly UserGuardianControllerSearchCursorPath = '/api/guardian/user/search/cursor';

  /**
   * 보호자 커서 기반 조회.
   *
   * 보호자을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGuardianControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGuardianControllerSearchCursor$Response(params: UserGuardianControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return userGuardianControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 커서 기반 조회.
   *
   * 보호자을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGuardianControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGuardianControllerSearchCursor(params: UserGuardianControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.userGuardianControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `userGuardianControllerFindById()` */
  static readonly UserGuardianControllerFindByIdPath = '/api/guardian/user/{id}';

  /**
   * 보호자 ID 기반 조회.
   *
   * 보호자을(를) ID를 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGuardianControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGuardianControllerFindById$Response(params: UserGuardianControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return userGuardianControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 ID 기반 조회.
   *
   * 보호자을(를) ID를 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGuardianControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGuardianControllerFindById(params: UserGuardianControllerFindById$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.userGuardianControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `userGuardianControllerDelete()` */
  static readonly UserGuardianControllerDeletePath = '/api/guardian/user/{id}';

  /**
   * 보호자 삭제.
   *
   * 보호자을(를) 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGuardianControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGuardianControllerDelete$Response(params: UserGuardianControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return userGuardianControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 삭제.
   *
   * 보호자을(를) 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGuardianControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGuardianControllerDelete(params: UserGuardianControllerDelete$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.userGuardianControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `userGuardianControllerUpdate()` */
  static readonly UserGuardianControllerUpdatePath = '/api/guardian/user/{id}';

  /**
   * 보호자 수정.
   *
   * 보호자을(를) 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGuardianControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGuardianControllerUpdate$Response(params: UserGuardianControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return userGuardianControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 수정.
   *
   * 보호자을(를) 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGuardianControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGuardianControllerUpdate(params: UserGuardianControllerUpdate$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.userGuardianControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `userGuardianControllerCreate()` */
  static readonly UserGuardianControllerCreatePath = '/api/guardian/user';

  /**
   * 보호자 생성.
   *
   * 아동과 보호자를 연결합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGuardianControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGuardianControllerCreate$Response(params: UserGuardianControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return userGuardianControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 생성.
   *
   * 아동과 보호자를 연결합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGuardianControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGuardianControllerCreate(params: UserGuardianControllerCreate$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.userGuardianControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `userGuardianControllerChangeMainGuardian()` */
  static readonly UserGuardianControllerChangeMainGuardianPath = '/api/guardian/user/change';

  /**
   * 주 보호자 변경.
   *
   * 주 보호자를 변경합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGuardianControllerChangeMainGuardian()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGuardianControllerChangeMainGuardian$Response(params: UserGuardianControllerChangeMainGuardian$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return userGuardianControllerChangeMainGuardian(this.http, this.rootUrl, params, context);
  }

  /**
   * 주 보호자 변경.
   *
   * 주 보호자를 변경합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGuardianControllerChangeMainGuardian$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGuardianControllerChangeMainGuardian(params: UserGuardianControllerChangeMainGuardian$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.userGuardianControllerChangeMainGuardian$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `adminGuardianControllerFindAllByChildId()` */
  static readonly AdminGuardianControllerFindAllByChildIdPath = '/api/guardian/admin/child';

  /**
   * 아동의 보호자 전체 조회.
   *
   * 아동의 보호자를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGuardianControllerFindAllByChildId()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuardianControllerFindAllByChildId$Response(params: AdminGuardianControllerFindAllByChildId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GuardianDto>>> {
    return adminGuardianControllerFindAllByChildId(this.http, this.rootUrl, params, context);
  }

  /**
   * 아동의 보호자 전체 조회.
   *
   * 아동의 보호자를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGuardianControllerFindAllByChildId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuardianControllerFindAllByChildId(params: AdminGuardianControllerFindAllByChildId$Params, context?: HttpContext): Observable<Array<GuardianDto>> {
    return this.adminGuardianControllerFindAllByChildId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GuardianDto>>): Array<GuardianDto> => r.body)
    );
  }

  /** Path part for operation `adminGuardianControllerSearchOffset()` */
  static readonly AdminGuardianControllerSearchOffsetPath = '/api/guardian/admin/search/offset';

  /**
   * 보호자 오프셋 기반 조회.
   *
   * 보호자을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGuardianControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuardianControllerSearchOffset$Response(params: AdminGuardianControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto>> {
    return adminGuardianControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 오프셋 기반 조회.
   *
   * 보호자을(를) 오프셋 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGuardianControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuardianControllerSearchOffset(params: AdminGuardianControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto> {
    return this.adminGuardianControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto>): OffsetPaginationDto => r.body)
    );
  }

  /** Path part for operation `adminGuardianControllerSearchCursor()` */
  static readonly AdminGuardianControllerSearchCursorPath = '/api/guardian/admin/search/cursor';

  /**
   * 보호자 커서 기반 조회.
   *
   * 보호자을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGuardianControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuardianControllerSearchCursor$Response(params: AdminGuardianControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto>> {
    return adminGuardianControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 커서 기반 조회.
   *
   * 보호자을(를) 커서 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGuardianControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuardianControllerSearchCursor(params: AdminGuardianControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto> {
    return this.adminGuardianControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto>): CursorPaginationDto => r.body)
    );
  }

  /** Path part for operation `adminGuardianControllerFindById()` */
  static readonly AdminGuardianControllerFindByIdPath = '/api/guardian/admin/{id}';

  /**
   * 보호자 ID 기반 조회.
   *
   * 보호자을(를) ID를 기반으로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGuardianControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuardianControllerFindById$Response(params: AdminGuardianControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return adminGuardianControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 ID 기반 조회.
   *
   * 보호자을(를) ID를 기반으로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGuardianControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuardianControllerFindById(params: AdminGuardianControllerFindById$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.adminGuardianControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `adminGuardianControllerDelete()` */
  static readonly AdminGuardianControllerDeletePath = '/api/guardian/admin/{id}';

  /**
   * 보호자 삭제.
   *
   * 보호자을(를) 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGuardianControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuardianControllerDelete$Response(params: AdminGuardianControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return adminGuardianControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 삭제.
   *
   * 보호자을(를) 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGuardianControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuardianControllerDelete(params: AdminGuardianControllerDelete$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.adminGuardianControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `adminGuardianControllerUpdate()` */
  static readonly AdminGuardianControllerUpdatePath = '/api/guardian/admin/{id}';

  /**
   * 보호자 수정.
   *
   * 보호자을(를) 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGuardianControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminGuardianControllerUpdate$Response(params: AdminGuardianControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return adminGuardianControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 수정.
   *
   * 보호자을(를) 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGuardianControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminGuardianControllerUpdate(params: AdminGuardianControllerUpdate$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.adminGuardianControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `adminGuardianControllerManualCreate()` */
  static readonly AdminGuardianControllerManualCreatePath = '/api/guardian/admin/manual';

  /**
   * 보호자 생성.
   *
   * 아동과 보호자를 연결합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGuardianControllerManualCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminGuardianControllerManualCreate$Response(params: AdminGuardianControllerManualCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return adminGuardianControllerManualCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자 생성.
   *
   * 아동과 보호자를 연결합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGuardianControllerManualCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminGuardianControllerManualCreate(params: AdminGuardianControllerManualCreate$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.adminGuardianControllerManualCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

  /** Path part for operation `parentGuardianControllerSearchByParent()` */
  static readonly ParentGuardianControllerSearchByParentPath = '/api/guardian/parent';

  /**
   * 보호자가 내 아동의 다른 보호자 검색 조회.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentGuardianControllerSearchByParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentGuardianControllerSearchByParent$Response(params: ParentGuardianControllerSearchByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchGuardianMemoResponseDto>> {
    return parentGuardianControllerSearchByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 다른 보호자 검색 조회.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentGuardianControllerSearchByParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentGuardianControllerSearchByParent(params: ParentGuardianControllerSearchByParent$Params, context?: HttpContext): Observable<SearchGuardianMemoResponseDto> {
    return this.parentGuardianControllerSearchByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchGuardianMemoResponseDto>): SearchGuardianMemoResponseDto => r.body)
    );
  }

  /** Path part for operation `parentGuardianControllerConnectChildParent()` */
  static readonly ParentGuardianControllerConnectChildParentPath = '/api/guardian/parent/connect';

  /**
   * 보호자가 아동과 자신 연결.
   *
   * 보호자가 아동과 자신을 연결합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentGuardianControllerConnectChildParent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentGuardianControllerConnectChildParent$Response(params: ParentGuardianControllerConnectChildParent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return parentGuardianControllerConnectChildParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 아동과 자신 연결.
   *
   * 보호자가 아동과 자신을 연결합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentGuardianControllerConnectChildParent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentGuardianControllerConnectChildParent(params: ParentGuardianControllerConnectChildParent$Params, context?: HttpContext): Observable<boolean> {
    return this.parentGuardianControllerConnectChildParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `parentGuardianControllerChangeMainGuardianByParent()` */
  static readonly ParentGuardianControllerChangeMainGuardianByParentPath = '/api/guardian/parent/main-guardian';

  /**
   * 보호자가 내 아동의 주보호자를 변경.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentGuardianControllerChangeMainGuardianByParent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentGuardianControllerChangeMainGuardianByParent$Response(params: ParentGuardianControllerChangeMainGuardianByParent$Params, context?: HttpContext): Observable<StrictHttpResponse<GuardianDto>> {
    return parentGuardianControllerChangeMainGuardianByParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 보호자가 내 아동의 주보호자를 변경.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parentGuardianControllerChangeMainGuardianByParent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  parentGuardianControllerChangeMainGuardianByParent(params: ParentGuardianControllerChangeMainGuardianByParent$Params, context?: HttpContext): Observable<GuardianDto> {
    return this.parentGuardianControllerChangeMainGuardianByParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuardianDto>): GuardianDto => r.body)
    );
  }

}
