/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminContentCategoryControllerCopy } from '../fn/content-category/admin-content-category-controller-copy';
import { AdminContentCategoryControllerCopy$Params } from '../fn/content-category/admin-content-category-controller-copy';
import { adminContentCategoryControllerCreate } from '../fn/content-category/admin-content-category-controller-create';
import { AdminContentCategoryControllerCreate$Params } from '../fn/content-category/admin-content-category-controller-create';
import { adminContentCategoryControllerDelete } from '../fn/content-category/admin-content-category-controller-delete';
import { AdminContentCategoryControllerDelete$Params } from '../fn/content-category/admin-content-category-controller-delete';
import { adminContentCategoryControllerFindAll } from '../fn/content-category/admin-content-category-controller-find-all';
import { AdminContentCategoryControllerFindAll$Params } from '../fn/content-category/admin-content-category-controller-find-all';
import { adminContentCategoryControllerFindAllParent } from '../fn/content-category/admin-content-category-controller-find-all-parent';
import { AdminContentCategoryControllerFindAllParent$Params } from '../fn/content-category/admin-content-category-controller-find-all-parent';
import { adminContentCategoryControllerFindById } from '../fn/content-category/admin-content-category-controller-find-by-id';
import { AdminContentCategoryControllerFindById$Params } from '../fn/content-category/admin-content-category-controller-find-by-id';
import { adminContentCategoryControllerReorder } from '../fn/content-category/admin-content-category-controller-reorder';
import { AdminContentCategoryControllerReorder$Params } from '../fn/content-category/admin-content-category-controller-reorder';
import { adminContentCategoryControllerUpdate } from '../fn/content-category/admin-content-category-controller-update';
import { AdminContentCategoryControllerUpdate$Params } from '../fn/content-category/admin-content-category-controller-update';
import { ContentCategoryDto } from '../models/content-category-dto';
import { userContentCategoryControllerFindAllByParentId } from '../fn/content-category/user-content-category-controller-find-all-by-parent-id';
import { UserContentCategoryControllerFindAllByParentId$Params } from '../fn/content-category/user-content-category-controller-find-all-by-parent-id';
import { userContentCategoryControllerFindAllChild } from '../fn/content-category/user-content-category-controller-find-all-child';
import { UserContentCategoryControllerFindAllChild$Params } from '../fn/content-category/user-content-category-controller-find-all-child';
import { userContentCategoryControllerFindAllParentWithContents } from '../fn/content-category/user-content-category-controller-find-all-parent-with-contents';
import { UserContentCategoryControllerFindAllParentWithContents$Params } from '../fn/content-category/user-content-category-controller-find-all-parent-with-contents';

@Injectable({ providedIn: 'root' })
export class ContentCategoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminContentCategoryControllerFindAll()` */
  static readonly AdminContentCategoryControllerFindAllPath = '/api/content-category/admin';

  /**
   * 콘텐츠 카테고리 목록 조회.
   *
   * 등록된 콘텐츠 카테고리 목록을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentCategoryControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentCategoryControllerFindAll$Response(params?: AdminContentCategoryControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContentCategoryDto>>> {
    return adminContentCategoryControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 콘텐츠 카테고리 목록 조회.
   *
   * 등록된 콘텐츠 카테고리 목록을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentCategoryControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentCategoryControllerFindAll(params?: AdminContentCategoryControllerFindAll$Params, context?: HttpContext): Observable<Array<ContentCategoryDto>> {
    return this.adminContentCategoryControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContentCategoryDto>>): Array<ContentCategoryDto> => r.body)
    );
  }

  /** Path part for operation `adminContentCategoryControllerCreate()` */
  static readonly AdminContentCategoryControllerCreatePath = '/api/content-category/admin';

  /**
   * 새 콘텐츠 카테고리 등록.
   *
   * 카테고리를 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentCategoryControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminContentCategoryControllerCreate$Response(params: AdminContentCategoryControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentCategoryDto>> {
    return adminContentCategoryControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 새 콘텐츠 카테고리 등록.
   *
   * 카테고리를 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentCategoryControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminContentCategoryControllerCreate(params: AdminContentCategoryControllerCreate$Params, context?: HttpContext): Observable<ContentCategoryDto> {
    return this.adminContentCategoryControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentCategoryDto>): ContentCategoryDto => r.body)
    );
  }

  /** Path part for operation `adminContentCategoryControllerFindAllParent()` */
  static readonly AdminContentCategoryControllerFindAllParentPath = '/api/content-category/admin/parent';

  /**
   * 부모 카테고리 목록 조회.
   *
   * 부모 카테고리 목록을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentCategoryControllerFindAllParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentCategoryControllerFindAllParent$Response(params?: AdminContentCategoryControllerFindAllParent$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContentCategoryDto>>> {
    return adminContentCategoryControllerFindAllParent(this.http, this.rootUrl, params, context);
  }

  /**
   * 부모 카테고리 목록 조회.
   *
   * 부모 카테고리 목록을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentCategoryControllerFindAllParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentCategoryControllerFindAllParent(params?: AdminContentCategoryControllerFindAllParent$Params, context?: HttpContext): Observable<Array<ContentCategoryDto>> {
    return this.adminContentCategoryControllerFindAllParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContentCategoryDto>>): Array<ContentCategoryDto> => r.body)
    );
  }

  /** Path part for operation `adminContentCategoryControllerFindById()` */
  static readonly AdminContentCategoryControllerFindByIdPath = '/api/content-category/admin/{id}';

  /**
   * 카테고리 조회.
   *
   * 카테고리를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentCategoryControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentCategoryControllerFindById$Response(params: AdminContentCategoryControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentCategoryDto>> {
    return adminContentCategoryControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 카테고리 조회.
   *
   * 카테고리를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentCategoryControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentCategoryControllerFindById(params: AdminContentCategoryControllerFindById$Params, context?: HttpContext): Observable<ContentCategoryDto> {
    return this.adminContentCategoryControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentCategoryDto>): ContentCategoryDto => r.body)
    );
  }

  /** Path part for operation `adminContentCategoryControllerDelete()` */
  static readonly AdminContentCategoryControllerDeletePath = '/api/content-category/admin/{id}';

  /**
   * 콘텐츠 카테고리 삭제.
   *
   * 카테고리를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentCategoryControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentCategoryControllerDelete$Response(params: AdminContentCategoryControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminContentCategoryControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 콘텐츠 카테고리 삭제.
   *
   * 카테고리를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentCategoryControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentCategoryControllerDelete(params: AdminContentCategoryControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.adminContentCategoryControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminContentCategoryControllerUpdate()` */
  static readonly AdminContentCategoryControllerUpdatePath = '/api/content-category/admin/{id}';

  /**
   * 콘텐츠 카테고리 수정.
   *
   * 카테고리를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentCategoryControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminContentCategoryControllerUpdate$Response(params: AdminContentCategoryControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentCategoryDto>> {
    return adminContentCategoryControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 콘텐츠 카테고리 수정.
   *
   * 카테고리를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentCategoryControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminContentCategoryControllerUpdate(params: AdminContentCategoryControllerUpdate$Params, context?: HttpContext): Observable<ContentCategoryDto> {
    return this.adminContentCategoryControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentCategoryDto>): ContentCategoryDto => r.body)
    );
  }

  /** Path part for operation `adminContentCategoryControllerCopy()` */
  static readonly AdminContentCategoryControllerCopyPath = '/api/content-category/admin/copy';

  /**
   * 콘텐츠 카테고리 복사.
   *
   * 카테고리를 복사합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentCategoryControllerCopy()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentCategoryControllerCopy$Response(params: AdminContentCategoryControllerCopy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminContentCategoryControllerCopy(this.http, this.rootUrl, params, context);
  }

  /**
   * 콘텐츠 카테고리 복사.
   *
   * 카테고리를 복사합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentCategoryControllerCopy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentCategoryControllerCopy(params: AdminContentCategoryControllerCopy$Params, context?: HttpContext): Observable<void> {
    return this.adminContentCategoryControllerCopy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminContentCategoryControllerReorder()` */
  static readonly AdminContentCategoryControllerReorderPath = '/api/content-category/admin/reorder';

  /**
   * 콘텐츠 카테고리 순서 변경.
   *
   * 카테고리의 순서를 변경합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminContentCategoryControllerReorder()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentCategoryControllerReorder$Response(params: AdminContentCategoryControllerReorder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminContentCategoryControllerReorder(this.http, this.rootUrl, params, context);
  }

  /**
   * 콘텐츠 카테고리 순서 변경.
   *
   * 카테고리의 순서를 변경합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminContentCategoryControllerReorder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminContentCategoryControllerReorder(params: AdminContentCategoryControllerReorder$Params, context?: HttpContext): Observable<void> {
    return this.adminContentCategoryControllerReorder$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userContentCategoryControllerFindAllParentWithContents()` */
  static readonly UserContentCategoryControllerFindAllParentWithContentsPath = '/api/content-category/user/parent';

  /**
   * 1차 카테고리 목록 조회.
   *
   * 1차 카테고리 목록을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userContentCategoryControllerFindAllParentWithContents()` instead.
   *
   * This method doesn't expect any request body.
   */
  userContentCategoryControllerFindAllParentWithContents$Response(params?: UserContentCategoryControllerFindAllParentWithContents$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContentCategoryDto>>> {
    return userContentCategoryControllerFindAllParentWithContents(this.http, this.rootUrl, params, context);
  }

  /**
   * 1차 카테고리 목록 조회.
   *
   * 1차 카테고리 목록을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userContentCategoryControllerFindAllParentWithContents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userContentCategoryControllerFindAllParentWithContents(params?: UserContentCategoryControllerFindAllParentWithContents$Params, context?: HttpContext): Observable<Array<ContentCategoryDto>> {
    return this.userContentCategoryControllerFindAllParentWithContents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContentCategoryDto>>): Array<ContentCategoryDto> => r.body)
    );
  }

  /** Path part for operation `userContentCategoryControllerFindAllChild()` */
  static readonly UserContentCategoryControllerFindAllChildPath = '/api/content-category/user/child';

  /**
   * 2차 카테고리 목록 조회.
   *
   * 2차 카테고리 목록을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userContentCategoryControllerFindAllChild()` instead.
   *
   * This method doesn't expect any request body.
   */
  userContentCategoryControllerFindAllChild$Response(params?: UserContentCategoryControllerFindAllChild$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContentCategoryDto>>> {
    return userContentCategoryControllerFindAllChild(this.http, this.rootUrl, params, context);
  }

  /**
   * 2차 카테고리 목록 조회.
   *
   * 2차 카테고리 목록을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userContentCategoryControllerFindAllChild$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userContentCategoryControllerFindAllChild(params?: UserContentCategoryControllerFindAllChild$Params, context?: HttpContext): Observable<Array<ContentCategoryDto>> {
    return this.userContentCategoryControllerFindAllChild$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContentCategoryDto>>): Array<ContentCategoryDto> => r.body)
    );
  }

  /** Path part for operation `userContentCategoryControllerFindAllByParentId()` */
  static readonly UserContentCategoryControllerFindAllByParentIdPath = '/api/content-category/user/parent/{parentId}';

  /**
   * 카테고리 조회.
   *
   * 카테고리를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userContentCategoryControllerFindAllByParentId()` instead.
   *
   * This method doesn't expect any request body.
   */
  userContentCategoryControllerFindAllByParentId$Response(params: UserContentCategoryControllerFindAllByParentId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContentCategoryDto>>> {
    return userContentCategoryControllerFindAllByParentId(this.http, this.rootUrl, params, context);
  }

  /**
   * 카테고리 조회.
   *
   * 카테고리를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userContentCategoryControllerFindAllByParentId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userContentCategoryControllerFindAllByParentId(params: UserContentCategoryControllerFindAllByParentId$Params, context?: HttpContext): Observable<Array<ContentCategoryDto>> {
    return this.userContentCategoryControllerFindAllByParentId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContentCategoryDto>>): Array<ContentCategoryDto> => r.body)
    );
  }

}
